import Select from 'react-dropdown-select';
import styled from 'styled-components';
import { AppSelectboxOption } from 'utils/types';

export const StyledSelect = styled(Select<AppSelectboxOption>)`
  border: none !important;
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.border[30]} !important;
  border-radius: 4px !important;
  background-color: ${({ theme }) => theme.colors.soft[50]} !important;
  height: 40px !important;

  &:focus,
  &:focus-within {
    box-shadow: none !important;
  }
`;

export const SelectedText = styled.p`
  color: ${({ theme }) => theme.colors.primary[30]};
  font-size: 14px;
  font-weight: 600;

  margin-left: 12px;
`;

export const Label = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 6px;
`;
