import { AUDIENCE_OPTIONS } from 'config/constants';
import { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getEntity } from 'redux/entity';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { retrieveTemplateIcon, retrieveTemplateName } from 'utils/helpers/articleHelper';
import { useSelect } from 'utils/hooks/useSelect';
import { Col, Row } from '../../common/Styles';
import { ArticleFormEditor } from './ArticleFormEditor';
import { ArticleLanguagePicker } from './ArticleLanguagePicker';
import { ArticleEditor } from './editor';

export const ArticleInputEditor = memo(() => {
  const { t } = useTranslation('article');
  const { articleId } = useContext(ArticleContext);

  const generationType = useSelect(({ editor }) => editor.generationType?.[articleId] ?? 'text');
  const inputType = useSelect(({ editor }) => editor.inputType);
  const textSettings = useSelect(({ editor }) => editor.textGenerationSettings);
  const template = useSelect(({ entity, editor }) => {
    const textTemplateIds = editor.selectedTextTemplateId;
    const imageTemplateIds = editor.selectedImageTemplateId;
    const templateIds = generationType === 'text' ? textTemplateIds : imageTemplateIds;
    const templateId = templateIds?.[articleId];
    return getEntity(entity, 'template', templateId);
  });

  // @handlers

  const subtitle = useMemo(() => {
    if (generationType === 'image') return '';
    const stringComponents = [];

    const labelFinder = (options: { label: string; value: string }[], value: string) => {
      return options.find((item) => item.value === value)?.label;
    };

    if (textSettings.aiTemplates) stringComponents.push(textSettings.aiTemplates.name);
    if (textSettings.tone) stringComponents.push(textSettings.tone);
    if (textSettings.platform) stringComponents.push(textSettings.platform);
    if (textSettings.audience) stringComponents.push(labelFinder(AUDIENCE_OPTIONS, textSettings.audience));
    if (textSettings.approach) stringComponents.push(textSettings.approach);

    return stringComponents.join(', ');
  }, [JSON.stringify(textSettings), generationType, template]);

  // @render

  return (
    <Container>
      <CreatorHeader>
        <Col>
          <TemplateName>{`${retrieveTemplateIcon(template)} ${retrieveTemplateName(template, t)}`}</TemplateName>
          {subtitle ? <TemplateAttr>{subtitle}</TemplateAttr> : null}
        </Col>
        {generationType === 'text' && <ArticleLanguagePicker />}
      </CreatorHeader>

      <CreatorContainer>{inputType === 'form' ? <ArticleFormEditor /> : <ArticleEditor />}</CreatorContainer>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  max-height: calc(100vh - 60px);
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CreatorHeader = styled(Row)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[70]};
  height: 50px;
  padding: 7px 30px;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
`;

const TemplateName = styled.div`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const TemplateAttr = styled.div`
  font-family: Nunito Sans;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #404163;
`;

const CreatorContainer = styled.div`
  display: flex;
  position: absolute;
  top: 64px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  max-width: 100%;
`;
