import { useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { AccountPageContainer } from 'features/account/AccountPageContainer';
import AccountSubscription from 'features/account/AccountSubscription';
import PersonalInformation from 'features/account/PersonalInformation';
import PasswordInformation from 'features/account/PasswordInformation';

const Account = () => {
  const history = useHistory();

  const [activeStep, setActiveStep] = useState('/');

  history.listen((location) => {
    if (location.pathname !== activeStep) {
      if (location.pathname.lastIndexOf('/') > 0) {
        setActiveStep('/' + location.pathname.split('/')[2]);
      }
    }
  });

  return (
    <AccountPageContainer activeStep={activeStep}>
      <Switch>
        <Route exact path={['/account/personal-information']}>
          <PersonalInformation />
        </Route>
        <Route path={'/account/password-information'}>
          <PasswordInformation />
        </Route>
        <Route path={'/account/subscription'}>
          <AccountSubscription />
        </Route>
        <Route path="*">
          <Redirect to="/account/personal-information" />
        </Route>
      </Switch>
    </AccountPageContainer>
  );
};

export default Account;
