import { memo } from 'react';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';

export const UserAvatar = memo<{
  onClick?: () => void;
  size?: 'small' | 'large';
  className?: string;
}>(({ onClick, size = 'small', className }) => {
  const user = useSelect(({ me }) => me.user);

  return (
    <Container onClick={onClick} className={className} size={size}>
      {user?.first_name[0] || ''}
      {user?.last_name[0] || ''}
    </Container>
  );
});

const Container = styled.div<{ size: 'small' | 'large' }>`
  width: ${({ size }) => (size === 'small' ? 18 : 48)}px;
  height: ${({ size }) => (size === 'small' ? 18 : 48)}px;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.purple[100]};
  font-weight: 700;
  font-size: ${({ size }) => (size === 'small' ? 9 : 16)}px;
  line-height: ${({ size }) => (size === 'small' ? 16 : 22)}px;
`;
