import { ReactComponent as DownArrow } from 'assets/img/icons/down-arrow.svg';
import { Col, FlexRow, Label, Row } from 'features/common/Styles';
import { orderBy } from 'lodash';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { animated, easings, useSpring } from 'react-spring';
import { getEntities } from 'redux/entity';
import { toggleOpenTemplateParent } from 'redux/folder';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { retrieveParentTemplateIcon } from 'utils/helpers/articleHelper';
import { useSelect } from 'utils/hooks/useSelect';
import { Template } from 'utils/types';
import { ArticleDrawerTemplateListItem } from './ArticleDrawerTemplateListItem';

export const ArticleDrawerTemplateAccordion = memo<{
  parentName: string;
  index: number;
  onTemplateClick: (article: Template) => void;
}>(({ parentName, index, onTemplateClick }) => {
  const dispatch = useDispatch();
  const { articleId } = useContext(ArticleContext);

  const selectedTemplateId = useSelect(({ editor }) => editor.selectedTextTemplateId[articleId] ?? '');
  const openTemplateParents = useSelect(({ folder }) => folder.openTemplateParents);
  const templates = useSelect(({ entity }) => {
    const allTemplates = getEntities(entity, 'template').filter(({ parent }) => parentName === parent);
    return orderBy(allTemplates, ['created_at'], ['desc']);
  });

  const [open, setOpen] = useState(openTemplateParents.includes(parentName));

  const includesSelectedTemplate = useMemo(() => {
    return templates.some(({ id }) => id === selectedTemplateId);
  }, [templates, selectedTemplateId]);

  const openAnimation = useSpring({
    from: { opacity: 0, maxHeight: '0px' },
    to: { opacity: open ? 1 : 0, maxHeight: open ? `${templates.length * 90}px` : '0px' },
    config: { duration: 300, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  const arrowAnimation = useSpring({
    from: { opacity: 1, rotate: '0deg' },
    to: { rotate: open ? '-180deg' : '0deg' },
    config: { duration: 250, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  // @effects

  useEffect(() => {
    dispatch(toggleOpenTemplateParent(parentName, open));
  }, [open, parentName]);

  // @handlers

  const handleToggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleTemplateClick = useCallback(
    (article: Template) => {
      onTemplateClick(article);
    },
    [onTemplateClick]
  );

  // @render

  return (
    <Col style={{ zIndex: index }}>
      <Container>
        <TitleContainer
          onClick={handleToggleOpen}
          className={!open && includesSelectedTemplate ? 'gradient-border' : ''}
        >
          <FlexRow
            style={{
              borderRadius: 4,
              maxWidth: '100%',
              padding: 10,
              zIndex: 2,
              backgroundColor: !open && includesSelectedTemplate ? 'rgb(106,40,162)' : 'transparent'
            }}
          >
            <FlexRow style={{ alignItems: 'center', maxWidth: 161 }}>
              <Title style={{ marginTop: 1, minWidth: 15 }}>{retrieveParentTemplateIcon(parentName)}</Title>
              <Title style={{ flex: 1 }}>{parentName}</Title>

              <Subtitle>{`(${templates.length})`}</Subtitle>
            </FlexRow>

            <animated.div style={arrowAnimation}>
              <DownArrow width={8} opacity={0.5} />
            </animated.div>
          </FlexRow>
        </TitleContainer>

        <animated.div style={{ ...openAnimation, width: '100%' }}>
          <ListContainer>
            {templates.map((template, itemIndex) => (
              <ArticleDrawerTemplateListItem
                key={template.id}
                template={template}
                isSelected={template.id === selectedTemplateId}
                isLastItem={itemIndex === templates.length - 1}
                onTemplateClick={handleTemplateClick}
              />
            ))}
          </ListContainer>
        </animated.div>
      </Container>
    </Col>
  );
});

const Container = styled(animated.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 32px;
  margin-bottom: 0px;
  border-radius: 8px;
  cursor: pointer;
  min-width: 190px;
  transition: 0.2s ease-in-out;
`;

const ListContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Label)`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-right: 6px;
  color: white;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-align: left;
  overflow: hidden;
  word-break: break-all;
`;

const TitleContainer = styled(Row)`
  width: 100%;
  font-size: 14px;
  justify-content: space-between;
  font-weight: 400;
  line-height: 20px;
  color: white;
  margin-top: 4px;
  align-items: center;
  padding: 1px;
  cursor: pointer;
  border-radius: 4px;
  min-width: 190px;
  background-color: rgba(255, 255, 255, 0.05);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Subtitle = styled(Label)`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  opacity: 0.6;
  margin-left: -1px;
  margin-right: 6px;
  color: white;
`;
