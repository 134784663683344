import { ReactComponent as RightArrow } from 'assets/img/icons/ArrowRight.svg';
import { Row } from 'features/common/Styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DrawerSection } from 'utils/types';

export const ArticleDrawerSectionList: React.FC<{
  onSectionClick: (section: DrawerSection) => void;
}> = ({ onSectionClick }) => {
  const { t } = useTranslation(['article']);
  const sections: DrawerSection[] = ['templates', 'customTemplates', 'beta'];

  // @handlers

  const handleSectionClick = useCallback(
    (section: DrawerSection) => () => {
      onSectionClick(section);
    },
    [sections, onSectionClick]
  );

  // @render

  return (
    <Container>
      {sections.map((section) => (
        <ListItem key={section} onClick={handleSectionClick(section)}>
          {t(section)}
          <RightArrow width={12} height={12} />
        </ListItem>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding: 0;
  & > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const ListItem = styled(Row)`
  width: 100%;
  font-size: 14px;
  justify-content: space-between;
  font-weight: 400;
  line-height: 20px;
  color: white;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  min-width: 190px;
  background-color: rgba(255, 255, 255, 0.05);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
