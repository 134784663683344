import { novusFont } from 'assets/fonts/novus-font';
import {
  MIND_FLOW_TEMPLATE_NAME,
  MIND_FLOW_TONE_OPTIONS,
  PROOF_READ_INLINE_STYLE_MAP,
  TEMPLATE_ICONS,
  TEMPLATE_PARENT_ICONS,
  TONE_OPTIONS,
  VENTI_ARTICLE_TEMPLATE_NAME,
  VENTI_ARTICLE_TONE_OPTIONS
} from 'config/constants';
import {
  BlockMap,
  ContentState,
  DraftModel,
  EditorState,
  Modifier,
  RawDraftContentState,
  RichUtils,
  SelectionState
} from 'draft-js';
import { TFunction } from 'i18next';
import jsPDF from 'jspdf';
import { ImageTemplateSettingsSection, Similarity, TextTemplateSettingsSection } from 'utils/types';
import { Template } from '../types';

export function getSelectionConfigForSimilarity(
  similarity: Similarity,
  currentContent: ContentState
): { selectionConfig: Partial<DraftModel.ImmutableData.SelectionStateProperties>; isSentenceUnrelated: boolean } {
  let currentStart = similarity.start;
  let currentEnd = similarity.end;

  let contentToProcess = currentContent.getFirstBlock();
  let previousBlockLength = 0;
  while (currentStart >= previousBlockLength + contentToProcess.getText().length) {
    const nextBlock = currentContent.getBlockAfter(contentToProcess.getKey());
    if (nextBlock) {
      previousBlockLength += contentToProcess.getText().length;
      contentToProcess = nextBlock;

      currentStart--;
      currentEnd--;
    } else break;
  }

  const blockRelativeStart = currentStart - previousBlockLength;
  const blockRelativeEnd = currentEnd - previousBlockLength;

  return {
    selectionConfig: {
      anchorKey: contentToProcess.getKey(),
      anchorOffset: blockRelativeStart,
      focusKey: contentToProcess.getKey(),
      focusOffset: blockRelativeEnd
    },
    isSentenceUnrelated: false
  };
}

export function applySimilarityInlineStyle(
  editorState: EditorState,
  selectionConfig: Partial<DraftModel.ImmutableData.SelectionStateProperties>,
  isExactMatch: boolean
) {
  const selectionState = editorState.getSelection();
  const selection = selectionState.merge(selectionConfig);

  let state = EditorState.forceSelection(editorState, selection);

  const currentInlineStyle = state.getCurrentInlineStyle();
  const hasExactMatchStyle = currentInlineStyle.has(PROOF_READ_INLINE_STYLE_MAP.EXACT);
  const hasSimilarMatchStyle = currentInlineStyle.has(PROOF_READ_INLINE_STYLE_MAP.SIMILAR);
  const shouldToggleInlineStyle = (isExactMatch && !hasExactMatchStyle) || (!isExactMatch && !hasSimilarMatchStyle);
  if (!shouldToggleInlineStyle) return editorState;

  const style = isExactMatch ? PROOF_READ_INLINE_STYLE_MAP.EXACT : PROOF_READ_INLINE_STYLE_MAP.SIMILAR;
  state = RichUtils.toggleInlineStyle(state, style);

  return EditorState.forceSelection(state, selectionState);
}

export function removeSimilarityInlineStyle(editorState: EditorState) {
  let state = editorState.getCurrentContent();
  const blocks = state.getBlocksAsArray();
  blocks.forEach((block) => {
    const selectionState = SelectionState.createEmpty(block.getKey());
    const selection = selectionState.merge({ anchorOffset: 0, focusOffset: block.getLength() });
    state = Modifier.removeInlineStyle(state, selection, PROOF_READ_INLINE_STYLE_MAP.EXACT);
    state = Modifier.removeInlineStyle(state, selection, PROOF_READ_INLINE_STYLE_MAP.SIMILAR);
  });
  return EditorState.push(editorState, state, 'change-inline-style');
}

export function splitSimilarities(data: Similarity[], blockMap: BlockMap): Similarity[] {
  const blockMapRange: Array<{ start: number; end: number }> = [];
  blockMap.forEach((block) => {
    let start = blockMapRange?.[blockMapRange.length - 1]?.end ?? 0;
    if (start !== 0) start++;

    let end = start + (block?.getText().length ?? 0);
    blockMapRange.push({ start, end });
  });

  const similarities: Similarity[] = [];
  data.forEach((similarity) => {
    let similarityStart = similarity.start;
    let similarityEnd = similarity.end;

    for (const blockRange of blockMapRange) {
      if (similarityStart >= blockRange.start && similarityEnd <= blockRange.end) {
        similarities.push({ ...similarity, start: similarityStart, end: similarityEnd });
        break;
      } else if (similarityStart <= blockRange.end) {
        similarities.push({ ...similarity, start: similarityStart, end: blockRange.end - 1 });
        similarityStart = blockRange.end;
      }
    }
  });

  return similarities;
}

export function draftEditorToPdfDownload(editor: RawDraftContentState, docType: 'pdf') {
  let doc = new jsPDF();
  doc.addFileToVFS('Novus-default.ttf', novusFont);
  doc.addFont('Novus-default.ttf', 'Novus', 'normal');
  doc.setFont('Novus');
  doc.setFontSize(12);

  //convert row array
  const sortedBlockArray: any[] = [];
  const sortedBlockPromises = editor.blocks.map(async (block, key) => {
    if (block.type === 'atomic') {
      const image = await fetch(editor.entityMap[block.entityRanges[0].key].data.src);
      const imageBlob = await image.blob();

      await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = (item) => {
          var dataURL = (item.target!.result as string).replace(/data:image\/svg\+xml;base64,/, '');
          sortedBlockArray[key] = { type: 'image', value: dataURL };
          resolve(true);
        };
      });
    } else if (block.type === 'unstyled') {
      sortedBlockArray[key] = { type: 'text', value: block.text };
    }
  });

  Promise.all(sortedBlockPromises).then(() => {
    let vertical = 10;
    sortedBlockArray.forEach((item) => {
      if (item.type === 'text') {
        if (vertical + 5 > 275) {
          doc.addPage();
          vertical = 10;
        }
        stringSliceSplit(item.value, 75).forEach((text) => {
          doc.text(text, 10, vertical);
          vertical += 5;
        });
      } else if (item.type === 'image') {
        const image = doc.getImageProperties(item.value);
        const height = (190 * image.height) / image.width;
        if (vertical + height > 280) {
          doc.addPage();
          vertical = 10;
        }
        doc.addImage(item.value, 'JPEG', 10, vertical, 190, (190 * image.height) / image.width);
        vertical += 205;
      }
    });

    doc.save(`editor.${docType}`);
  });
}

export const getTemplateName = (name: string, templateParent: string, t: TFunction) => {
  if (templateParent === 'UserTemplate') return name;

  return t(`article:templateNames.${name}`);
};

export const retrieveTemplateName = (template?: Template | null, t?: TFunction) => {
  if (!template) return '';
  if (template.parent === 'UserTemplate') return template.name;
  return t?.(`article:templateNames.${template.name}`);
};

export const retrieveTemplateIcon = (template?: Template | null) => {
  if (!template) return '';
  if (template.parent === 'UserTemplate') return '';
  return (TEMPLATE_ICONS as any)[template.name];
};

export const retrieveParentTemplateIcon = (parentName?: string) => {
  if (!parentName) return '';
  return (TEMPLATE_PARENT_ICONS as any)[parentName] ?? '';
};

export const arrayMove = <T>(arr: T[], oldIndex: number, newIndex: number) => {
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const toneOptionsGetter = (templateName?: string) => {
  if (templateName === VENTI_ARTICLE_TEMPLATE_NAME) return VENTI_ARTICLE_TONE_OPTIONS;
  else if (templateName === MIND_FLOW_TEMPLATE_NAME) return MIND_FLOW_TONE_OPTIONS;
  else return TONE_OPTIONS;
};

export const hasApproachOptionsGetter = (templateName?: string) => {
  if (templateName === MIND_FLOW_TEMPLATE_NAME) return true;
  else return false;
};

export const camelCaseToTitleCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const isInsufficientUserRightsError = (error: any) => error?.data?.code === 1502;

export const renderTemplateSettings = (
  template: Template | null,
  section: ImageTemplateSettingsSection | TextTemplateSettingsSection
) => {
  if (!template) return false;
  return template?.render?.includes(section);
};

//#region private functions

const stringSliceSplit = (str: string, size: number) => {
  const result = [];
  for (let i = 0; i < str.length; i += size) result.push(str.slice(i, i + size));
  return result;
};

export const editorStyle = {
  EXACT_MATCH: { backgroundColor: '#FBDADA' },
  SIMILAR_MATCH: { backgroundColor: '#FBF8DA' },
  'header-one': { fontSize: '2rem', fontWeight: 'bold', lineHeight: '2.5rem', marginBottom: '1rem', marginTop: '1rem' },
  'header-two': { fontSize: '1.5rem', fontWeight: 'bold', lineHeight: '2rem', marginBottom: '1rem', marginTop: '1rem' },
  'header-three': {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    lineHeight: '1.75rem',
    marginBottom: '1rem',
    marginTop: '1rem'
  }
};
