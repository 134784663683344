import styled from 'styled-components';
import AppButton from './AppButton';

export const AppButtonGroup: React.FC<{
  value: string;
  options: Array<{ label: string; value: string }>;
  onChange: (selectedValue: string) => void;
}> = ({ value, options, onChange }) => {
  return (
    <Container>
      {options.map((item) => (
        <Tab
          key={item.value}
          style={{ fontSize: '14.5px', letterSpacing: '0.2px' }}
          variant={value === item.value ? 'default' : 'link'}
          selected={value === item.value}
          text={item.label}
          onClick={() => onChange(item.value)}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.soft[40]};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 2px;
`;

const Tab = styled(AppButton)<{ selected: boolean }>`
  min-width: 130px;
  border-radius: 4px;
  padding: 4px 30px;
  background: ${({ theme, selected }) =>
    selected ? 'linear-gradient(130deg, #561a55 0%, #641da9 50%);' : 'transparent'};
  transition: all 0.4s ease-in-out;
`;
