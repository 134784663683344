import React from 'react';
import styled from 'styled-components';
import AppButton from './AppButton';

export const absoluteFill: Pick<React.CSSProperties, 'position' | 'top' | 'left' | 'right' | 'bottom'> = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
};

export const centered: React.CSSProperties = {
  alignItems: 'center',
  justifyContent: 'center'
};

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexCol = styled(Col)`
  flex: 1;
`;

export const FlexRow = styled(Row)`
  flex: 1;
`;

export const TouchableRow = styled(Row)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.soft[30]};
  }
`;

export const TouchableCol = styled(Col)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.soft[30]};
  }
`;

export const Label = styled.div`
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`;

export const MainButton = styled(AppButton)`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  height: 48px;
  background: ${(props) => props.theme.colors.red[100]};
`;
