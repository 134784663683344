import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './en';
import trTranslations from './tr';

const resources = {
  en: enTranslations,
  tr: trTranslations
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    lng: localStorage.getItem('novusLng') || 'en',
    interpolation: {
      escapeValue: false
    },
    detection: {
      lookupQuerystring: 'novusLng',
      lookupLocalStorage: 'novusLng'
    }
  });

export default i18n;
