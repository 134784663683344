import { StringFormat, getDownloadURL, getStorage, ref, uploadBytes, uploadString } from 'firebase/storage';
import { apiService } from '../../service/service';

export const uploadCustomTemplateFileToStorage = async (file: File, userId?: string) => {
  const storageFilePath = `${userId}-${file.name}`;
  const validImageFileExtensions = ['.png', '.jpeg', '.jpg'];
  const validTextFileExtensions = ['.txt', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'];
  const validFileExtensions = [...validImageFileExtensions, ...validTextFileExtensions];

  const fileExtension = validFileExtensions.filter((value) => file.name.endsWith(value))[0];
  const isImageFile = validImageFileExtensions.includes(fileExtension ?? '');
  if (!fileExtension || !userId) throw new Error('Invalid file extension');

  const shouldTransform = validTextFileExtensions.includes(fileExtension) && fileExtension !== '.txt' && !isImageFile;
  const storageRef = ref(getStorage(), (shouldTransform ? 'Temp/' : 'AutoML/') + storageFilePath);

  const snapshot = await uploadBytes(storageRef, file);
  let url = await getDownloadURL(snapshot.ref);
  let size = file.size;

  if (shouldTransform) {
    const { text } = await apiService.fetchCustomTemplateFileText(url);
    const textStorageRef = ref(getStorage(), 'AutoML/' + storageFilePath);
    const textSnapshot = await uploadString(textStorageRef, text, StringFormat.RAW, { contentType: 'text/plain' });
    url = await getDownloadURL(textSnapshot.ref);
    size = text.length;
  }

  const thumbnail = isImageFile ? url : '';
  const type = isImageFile ? 'image' : 'text';
  const fileType = isImageFile ? file.type : 'text/plain';

  return {
    url,
    thumbnail,
    name: file.name,
    type,
    fileType,
    size
  };
};
