import { useTheme } from 'styled-components';

import { AppSelectboxOption } from 'utils/types';
import SelectboxDropdownHandle from 'features/common/SelectboxDropdownHandle';

import { Label, SelectedText, StyledSelect } from './styles';

type AppSelectboxProps = {
  defaultValue?: AppSelectboxOption | null;
  onChange: (newValues: AppSelectboxOption[]) => void;
  options: AppSelectboxOption[];
  label?: string;
  className?: string;
  placeholder?: string;
};

function AppSelectbox({
  defaultValue,
  onChange,
  label,
  options,
  className,
  placeholder = 'Selected...'
}: AppSelectboxProps) {
  const theme = useTheme();

  return (
    <div className={className}>
      <Label>{label}</Label>

      <StyledSelect
        className="novus-select"
        values={defaultValue ? [defaultValue] : []}
        options={options}
        onChange={onChange}
        color={theme.colors.black}
        contentRenderer={({ state }) => (
          <SelectedText>{state.values.length > 0 ? state.values[0].label : placeholder}</SelectedText>
        )}
        dropdownHandleRenderer={({ state }) => <SelectboxDropdownHandle isDropdownOpen={state.dropdown} />}
      />
    </div>
  );
}

export default AppSelectbox;
