import React, { Fragment, memo, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelect } from 'utils/hooks/useSelect';
import { fetchGoogleTrends } from 'redux/trends';
import styled, { useTheme } from 'styled-components';
import { Col, Label, Row, centered } from 'features/common/Styles';

export const HomeHotTopics = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['home']);
  const googleTrends = useSelect(({ trends }) => trends.googleTrends.slice(0, 5));

  // @effect

  useEffect(() => {
    dispatch(fetchGoogleTrends());
  }, []);

  return (
    <Container>
      <Title>🔥 {t('hotTopics')}</Title>
      <Row>
        {googleTrends.length ? (
          googleTrends.map((item, index) => (
            <ListItem key={index} style={centered}>
              <ListItemTitle>{item}</ListItemTitle>
            </ListItem>
          ))
        ) : (
          <Skeleton count={5} />
        )}
      </Row>
    </Container>
  );
});

const Skeleton = memo<{ count: number }>(({ count = 1 }) => {
  const { colors } = useTheme();

  return (
    <ContentLoader width={'100%'} height={'40px'} backgroundColor={colors.soft[70]}>
      {Array.from({ length: count }, (_, i) => i).map((skeletonNum) => (
        <Fragment key={`hot-topics-skeleton-${skeletonNum}`}>
          <rect x={108 * skeletonNum} y={0} rx="4" ry="4" width="100px" height="39px" />
        </Fragment>
      ))}
    </ContentLoader>
  );
});

const Title = styled(Label)`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  padding-bottom: 10px;
`;

const Container = styled(Col)`
  background-color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 50px;
  align-items: flex-start;
`;

const ListItem = styled(Row)`
  height: 39px;
  padding: 10px 16px;
  border-radius: 10px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.gray[10]};
  border: 1px solid ${({ theme }) => theme.colors.gray[20]};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
`;

const ListItemTitle = styled(Label)`
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
`;
