import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { ArticleFormType } from 'utils/types';

export const ArticleGenerationTypeSelect = memo<{
  articleId: string;
  onGenerationTypeChange: (newFormType: ArticleFormType) => void;
}>(({ articleId, onGenerationTypeChange }) => {
  const { t } = useTranslation('article');

  const generationType = useSelect(({ editor }) => {
    return editor.generationType?.[articleId] || 'text';
  });

  // @handlers

  const handleGenerationTypeClick = useCallback(
    (type: ArticleFormType) => () => {
      onGenerationTypeChange(type);
    },
    [onGenerationTypeChange]
  );

  // @render

  return (
    <Container>
      <Indicator style={{ transform: `translateX(${generationType === 'text' ? 0 : 104.5}px)` }} />

      <SelectText selected={generationType === 'text'} onClick={handleGenerationTypeClick('text')}>
        {t('text')}
      </SelectText>

      <SelectText selected={generationType === 'image'} onClick={handleGenerationTypeClick('image')}>
        {t('image')}
      </SelectText>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  position: relative;
  background-color: #edf0f9;
  padding: 2.5px;
  border-radius: 4px;
  height: 32px;
`;

const Indicator = styled.div`
  position: absolute;
  width: 106px;
  height: 27px;
  background-color: #3b0a6c;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
`;

const SelectText = styled.span.attrs((props: { selected: boolean }) => ({
  selected: props.selected ?? true
}))`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding: 4px 36px 3px 36px;
  border-radius: 4px;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  color: ${({ selected }) => (selected ? 'white' : '#25262a')};
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
`;
