import AppPopUp from 'features/common/AppPopUp';
import { FlexCol, Label } from 'features/common/Styles';
import { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { useEscapeKeydown } from 'utils/hooks/useKeydown';
import { useSelect } from 'utils/hooks/useSelect';
import { resetPlagiarism } from 'redux/article';
import { orderBy } from 'lodash';

const SOURCE_HEIGHT = 160;
const HEADER_HEIGHT = 50;

export const ArticleEditorPlagiarism = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation('article');

  const { modalType, setModalType, articleId } = useContext(ArticleContext);
  const plagiarism = useSelect(({ article }) => article.plagiarism[articleId] ?? null);
  const modalHeight = useMemo(() => {
    if (plagiarism?.sources.length) {
      const contentHeight = plagiarism.sources.length * SOURCE_HEIGHT;
      return Math.max(Math.min(HEADER_HEIGHT + contentHeight, 1200), 400);
    } else {
      return HEADER_HEIGHT;
    }
  }, [plagiarism?.sources.length]);

  const sources = useMemo(() => {
    if (plagiarism?.sources.length) {
      const trimmedSources = plagiarism.sources.map((source) => ({
        ...source,
        url: source.url.length > 75 ? source.url.slice(0, 75) + '...' : source.url
      }));

      return orderBy(trimmedSources, ['plagiarism'], ['desc']);
    } else {
      return [];
    }
  }, [plagiarism?.sources.length]);

  // @effects

  useEffect(() => {
    if (plagiarism?.sources.length) setModalType('plagiarism');
  }, [plagiarism?.sources.length]);

  useEscapeKeydown(() => {
    if (modalType === 'plagiarism') {
      dispatch(resetPlagiarism(articleId));
      setModalType(null);
    }
  }, [modalType]);

  // @handlers

  const handleCloseModal = useCallback(() => {
    dispatch(resetPlagiarism(articleId));
    setModalType(null);
  }, []);

  // @render

  return (
    <AppPopUp open={modalType === 'plagiarism'} onClose={handleCloseModal}>
      <Container height={modalHeight}>
        <Header height={HEADER_HEIGHT}>
          {t('generalSimilarity')} %{plagiarism?.globalPlagiarism}
        </Header>

        <Body>
          <HeaderText>{t('someOfThose')}</HeaderText>
          {sources.map((source, index) => (
            <Item key={index}>
              <SourceSentenceText>{source?.sentence ?? ''}</SourceSentenceText>
              <SourceHeaderText>{source?.name ?? ''}</SourceHeaderText>
              <SourceText href={source.url} target="_blank">
                {source.url}
                <b>{` %${source?.plagiarism ?? 0}`}</b>
              </SourceText>
            </Item>
          ))}
        </Body>
      </Container>
    </AppPopUp>
  );
});

const Container = styled(FlexCol)<{ height: number }>`
  border-radius: 12px;
  max-width: 90vw;
  max-height: 80vh;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  height: ${({ height }) => height}px;
  overflow: hidden;
`;

const Header = styled(Label)<{ height: number }>`
  display: flex;
  justify-content: start;
  background-color: ${({ theme }) => theme.colors.gray[60]};
  padding: 15px 23px;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  width: 100%;
  height: ${({ height }) => height}px;
  letter-spacing: 0em;
  text-align: left;
`;

const Body = styled(FlexCol)`
  padding: 24px 24px;
  padding-bottom: 44px;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.white};

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  padding: 16px 12px 10px 12px;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  border-radius: 4px;
`;

const DefaultText = styled(Label)`
  text-align: left;
  letter-spacing: 0em;
  color: ${({ theme }) => theme.colors.dark[90]};
  white-space: nowrap;
`;

const HeaderText = styled(DefaultText)`
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
`;

const SourceHeaderText = styled(DefaultText)`
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 4px;
`;

const SourceSentenceText = styled(DefaultText)`
  font-size: 13px;
  white-space: break-spaces;
  font-weight: 400;
  line-height: 17px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[60]};
  margin-bottom: 12px;
`;

const SourceText = styled.a`
  font-family: Nunito Sans;
  text-align: left;
  letter-spacing: 0em;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 4px;
  /* color: ${({ theme }) => theme.colors.dark[90]}; */

  text-decoration: none;
`;
