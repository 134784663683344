import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  inset: 0px;

  background-color: ${({ theme }) => theme.colors.overlay[40]};

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 99;
`;
