import { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';

export const AppInput = forwardRef<
  HTMLInputElement,
  {
    value: string;
    onChange: (newValue: string) => void;
    placeholder?: string;
    className?: string;
    type?: string;
    name?: string;
    disabled?: boolean;
    autoComplete?: string;
  }
>(({ placeholder, className, type, value, onChange, name, disabled = false, autoComplete }, ref) => {
  //MARK: - Hooks

  const [focus, setFocus] = useState<boolean>(false);

  // @effects

  useEffect(() => {
    if (value) {
      setFocus(true);
    }
  }, [value]);

  // @render

  return (
    <InputContainer>
      <InputLabel focus={focus}>{placeholder}</InputLabel>
      <TextInput
        ref={ref}
        autoComplete={autoComplete}
        name={name}
        className={className}
        onFocus={() => (value.length >= 0 ? setFocus(true) : null)}
        onBlur={() => (value.length === 0 ? setFocus(false) : null)}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        focus={focus}
        type={type}
        disabled={disabled}
      />
    </InputContainer>
  );
});

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;

  position: relative;
`;

const InputLabel = styled.label.attrs((props: { focus: boolean }) => ({
  focus: props.focus
}))`
  font-family: Nunito Sans;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  font-size: ${({ focus }) => (focus ? '12px' : '14px')};
  line-height: ${({ focus }) => (focus ? '19px' : '16px')};

  position: absolute;

  color: ${({ theme, focus }) => {
    if (focus) return theme.colors.dark[70];
    else return theme.colors.dark[90];
  }};

  top: ${({ focus }) => (focus ? '12%' : '35%')};
  left: 20px;

  transition: all 0.2s ease-in-out;

  pointer-events: none;
`;

const TextInput = styled.input.attrs((props: { focus: boolean; disabled: boolean }) => ({
  focus: props.focus,
  disabled: props.disabled
}))`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${({ focus }) => (focus ? '30px 20px 14px 20px' : '22px 20px')};

  gap: 10px;

  width: 100%;
  min-width: 420px;

  background: ${({ theme }) => theme.colors.white};

  border: 1px solid ${({ theme }) => theme.colors.gray[60]};
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;
