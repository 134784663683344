import { Grid, Typography } from '@mui/material';
import { ReactComponent as NovusMiniSvg } from 'assets/img/NovusMini.svg';
import NovusWriterSvg from 'assets/img/novusWriter.svg';
import { ReactNode } from 'react';

export const WelcomePageLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Grid component="main" container>
      <Grid style={{ padding: 8 }} xs={6} justifyContent="center" alignContent="space-around" item container>
        <Grid justifyContent="center" flexDirection="column" alignItems="center" container>
          <NovusMiniSvg color="#59159C" height={60} width={60} />
          <br />
          <img src={NovusWriterSvg} alt="Novus Logo" height="20px" />
        </Grid>
        <Grid xs={6} justifyContent="center" alignContent="space-around" item container>
          {children}
        </Grid>
        <Grid justifyContent="flex-end" flexDirection="column" style={{ color: '#1D1F2F' }} container>
          <Typography fontWeight={600} fontSize={14} align="center">
            {`Copyright © Novus ${new Date().getFullYear()}`}.
          </Typography>
        </Grid>
      </Grid>
      <Grid style={{ background: '#000000 url("/welcomeBg.png")', height: '100vh' }} xs={6} item />
    </Grid>
  );
};
