import AppButton from 'features/common/AppButton';
import { AppInput } from 'features/common/AppInput';
import AppPopUp from 'features/common/AppPopUp';
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { updateArticle } from 'redux/article';
import { getEntity } from 'redux/entity';
import styled from 'styled-components';
import { HomeContext } from 'utils/contexts/HomeContext';
import { useEnterKeydown, useEscapeKeydown } from 'utils/hooks/useKeydown';
import { useSelect } from 'utils/hooks/useSelect';

export const EditArticleModal = memo(() => {
  const ref = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const { t } = useTranslation(['home', 'common']);

  const { selectedArticleId, modalType, setModalType } = useContext(HomeContext);
  const article = useSelect(({ entity }) => getEntity(entity, 'article', selectedArticleId));

  const [title, setTitle] = useState(article?.title ?? '');

  // @effects

  useEscapeKeydown(() => {
    if (modalType === 'editArticle') setModalType(null);
  }, [modalType]);

  useEnterKeydown(() => {
    if (modalType === 'editArticle') handleUpdateClick();
  }, [title, article, modalType]);

  useEffect(() => {
    setTitle(article?.title ?? '');
  }, [article]);

  useEffect(() => {
    if (modalType === 'editArticle') ref.current?.focus();
  }, [modalType]);

  // @handlers

  const handleUpdateClick = useCallback(() => {
    if (title.trim() === '') return toast.info(t('notEmptyProjectName'));
    if (article) dispatch(updateArticle(article.id, { title }));
    setModalType(null);
    setTitle('');
  }, [article, title]);

  // @render

  return (
    <AppPopUp open={modalType === 'editArticle'} onClose={() => setModalType(null)}>
      <Container>
        <Title>{`📄 ${t('editProject')}`}</Title>
        <ProjectNameInput ref={ref} placeholder={t('projectName')} value={title} onChange={setTitle} />
        <SaveButton text={t('save')} onClick={handleUpdateClick} />
      </Container>
    </AppPopUp>
  );
});

const Container = styled.div`
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  color: ${(props) => props.theme.colors.black};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ProjectNameInput = styled(AppInput)`
  min-width: 380px !important;
  width: 380px !important;
`;

const SaveButton = styled(AppButton)`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 20px;
  background: ${({ theme }) => theme.colors.dark[100]};
  border-radius: 4px;
`;
