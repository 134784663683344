import React, { PropsWithChildren, createContext, useState } from 'react';

type ArticleModalType =
  | 'saveDraft'
  | 'customTemplate'
  | 'proofRead'
  | 'plagiarism'
  | 'bookmark'
  | 'customTemplatePreparing'
  | 'limitError';

export const ArticleContext = createContext<{
  articleId: string;
  editorText: string;
  modalType: ArticleModalType | null;
  setEditorText: (text: string) => void;
  setModalType: (type: ArticleModalType | null) => void;
}>({
  articleId: '',
  editorText: '',
  modalType: null,
  setEditorText: () => {},
  setModalType: () => {}
});

export const ArticleProvider: React.FC<PropsWithChildren<{ articleId: string }>> = ({ articleId, children }) => {
  const [modalType, setModalType] = useState<ArticleModalType | null>(null);
  const [editorText, setEditorText] = useState<string>('');

  return (
    <ArticleContext.Provider
      value={{
        articleId,
        editorText,
        setEditorText,
        modalType,
        setModalType
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};
