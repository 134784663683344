import AppButton from 'features/common/AppButton';
import { AppInput } from 'features/common/AppInput';
import { Spinner } from 'features/common/Spinner';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateuserMutation } from 'redux/user/api';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';

const PersonalInformation = () => {
  const { t } = useTranslation('account');

  const [updateuserApi, { isLoading: isUpdateLoading, isError: isUpdateError, isSuccess: isUpdateSuccess }] =
    useUpdateuserMutation();

  const user = useSelect(({ me }) => me.user);

  const [name, setName] = useState(user?.first_name || '');
  const [surname, setSurname] = useState(user?.last_name || '');
  const [email, setEmail] = useState(user?.email || '');

  useEffect(() => {
    if (isUpdateError) toast.error(t('updateError'));
    else if (isUpdateSuccess) toast.success(t('updateSuccess'));
  }, [isUpdateLoading, isUpdateError, isUpdateSuccess, t]);

  return (
    <Container>
      <Title>{t('personalInformation')}</Title>
      <AppInput name="firstName" value={name} onChange={setName} placeholder={t('name')} />
      <AppInput name="lastName" value={surname} onChange={setSurname} placeholder={t('surname')} />
      <AppInput name="email" value={email} onChange={setEmail} placeholder={t('email')} disabled />
      <SaveButton
        text={t('save')}
        customSpinner={<Spinner />}
        loading={isUpdateLoading}
        disabled={isUpdateLoading}
        onClick={() => updateuserApi({ first_name: name, last_name: surname, email })}
      />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.soft[40]};
  flex: 10;
  padding: 40px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow-y: auto;

  & :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Title = styled.p`
  font-family: Nunito Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 48px !important;
`;

const SaveButton = styled(AppButton)`
  margin-top: 48px !important;

  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 8px;
  gap: 10px;

  width: 200px;
  height: 40px;

  background: ${({ theme }) => theme.colors.dark[100]};
  border-radius: 4px;

  flex: none;
  order: 2;
  flex-grow: 0;
`;

export default PersonalInformation;
