import produce from 'immer';
import { Dispatch } from 'react';
import { toast } from 'react-toastify';

export type QueryState = 'loading' | 'success' | 'failure';
export type QueryKey = string;

type State = {
  observers: Record<string, QueryState | null>;
};

export type QueryAction = {
  type: 'query/setState';
  key: QueryKey | string;
  state: QueryState | null;
};

// @Reducer

export const queryReducer = (
  state: State = { observers: {} },
  action: QueryAction | { type: 'account/logout' }
): State => {
  switch (action.type) {
    case 'query/setState':
      return produce(state, (draft) => {
        if (action.state === null) delete draft.observers[action.key];
        else draft.observers[action.key] = action.state;
      });

    case 'account/logout':
      return { observers: {} };

    default:
      return state;
  }
};

// @Actions

export const setQueryState = (key: QueryKey | string, state: QueryState | null): QueryAction => ({
  type: 'query/setState',
  key,
  state
});

// @Extensions

Promise.prototype.first = async function <U>(action: (() => Promise<void>) | (() => void)): Promise<U> {
  await action();
  return this;
};

Promise.prototype.observe = async function <U>(key: QueryKey, dispatch: Dispatch<QueryAction>): Promise<U> {
  dispatch(setQueryState(key, 'loading'));
  try {
    const response = await this;
    dispatch(setQueryState(key, 'success'));
    return response;
  } catch (error) {
    dispatch(setQueryState(key, 'failure'));
    throw error;
  }
};

Promise.prototype.catchError = async function <U>(dispatch: Dispatch<any>): Promise<U> {
  return this.catch((error: any) => {
    try {
      error = JSON.parse(error.message);
      if (!error) throw new Error();
      const message = error.error || error.reason || error.message || error;
      toast.error(message);
    } catch (_) {
      toast.error(error.message);
    }
  });
};
