import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setCustomTemplateSelectedFiles } from 'redux/customTemplate';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { Col, Label } from '../common/Styles';
import { CustomTemplateUploadedFileCard } from './CustomTemplateUploadedFileCard';

export const CustomTemplateAddedFiles = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation('article');

  const files = useSelect(({ customTemplate }) => customTemplate.selectedFiles);

  // @handlers

  const handleRemoveFileClick = useCallback(
    (fileId: string) => () => {
      const fileIndex = files.findIndex((file) => file.id === fileId);
      if (fileIndex === -1) return;

      const newFilesAdded = [...files];
      newFilesAdded.splice(fileIndex, 1);
      dispatch(setCustomTemplateSelectedFiles(newFilesAdded));
    },
    [files, dispatch]
  );

  // @render

  return (
    <Container>
      <Input centerContent={!files.length}>
        {!files.length ? (
          <Col>
            <Placeholder>{t('customAiAddedFilesPlaceholder')}</Placeholder>
            <PlaceholderDescription>{t('customAiAddedFilesDescription')}</PlaceholderDescription>
          </Col>
        ) : (
          files.map((file) => (
            <StyledUploadedFileCard key={file.id} file={file} active onClickClose={handleRemoveFileClick(file.id)} />
          ))
        )}
      </Input>
    </Container>
  );
});

const Container = styled(Col)`
  padding-top: 12px;
`;

const Input = styled.div<{ centerContent?: boolean }>`
  height: 132px;
  padding: 0px 10px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.soft[60]};
  background-color: ${({ theme }) => theme.colors.soft[10]};
  overflow-x: scroll;
  display: flex;
  justify-content: ${({ centerContent }) => (centerContent ? 'center' : 'flex-start')};
  align-items: center;
  gap: 7px;
`;

const Placeholder = styled(Label)`
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.purple[90]};
`;

const PlaceholderDescription = styled(Label)`
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  opacity: 0.6;
  margin-top: 6px;
  color: ${({ theme }) => theme.colors.purple[90]};
`;

const StyledUploadedFileCard = styled(CustomTemplateUploadedFileCard)`
  flex-shrink: 0;
  width: 115px;
  height: 96px;
`;
