import { ReactComponent as DownArrow } from 'assets/img/icons/down-arrow.svg';
import { Col, FlexRow, Label, Row } from 'features/common/Styles';
import { orderBy } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { animated, easings, useSpring } from 'react-spring';
import { getEntities, getEntity } from 'redux/entity';
import { toggleOpenFolderId } from 'redux/folder';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { Article } from 'utils/types';
import { DrawerFolderArticleListItem } from './DrawerFolderArticleListItem';

export const DrawerFolderAccordion = memo<{
  folderId: string;
  index: number;
  onArticleClick: (article: Article) => void;
}>(({ folderId, index, onArticleClick }) => {
  const dispatch = useDispatch();

  const openFolderIds = useSelect(({ folder }) => folder.openDrawerFolderIds);
  const folder = useSelect(({ entity }) => getEntity(entity, 'folder', folderId));
  const articles = useSelect(({ entity }) => {
    const allArticles = getEntities(entity, 'article').filter(({ folder_id }) => folder_id === folderId);
    return orderBy(allArticles, ['created_at'], ['desc']);
  });

  const [open, setOpen] = useState(openFolderIds.includes(folderId));

  const openAnimation = useSpring({
    from: { opacity: 0, maxHeight: '0px' },
    to: {
      opacity: open ? 1 : 0,
      maxHeight: open ? `${articles.length * 90}px` : '0px',
      display: open ? 'block' : 'none'
    },
    config: { duration: 300, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  const arrowAnimation = useSpring({
    from: { opacity: 1, rotate: '0deg' },
    to: { rotate: open ? '-180deg' : '0deg' },
    config: { duration: 250, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  // @effects

  useEffect(() => {
    dispatch(toggleOpenFolderId(folderId, open));
  }, [open, folderId]);

  // @handlers

  const handleToggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleArticleClick = useCallback(
    (article: Article) => {
      onArticleClick(article);
    },
    [onArticleClick]
  );

  // @render

  return (
    <Col style={{ zIndex: index }}>
      <Container>
        <TitleContainer onClick={handleToggleOpen}>
          <FlexRow style={{ alignItems: 'center' }}>
            <Title style={{ marginTop: 1 }}>📁</Title>
            <Title>{folder?.name || 'Folder'}</Title>

            <Subtitle>{`(${articles.length})`}</Subtitle>
          </FlexRow>
          <animated.div style={arrowAnimation}>
            <DownArrow width={8} />
          </animated.div>
        </TitleContainer>

        <animated.div style={{ ...openAnimation, width: '100%' }}>
          <ListContainer>
            {articles.map((article, itemIndex) => (
              <DrawerFolderArticleListItem
                key={article.id}
                article={article}
                isLastItem={itemIndex === articles.length - 1}
                onArticleClick={handleArticleClick}
              />
            ))}
          </ListContainer>
        </animated.div>
      </Container>
    </Col>
  );
});

const Container = styled(animated.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;
  min-width: 190px;
  transition: 0.2s ease-in-out;
`;

const ListContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Label)`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-right: 6px;
  color: white;
`;

const TitleContainer = styled(Row)`
  width: 100%;
  font-size: 14px;
  justify-content: space-between;
  font-weight: 400;
  line-height: 20px;
  color: white;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  min-width: 190px;
  background-color: rgba(255, 255, 255, 0.05);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Subtitle = styled(Label)`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 6px;
  opacity: 0.8;
  margin-left: -1px;
  margin-right: 10px;
  color: white;
`;
