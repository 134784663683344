import SpinnerIcon from 'features/common/Icons/SpinnerIcon';
import { SentencesListIllustrator } from 'features/common/SentencesListIllustrator';
import { memo, useContext } from 'react';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { useQuery } from 'utils/hooks/useQuery';
import { useSelect } from 'utils/hooks/useSelect';
import { AIImageGeneration, AITextGeneration } from 'utils/types';
import { ArticleGenerationImageCard } from './ArticleGenerationImageCard';
import { ArticleGenerationTabList } from './ArticleGenerationTabList';
import { ArticleGenerationTextCard } from './ArticleGenerationTextCard';

export const ArticleGenerationList = memo(() => {
  const { articleId } = useContext(ArticleContext);

  const tabId = useSelect(({ editor }) => editor.selectedTabId[articleId] ?? '');
  const generations = useSelect(({ article }) => {
    type TextGeneration = { type: 'Text'; generations: AITextGeneration };
    type ImageGeneration = { type: 'Image'; generations: AIImageGeneration };

    let textGenerations = (article.textGenerations[articleId] ?? []).filter(({ tab }) => tab.id === tabId);
    let imageGenerations = (article.imageGenerations[articleId] ?? []).filter(({ tab }) => tab.id === tabId);

    const textItems: TextGeneration[] = textGenerations.map((item) => ({ type: 'Text', generations: item }));
    const imageItems: ImageGeneration[] = imageGenerations.map((item) => ({ type: 'Image', generations: item }));
    return [...textItems, ...imageItems].sort((a, b) => b.generations.created_at - a.generations.created_at);
  });
  const { isLoading } = useQuery('regenerate_text' + articleId);

  // @render

  return (
    <Container>
      <ArticleGenerationTabList />

      {!generations.length ? (
        <SentencesListIllustrator />
      ) : (
        <SentencesList>
          {isLoading && (
            <LoadingContainer>
              <SpinnerIcon />
            </LoadingContainer>
          )}
          {generations.map(({ type, generations: item }, index) =>
            type === 'Text' ? (
              item.generations.map((generation, generationIndex) => (
                <ArticleGenerationTextCard
                  key={`ai-generated-text-${generationIndex}-${generationIndex}`}
                  generationId={item.id}
                  suggestionIndex={generationIndex}
                  content={generation.content}
                  keywords={item.keywords[generationIndex]?.split(',') ?? []}
                  objectives={generation.statement_objectives}
                  objectives_en={generation['statement_objectives_en']}
                  fact={generation?.facts?.[generationIndex]}
                  buttonFact={generation?.facts}
                  generations={item as AITextGeneration}
                />
              ))
            ) : (
              <ArticleGenerationImageCard key={index} id={item.id} imageUrls={item.pictures} />
            )
          )}
        </SentencesList>
      )}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-height: calc(100vh - 60px);
`;

const SentencesList = styled.div`
  flex: 1;
  overflow-y: scroll;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  svg {
    width: 50px;
    height: 50px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin 1.7s linear infinite;
`;
