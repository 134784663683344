import { Redirect, Route } from 'react-router-dom';
import { WelcomePageLayout } from './common/WelcomePageLayout';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';

export const WelcomePage = () => {
  return (
    <WelcomePageLayout>
      {routes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
    </WelcomePageLayout>
  );
};

const routes = [
  { exact: true, path: '/login', component: Login },
  { exact: true, path: '/forgot-password', component: ForgotPassword },
  { exact: true, path: '*', component: () => <Redirect to="/login" /> }
];
