import { ReactNode } from 'react';
import SpinWhite from 'assets/img/spinWhite.svg';
import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';

export const Spinner = memo<{
  customSpinner?: ReactNode;
  className?: string;
}>(({ customSpinner, className }) => {
  return <Container className={className}>{customSpinner || <img src={SpinWhite} alt="spinner" />}</Container>;
});

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Container = styled.div`
  width: 20px;
  height: 20px;
  animation: ${rotate} 2s linear infinite;
`;
