import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CustomTemplateInformationStep } from './CustomTemplateInformationStep';
import { ItemTitleIcon, CustomTemplateInformationCard } from './CustomTemplateInformationCard';
import { ReactComponent as StrollingImg } from 'assets/img/icons/Strolling.svg';
import AlarmClock from 'assets/img/icons/AlarmClock.png';
import SpeechBalloon from 'assets/img/icons/SpeechBalloon.png';
import { memo } from 'react';

export const CustomTemplateResult = memo<{
  onClickContinue: () => void;
}>(({ onClickContinue }) => {
  const { t } = useTranslation('article');

  const renderResultTitle = () => {
    return (
      <>
        <StrollingImg />
        <Title>{t('resultTitle')}</Title>
      </>
    );
  };

  return (
    <CustomTemplateInformationStep
      title={renderResultTitle()}
      ctaButtonText={t('resultButton')}
      onClickCTAButton={onClickContinue}
      blockButton
    >
      <CustomTemplateInformationCard
        title={t('resultItemTitles.1')}
        icon={<ItemTitleIcon src={AlarmClock} alt="alarm-clock" />}
        description={t('resultItemDescriptions.1')}
      />
      <CustomTemplateInformationCard
        title={t('resultItemTitles.2')}
        icon={<ItemTitleIcon src={SpeechBalloon} alt="speech-ballon" />}
        description={t('resultItemDescriptions.2')}
      />
    </CustomTemplateInformationStep>
  );
});

const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: ${({ theme }) => theme.colors.primary[30]};
  margin-top: 20px;
`;
