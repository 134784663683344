import { getApps, initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { connectAuthEmulator, getAuth } from 'firebase/auth';

const firebaseConfig = {
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
};

initializeApp(firebaseConfig);

const app = getApps().length ? getApps()[0] : initializeApp(firebaseConfig);
export const storage = getStorage();
export const auth = getAuth();

if (process.env.REACT_APP_ENVIRONMENT === 'local') connectAuthEmulator(auth, 'http://localhost:9099');

export default app;
