import { ReactComponent as BackIcon } from 'assets/img/icons/BackIcon.svg';
import PlusIcon from 'features/common/Icons/Plus';
import { Col, FlexCol, Label, Row } from 'features/common/Styles';
import { orderBy } from 'lodash';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getEntities } from 'redux/entity';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { useSelect } from 'utils/hooks/useSelect';
import { Template } from 'utils/types';
import { ArticleDrawerCustomTemplateListItem } from './ArticleDrawerCustomTemplateListItem';

export const ArticleDrawerCustomTemplates = memo<{
  onBackClick: () => void;
  onTemplateClick: (template: Template) => void;
}>(({ onBackClick, onTemplateClick }) => {
  const { t } = useTranslation(['article', 'common']);
  const { articleId, setModalType } = useContext(ArticleContext);

  const selectedTemplateId = useSelect(({ editor }) => editor.selectedTextTemplateId[articleId] ?? '');
  const customTemplates = useSelect(({ entity }) => {
    let templates = getEntities(entity, 'template', ({ parent }) => parent === 'UserTemplate');
    templates = templates.map((template) => ({ ...template, created_at: template.created_at ?? -Date.now() }));
    return orderBy(templates, ['created_at', 'order'], ['desc']);
  });

  // @render

  return (
    <FlexCol>
      <Col style={{ paddingTop: 23, alignItems: 'center', minWidth: 230 }}>
        <Col>
          <Header>
            <BackButton onClick={onBackClick}>
              <BackIcon />
            </BackButton>
            <Title>{t('customTemplates')}</Title>
          </Header>

          <CustomTemplateButton onClick={() => setModalType('customTemplate')}>
            <PlusIcon size={14} />

            <Label style={{ fontWeight: 600, fontSize: 14, color: 'white', marginLeft: 10 }}>
              {t('customTemplate')}
            </Label>
          </CustomTemplateButton>
        </Col>

        <FlexCol style={{ paddingRight: 20, paddingLeft: 20, minWidth: 190 }}>
          {customTemplates.map((template) => (
            <ArticleDrawerCustomTemplateListItem
              key={template.id}
              template={template}
              isSelected={template.id === selectedTemplateId}
              isLastItem={false}
              removePadding
              onTemplateClick={onTemplateClick}
            />
          ))}
        </FlexCol>
      </Col>
    </FlexCol>
  );
});

const Title = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  flex: 1;
  color: ${({ theme }) => theme.colors.white};
`;

const Header = styled(Row)`
  align-items: center;
  padding-right: 35px;
  padding-left: 8px;
  justify-content: space-between;
  min-width: 230px;
  margin-bottom: 8px;
`;

const BackButton = styled(Row)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
`;

const CustomTemplateButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 0px;
  margin: 0px 20px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: rgba(255, 255, 255, 0.05);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
