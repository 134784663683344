import { Action, Thunk } from 'redux/store';
import { apiService } from '../service/service';
import { DeleteAction, MergeAction, deleteEntities, getEntity, mergeEntities } from './entity';
import produce from 'immer';

type State = {
  openFolderIds: string[];
  openTemplateParents: string[];
  openDrawerFolderIds: string[];
};

export const initialState: State = {
  openFolderIds: [],
  openTemplateParents: [],
  openDrawerFolderIds: []
};

type ActionType =
  | { type: 'folder/setOpenFolderIds'; folderIds: string[] }
  | { type: 'folder/setOpenTemplateParents'; templateParents: string[] }
  | { type: 'folder/setOpenDrawerFolderIds'; folderIds: string[] }
  | MergeAction
  | DeleteAction
  | Action<'me/logout'>;

export const folderReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'folder/setOpenFolderIds':
      return produce(state, (draft) => {
        draft.openFolderIds = action.folderIds;
      });

    case 'folder/setOpenDrawerFolderIds':
      return produce(state, (draft) => {
        draft.openDrawerFolderIds = action.folderIds;
      });

    case 'folder/setOpenTemplateParents':
      return produce(state, (draft) => {
        draft.openTemplateParents = action.templateParents;
      });

    case 'me/logout':
      return initialState;

    default:
      return state;
  }
};

// @actions

export const toggleOpenFolderId =
  (folderId: string, open: boolean): Thunk<ActionType> =>
  (dispatch, getState) => {
    const { openFolderIds } = getState().folder;
    const folderIds = open ? [...openFolderIds, folderId] : openFolderIds.filter((id) => id !== folderId);
    dispatch({ type: 'folder/setOpenFolderIds', folderIds });
  };

export const toggleOpenTemplateParent =
  (templateParent: string, open: boolean): Thunk<ActionType> =>
  (dispatch, getState) => {
    const { openTemplateParents } = getState().folder;
    const templateParents = open
      ? [...openTemplateParents, templateParent]
      : openTemplateParents.filter((id) => id !== templateParent);
    dispatch({ type: 'folder/setOpenTemplateParents', templateParents });
  };

export const toggleOpenDrawerFolderId =
  (folderId: string, open: boolean): Thunk<ActionType> =>
  (dispatch, getState) => {
    const { openDrawerFolderIds } = getState().folder;
    const folderIds = open ? [...openDrawerFolderIds, folderId] : openDrawerFolderIds.filter((id) => id !== folderId);
    dispatch({ type: 'folder/setOpenDrawerFolderIds', folderIds });
  };

export const fetchFolders = (): Thunk<ActionType> => (dispatch) =>
  apiService
    .fetchFolders()
    .then((folders) => dispatch(mergeEntities({ folder: folders })))
    .observe('fetch_folders', dispatch)
    .catchError(dispatch);

export const createFolder =
  (name: string): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .createFolder(name)
      .then((folder) => dispatch(mergeEntities({ folder: [folder] })))
      .observe('create_folder', dispatch)
      .catchError(dispatch);

export const updateFolder =
  (folderId: string, name: string): Thunk<ActionType> =>
  (dispatch, getState) =>
    apiService
      .updateFolder(folderId, name)
      .first(() => {
        const folder = getEntity(getState().entity, 'folder', folderId);
        if (folder) dispatch(mergeEntities({ folder: [{ ...folder, name }] }));
      })
      .then((folder) => dispatch(mergeEntities({ folder: [folder] })))
      .observe('update_folder', dispatch)
      .catchError(dispatch);

export const deleteFolder =
  (id: string): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .deleteFolder(id)
      .first(() => dispatch(deleteEntities({ folder: [id] })))
      .observe('delete_folder', dispatch)
      .catchError(dispatch);
