import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ThunkAction } from 'redux-thunk';
import { adminReducer } from './admin';
import api from './api';
import { articleReducer } from './article';
import AuthReducer from './auth/slice';
import { bookmarkReducer } from './bookmark';
import { customTemplateReducer } from './customTemplate';
import { editorReducer } from './editor';
import { entityReducer } from './entity';
import { folderReducer } from './folder';
import { meReducer } from './me';
import { queryReducer } from './query';
import { tabReducer } from './tab';
import { trendsReducer } from './trends';

export type Action<T> = { type: T };

// @ts-ignore
export type Thunk<U = Action<any>, T = void> = ThunkAction<T, RootState, unknown, U>;

export type AsyncThunk<U = Action<any>, ReturnType = void> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  unknown,
  // @ts-ignore
  U
>;

const persistedFolderReducer = persistReducer({ key: 'folder', storage }, folderReducer);
const persistedTrendsReducer = persistReducer({ key: 'trends', storage }, trendsReducer);
const persistedEntityReducer = persistReducer({ key: 'entity', storage }, entityReducer);
const persistedUserReducer = persistReducer({ key: 'me', storage, whitelist: ['user', 'userPlan'] }, meReducer);
const persistedArticleReducer = persistReducer(
  { key: 'article', storage, blacklist: ['similarities', 'plagiarism', 'combinedFacts'] },
  articleReducer
);
const persistedEditorReducer = persistReducer({ key: 'editor', storage }, editorReducer);

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    _auth: AuthReducer,
    admin: adminReducer,
    article: persistedArticleReducer,
    bookmark: bookmarkReducer,
    customTemplate: customTemplateReducer,
    editor: persistedEditorReducer,
    entity: persistedEntityReducer,
    folder: persistedFolderReducer,
    me: persistedUserReducer,
    query: queryReducer,
    tab: tabReducer,
    trends: persistedTrendsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(api.middleware)
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
