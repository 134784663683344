import PlusIcon from 'features/common/Icons/Plus';
import { Col, Label, Row } from 'features/common/Styles';
import { orderBy } from 'lodash';
import { Fragment, memo, useCallback, useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { getEntities } from 'redux/entity';
import styled, { useTheme } from 'styled-components';
import { HomeContext } from 'utils/contexts/HomeContext';
import { useQuery } from 'utils/hooks/useQuery';
import { useSelect } from 'utils/hooks/useSelect';
import { Article } from 'utils/types';
import { HomeFolderAccordion } from './HomeFolderAccordion';

export const HomeFolders = memo<{
  onArticleClick: (article: Article) => void;
}>(({ onArticleClick }) => {
  const { t } = useTranslation(['home', 'article', 'common']);

  const { isLoading: isCreatingFolder } = useQuery('create_folder');
  const articles = useSelect(({ entity }) => getEntities(entity, 'article'));
  const folders = useSelect(({ entity }) => {
    const articleFolderMap = articles.reduce((acc, article) => {
      if (article.folder_id) {
        acc[article.folder_id] = acc[article.folder_id] || [];
        acc[article.folder_id].push(article);
      }
      return acc;
    }, {} as Record<string, Article[]>);
    let entities = getEntities(entity, 'folder').map((folder) => ({
      ...folder,
      article_count: articleFolderMap[folder.id]?.length ?? 0,
      created_at: -folder.created_at
    }));
    entities = entities.filter(({ is_draft, article_count }) => !is_draft || article_count > 0);
    return orderBy(entities, ['is_draft', 'created_at'], ['desc']).reverse();
  });

  const { setModalType } = useContext(HomeContext);

  // @handlers

  const handleCreateFolderClick = useCallback(() => {
    setModalType('newFolder');
  }, []);

  // @render

  return (
    <Col>
      <FoldersHeader>
        <Title isFolder>📁 {t('myFolders')} </Title>
        <CreateFolderButton onClick={handleCreateFolderClick}>
          <PlusIcon size={13} />
          <p style={{ marginLeft: 4 }}>{t('newFolder')}</p>
        </CreateFolderButton>
      </FoldersHeader>

      {folders.length ? (
        folders.map(({ id }, index) => (
          <HomeFolderAccordion key={id} folderId={id} index={index} onArticleClick={onArticleClick} />
        ))
      ) : (
        <Skeleton count={3} />
      )}

      {isCreatingFolder ? <Skeleton count={1} /> : null}
    </Col>
  );
});

export const Skeleton = memo<{ count: number }>(({ count = 1 }) => {
  const theme = useTheme();

  return (
    <ContentLoader width={'100%'} height={'250px'} backgroundColor={theme.colors.soft[70]}>
      {Array.from({ length: count }, (_, i) => i).map((skeletonNum) => (
        <Fragment key={`my-folders-skeleton-${skeletonNum}`}>
          <rect x={0} y={70 * skeletonNum} rx="4" ry="4" width="100%" height="60px" />
        </Fragment>
      ))}
    </ContentLoader>
  );
});

const Title = styled(Label)<{ isFolder?: boolean }>`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  padding-bottom: 10px;
  ${({ isFolder, theme }) => isFolder && `color: ${theme.colors.black};`}
`;

const CreateFolderButton = styled(Row)`
  align-items: center;
  background-color: #404163;
  cursor: pointer;
  padding: 10px 22px;
  border-radius: 4px;
  color: white;
  font-weight: 700;
  font-size: 12px;
`;

const FoldersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 27.5px;
`;
