import { ReactNode } from 'react';
import styled from 'styled-components';

type CustomTemplateInformationCardProps = {
  icon: ReactNode;
  title: string;
  description: string;
};
export function CustomTemplateInformationCard({ icon, title, description }: CustomTemplateInformationCardProps) {
  return (
    <ItemContainer>
      <ItemTitleContainer>
        {icon}
        <ItemTitle>{title}</ItemTitle>
      </ItemTitleContainer>
      <ItemDescription>{description}</ItemDescription>
    </ItemContainer>
  );
}

//#region Styles
const ItemContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[10]};

  padding: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const ItemTitleContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 10px;
`;

export const ItemTitleIcon = styled.img`
  width: 20px;
  height: 20px;

  margin: 0px 10px;
`;

const ItemTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.primary[30]};
`;
const ItemDescription = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.primary[30]};
`;

//#endregion
