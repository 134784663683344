import { orderBy } from 'lodash';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { getEntities } from 'redux/entity';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { Article } from 'utils/types';
import { Label } from '../common/AppSelectbox/styles';
import { DrawerFolderAccordion } from './DrawerFolderAccordion';

export const DrawerFolderList = memo<{
  articleId: string;
  onArticleClick: (article: Article) => void;
}>(({ onArticleClick }) => {
  const { t } = useTranslation('home');

  const articles = useSelect(({ entity }) => getEntities(entity, 'article'));
  const folders = useSelect(({ entity }) => {
    const articleFolderMap = articles.reduce((acc, article) => {
      if (article.folder_id) {
        acc[article.folder_id] = acc[article.folder_id] || [];
        acc[article.folder_id].push(article);
      }
      return acc;
    }, {} as Record<string, Article[]>);
    let entities = getEntities(entity, 'folder').map((folder) => ({
      ...folder,
      article_count: articleFolderMap[folder.id]?.length ?? 0,
      created_at: -folder.created_at
    }));
    entities = entities.filter(({ is_draft, article_count }) => !is_draft || article_count > 0);
    return orderBy(entities, ['is_draft', 'created_at'], ['desc']).reverse();
  });

  // @render

  return (
    <Container>
      <Title>📁 {t('home:myFolders')}</Title>
      {folders.length
        ? folders.map(({ id }, index) => (
            <DrawerFolderAccordion key={id} folderId={id} index={index} onArticleClick={onArticleClick} />
          ))
        : null}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  & > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Title = styled(Label)`
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  line-height: 19px;
  min-width: 190px;
  color: ${({ theme }) => theme.colors.gray[15]};
  margin-bottom: 10px !important;
`;
