import styled from 'styled-components';
import AppPopUp from 'features/common/AppPopUp';
import { ReactComponent as Close2SVG } from 'assets/img/icons/Close2.svg';
import { ReactComponent as BodyMacbookSVG } from 'assets/img/icons/BodyMacbook.svg';
import AppButton from 'features/common/AppButton';
import { useTranslation } from 'react-i18next';
import { memo, useCallback, useContext } from 'react';
import { HomeContext } from 'utils/contexts/HomeContext';
import { useEscapeKeydown } from 'utils/hooks/useKeydown';

export const CustomTemplatePreparingModal = memo(() => {
  const { t } = useTranslation('article');
  const { modalType: homeModalType, setModalType: setHomeModalType } = useContext(HomeContext);
  const { modalType: articleModalType, setModalType: setArticleModalType } = useContext(HomeContext);

  // @effects

  useEscapeKeydown(() => {
    if (homeModalType === 'customTemplatePreparing') setHomeModalType(null);
    if (articleModalType === 'customTemplatePreparing') setArticleModalType(null);
  }, [homeModalType, articleModalType]);

  // @handlers

  const handleCloseClick = useCallback(() => {
    setArticleModalType(null);
    setHomeModalType(null);
  }, []);

  // @render

  return (
    <AppPopUp
      open={articleModalType === 'customTemplatePreparing' || homeModalType === 'customTemplatePreparing'}
      onClose={handleCloseClick}
    >
      <Container>
        <CloseIconContainer>
          <Close2SVG onClick={handleCloseClick} />
        </CloseIconContainer>

        <Content>
          <BodyMacbookSVG />
          <Title>{t('customTemplatePreparing.title')}</Title>
          <Description>{t('customTemplatePreparing.description')}</Description>

          <Button text={t('gotIt')} onClick={handleCloseClick} />
        </Content>
      </Container>
    </AppPopUp>
  );
});

const Container = styled.div`
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Content = styled.div`
  max-width: 654px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.p`
  margin-top: 24px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: ${({ theme }) => theme.colors.dark[100]};
`;

const Description = styled(Title)`
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

const Button = styled(AppButton)`
  width: 100%;
  margin-top: 60px;
`;
