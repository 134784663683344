import { ReactNode, memo } from 'react';
import styled from 'styled-components';
import AppButton from 'features/common/AppButton';

export const CustomTemplateInformationStep = memo<{
  title: ReactNode;
  children: ReactNode;
  onClickCTAButton: () => void;
  ctaButtonText: ReactNode;
  blockButton?: boolean;
  blurButton?: boolean;
}>(({ title, children, onClickCTAButton, ctaButtonText, blockButton, blurButton }) => {
  return (
    <ContentContainer>
      <InnerContent>
        <Title>{title}</Title>

        {children}
      </InnerContent>

      <ButtonContainer blur={blurButton}>
        <Button text={ctaButtonText} onClick={onClickCTAButton} block={blockButton} />
      </ButtonContainer>
    </ContentContainer>
  );
});

const ContentContainer = styled.div`
  position: relative;
  flex: 1;
  align-items: flex-start;
  max-width: 700px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
`;

const InnerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Title = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary[30]};
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div<{ blur?: boolean }>`
  ${({ blur }) => (blur ? 'position: absolute; bottom: 0; backdrop-filter: blur(7px);' : '')};
  width: 100%;
  padding: 30px 0px;
  background-color: rgba(255 255 255, 0.15);
`;

const Button = styled(AppButton)<{ block?: boolean }>`
  margin: 0px auto;
  padding: 15px 20px;
  ${({ block }) => (block ? 'width: 100%;' : '')};
`;
