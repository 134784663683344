import CheckboxPreLine from 'assets/img/icons/CheckboxPreLine.svg';
import CheckmarkIcon from 'assets/img/icons/Checkmark.svg';
import { memo } from 'react';
import styled from 'styled-components';

export const AppCheckbox = memo<{
  value: boolean;
  onChange: (newValue: boolean) => void;
  text: string;
  className?: string;
  preLine?: boolean;
}>(({ value, onChange, text, className, preLine }) => {
  return (
    <div className={className}>
      {preLine && <img src={CheckboxPreLine} alt="checkbox-pre-line" />}

      <Container>
        <span>{text}</span>

        <input type="checkbox" checked={value} onChange={() => onChange(!value)} />

        <Checkmark>
          <CheckmarkIconImg src={CheckmarkIcon} alt="checkmark" />
        </Checkmark>
      </Container>
    </div>
  );
});

const Checkmark = styled.div`
  position: absolute;
  top: 0px;
  left: 6px;
  height: 16px;
  width: 16px;
  border: 2px solid #cacddb;
  border-radius: 3px;
`;

export const CheckmarkIconImg = styled.img`
  display: none;
`;

export const Container = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  padding-left: 34px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ ${Checkmark} {
    background-color: #3b0a6c;
    border-width: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    & > ${CheckmarkIconImg} {
      display: block;
    }
  }
`;
