import { apiService } from '../service/service';
import { Bookmark } from '../utils/types';
import { DeleteAction, MergeAction, deleteEntities, mergeEntities } from './entity';
import { Action, Thunk } from './store';

type State = {};

export const initialState: State = {};

type ActionType = MergeAction | DeleteAction | Action<'me/logout'>;

export const bookmarkReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'me/logout':
      return initialState;

    default:
      return state;
  }
};

// @actions

export const fetchBookmarks =
  (articleId: string): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .fetchBookmarks(articleId)
      .then((bookmarks) => dispatch(mergeEntities({ bookmark: bookmarks })))
      .observe('fetch_bookmarks', dispatch)
      .catchError(dispatch);

export const createBookmark =
  (bookmark: Omit<Bookmark, 'created_at'>): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .createBookmark(bookmark)
      .first(() => dispatch(mergeEntities({ bookmark: [{ ...bookmark, created_at: Date.now() }] })))
      .then((item) => dispatch(mergeEntities({ bookmark: [item] })))
      .observe('create_bookmark', dispatch)
      .catchError(dispatch);

export const deleteBookmark =
  (articleId: string, bookmarkId: string): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .deleteBookmark(articleId, bookmarkId)
      .first(() => dispatch(deleteEntities({ bookmark: [bookmarkId] })))
      .then(() => console.info('delete bookmark success'))
      .observe('delete_bookmark' + bookmarkId, dispatch)
      .catchError(dispatch);
