import { Spinner } from 'features/common/Spinner';
import styled from 'styled-components';

export const CommonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  border: none;

  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;

export const LinkButton = styled(CommonContainer)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary[40]};

  &:hover {
    color: ${({ theme }) => theme.colors.primary[30]};
  }
`;

export const NormalButton = styled(CommonContainer)`
  padding: 13px 16px;
  border-radius: 4px;

  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray[20] : theme.colors.primary[40])};
  &:hover {
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray[20] : theme.colors.primary[30])};
  }

  color: ${({ theme, disabled }) => (disabled ? '#CDD1DE' : theme.colors.white)};
`;

type StyledSpinnerProps = { isThereText: boolean };
export const StyledSpinner = styled(Spinner)<StyledSpinnerProps>`
  margin-right: ${({ isThereText }) => (isThereText ? 8 : 0)}px;
`;
