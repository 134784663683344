import { ReactComponent as NovusMiniSvg } from 'assets/img/NovusMini.svg';
import AppPopUp from 'features/common/AppPopUp';
import { Col, FlexCol, Label, MainButton, centered } from 'features/common/Styles';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from 'redux/me';
import styled from 'styled-components';
import { HomeContext } from 'utils/contexts/HomeContext';
import { useEnterKeydown, useEscapeKeydown } from 'utils/hooks/useKeydown';

export const LogoutModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('home');
  const { replace } = useHistory();

  const { selectedArticleId, modalType, setModalType } = useContext(HomeContext);

  // @effects

  useEscapeKeydown(() => {
    if (modalType === 'logout') setModalType(null);
  }, [modalType]);

  useEnterKeydown(() => {
    if (modalType === 'logout') handleLogoutPress();
  }, [selectedArticleId, modalType]);

  // @handlers

  const handleLogoutPress = useCallback(() => {
    dispatch(logout());
    replace('/login');
  }, []);

  // @render

  return (
    <AppPopUp open={modalType === 'logout'} onClose={() => setModalType(null)}>
      <Container style={centered}>
        <FlexCol style={{ ...centered, borderRadius: 8 }}>
          <NovusMiniSvg height={30} width={30} />
          <Title style={{ marginTop: 16 }}>{t('signOut')} </Title>
          <InformationText>{t('signOutDescription')}</InformationText>
        </FlexCol>

        <BottomContainer>
          <MainButton text={t('signOutTitle')} loading={false} onClick={handleLogoutPress} />
        </BottomContainer>
      </Container>
    </AppPopUp>
  );
};

const Container = styled(Col)`
  width: 420px;
  height: 228px;
  background: #ffffff;
  border-radius: 8px;
  padding-top: 6px;
  background-color: ${(props) => props.theme.colors.white};
`;

const BottomContainer = styled.div`
  width: 100%;
  padding: 0px 20px 16px 20px;
`;

const Title = styled(Label)`
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  margin-bottom: 10px;
  text-align: center;
`;

const InformationText = styled(Label)`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  padding: 0px 24px;
`;
