import EyeIcon from 'assets/img/icons/Eye.svg';
import EyeSlashIcon from 'assets/img/icons/EyeSlash.svg';
import TrashIcon from 'assets/img/icons/Trash.svg';
import AppButton from 'features/common/AppButton';
import AppModal from 'features/common/AppModal';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteBookmark } from 'redux/bookmark';
import { getEntities, getEntity } from 'redux/entity';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { useEscapeKeydown } from 'utils/hooks/useKeydown';
import { useQuery } from 'utils/hooks/useQuery';
import { useSelect } from 'utils/hooks/useSelect';
import { Bookmark } from 'utils/types';

export const ArticleGenerationBookmarkModal = memo<{}>(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['article', 'common']);
  const { articleId, modalType, setModalType } = useContext(ArticleContext);

  const { isLoading } = useQuery('fetch_bookmarks');
  const article = useSelect(({ entity }) => getEntity(entity, 'article', articleId));
  const bookmarks = useSelect(({ entity }) =>
    getEntities(entity, 'bookmark', ({ article_id }) => article_id === articleId)
  );

  const [expandedBookmarks, setExpandedBookmarks] = useState<string[]>([]);

  // @effects

  useEffect(() => {
    if (!bookmarks.length) setModalType(null);
  }, [bookmarks.length]);

  useEscapeKeydown(() => {
    if (modalType === 'bookmark') setModalType(null);
  }, [modalType]);

  // @handlers

  const handleCollapseClick = useCallback(
    (expanded: boolean, itemId: string) => {
      const newExpandedBookmarks = [...expandedBookmarks];
      if (expanded) {
        const indexOfBookmark = expandedBookmarks.indexOf(itemId);
        if (indexOfBookmark === -1) return;
        newExpandedBookmarks.splice(indexOfBookmark, 1);
        setExpandedBookmarks(newExpandedBookmarks);
      } else setExpandedBookmarks([...expandedBookmarks, itemId]);
    },
    [expandedBookmarks]
  );

  const handleDeleteClick = useCallback(
    (bookmarkId: string) => {
      dispatch(deleteBookmark(articleId, bookmarkId));
    },
    [article]
  );

  const renderTextBookmark = useCallback(
    (item: Bookmark) => {
      const expanded = expandedBookmarks.includes(item.id);

      return (
        <Wrapper key={item.id}>
          <TextBookmark collapse={!expanded}>{item.content}</TextBookmark>

          <ActionButtons>
            <BookmarkActionButton
              text={<img src={expanded ? EyeSlashIcon : EyeIcon} style={{ width: 16, height: 16 }} alt="eye" />}
              onClick={() => handleCollapseClick(expanded, item.id)}
            />
            <BookmarkActionButton
              text={<img src={TrashIcon} style={{ width: 16, height: 16 }} alt="trash" />}
              onClick={() => handleDeleteClick(item.id)}
            />
          </ActionButtons>
        </Wrapper>
      );
    },
    [handleCollapseClick, handleDeleteClick, expandedBookmarks]
  );

  const renderImageBookmark = useCallback(
    (item: Bookmark) => {
      return (
        <Wrapper key={item.id}>
          <ImageBookmark src={item.content} />

          <ActionButtons>
            <BookmarkActionButton
              text={isLoading ? '' : <img src={TrashIcon} style={{ width: 16, height: 16 }} alt="trash" />}
              onClick={() => handleDeleteClick(item.id)}
              loading={isLoading}
            />
          </ActionButtons>
        </Wrapper>
      );
    },
    [handleDeleteClick]
  );

  return (
    <AppModal visible={modalType === 'bookmark'} onClose={() => setModalType(null)}>
      <Container>
        <Title>
          {t('bookmarks')} ({bookmarks?.length ?? 0})
        </Title>

        {bookmarks?.map((item) => (item.type === 'image' ? renderImageBookmark(item) : renderTextBookmark(item)))}
      </Container>
    </AppModal>
  );
});

const Container = styled.div`
  width: 50vw;
  max-height: 80vh;
  padding: 24px 60px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;

  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.soft[80]};
  }
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
`;

const Wrapper = styled.div`
  padding: 12px 0px;
`;

const TextBookmark = styled.p<{ collapse: boolean }>`
  ${({ collapse }) =>
    collapse
      ? `text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;`
      : ''}

  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
`;

const ImageBookmark = styled.img`
  width: 200px;
  height: ${(200 * 317) / 428}px;
  border-radius: 4px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;

  & > button:not(:last-child) {
    margin-right: 12px;
  }
`;

const BookmarkActionButton = styled(AppButton)`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  padding: 8px;
  background-color: ${({ loading, theme }) => (loading ? theme.colors.dark[100] : theme.colors.soft[40])};
  &:hover {
    background-color: ${({ loading, theme }) => (loading ? theme.colors.dark[100] : theme.colors.soft[50])};
  }
`;
