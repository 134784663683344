import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { QueryKey, setQueryState } from 'redux/query';
import { useSelect } from 'utils/hooks/useSelect';

export const useQuery = (key: QueryKey) => {
  const dispatch = useDispatch();
  const state = useSelect(({ query: network }) => network.observers[key]);

  useEffect(() => {
    dispatch(setQueryState(key, null));

    return () => {
      dispatch(setQueryState(key, null));
    };
  }, []);

  return {
    isPending: state === null,
    isLoading: state === 'loading',
    isSuccess: state === 'success',
    isFailure: state === 'failure'
  };
};
