import GbFlag from 'assets/img/flags/gb.svg';
import TrFlag from 'assets/img/flags/tr.svg';
import SelectboxDropdownHandle from 'features/common/SelectboxDropdownHandle';
import { useCallback, useState } from 'react';
import Select from 'react-dropdown-select';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { LanguageOption } from 'utils/types';

type LanguageSelectOption = {
  label: string;
  value: LanguageOption;
  flag: string;
};

const LANGUAGE_OPTIONS: LanguageSelectOption[] = [
  { label: 'English', value: 'en', flag: GbFlag },
  { label: 'Turkish', value: 'tr', flag: TrFlag }
];

export const LanguageSelect: React.FC<{ className?: string }> = ({ className }) => {
  const { i18n } = useTranslation();
  const theme = useTheme();

  const defaultValue = LANGUAGE_OPTIONS.find((item) => item.value === localStorage.getItem('novusLng'));
  const [values, setValues] = useState<LanguageSelectOption[]>([defaultValue ?? LANGUAGE_OPTIONS[0]]);

  // @handler

  const handleLanguageChange = useCallback(
    (newValues: LanguageSelectOption[]) => {
      setValues(newValues);
      localStorage.setItem('novusLng', newValues[0].value);
      i18n.changeLanguage(newValues[0].value);
    },
    [setValues, i18n]
  );

  // @render

  const renderContent = useCallback(({ state }) => {
    return (
      <SelectedLanguageContent>
        <SelectedLanguageFlag src={state.values[0].flag} alt="lang-flag" />
        <SelectedLanguageLabel>{state.values[0].label}</SelectedLanguageLabel>
      </SelectedLanguageContent>
    );
  }, []);

  return (
    <div className={className}>
      <StyledSelect
        values={values}
        options={LANGUAGE_OPTIONS}
        onChange={handleLanguageChange}
        color={theme.colors.primary[40]}
        contentRenderer={renderContent}
        dropdownHandleRenderer={({ state }) => <SelectboxDropdownHandle isDropdownOpen={state.dropdown} />}
      />
    </div>
  );
};

const StyledSelect = styled(Select<LanguageSelectOption>)`
  min-width: 176px !important;
  border: none !important;
  border-radius: 4px !important;
  background-color: ${({ theme }) => theme.colors.soft[50]} !important;
  &:focus,
  &:focus-within {
    box-shadow: none !important;
  }
`;

const SelectedLanguageContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;
`;

const SelectedLanguageFlag = styled.img`
  width: 28px;
  height: 17px;
`;

const SelectedLanguageLabel = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};
  margin-left: 9px;
`;
