import { Action, Thunk } from 'redux/store';
import { apiService } from 'service/service';
import { User, UserPlan } from 'utils/types';
import { DeleteAction, MergeAction, ReplaceAction } from './entity';
import produce from 'immer';
import { auth } from '../Firebase';

type State = {
  user: User | null;
  userPlan: UserPlan | null;
};

export const initialState: State = {
  user: null,
  userPlan: null
};

type ActionType =
  | { type: 'user/setUser'; user: User | null }
  | { type: 'user/setUserPlan'; userPlan: UserPlan | null }
  | MergeAction
  | ReplaceAction
  | DeleteAction
  | Action<'me/logout'>;

export const meReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'user/setUser':
      return produce(state, (draft) => {
        draft.user = action.user;
      });

    case 'user/setUserPlan':
      return produce(state, (draft) => {
        draft.userPlan = action.userPlan;
      });

    case 'me/logout':
      return initialState;

    default:
      return state;
  }
};

// @actions

export const fetchUser = (): Thunk<ActionType> => (dispatch) =>
  apiService
    .fetchUser()
    .then(({ userData }) => dispatch({ type: 'user/setUser', user: userData.userCredentials }))
    .observe('fetch_user', dispatch)
    .catchError(dispatch);

export const fetchUserPlan = (): Thunk<ActionType> => (dispatch) =>
  apiService
    .fetchUserPlan()
    .then((userPlan) => dispatch({ type: 'user/setUserPlan', userPlan }))
    .observe('fetch_user_plan', dispatch)
    .catchError(dispatch);

export const logout = (): Thunk<ActionType> => (dispatch) => {
  auth.signOut();
  localStorage.removeItem('auth');
  dispatch({ type: 'me/logout' });
};
