import { RawDraftContentState } from 'draft-js';
import { pickBy } from 'lodash';
import {
  AIImageGeneration,
  AITemplate,
  AITextGeneration,
  Article,
  ArticleImageGenerationSettings,
  ArticleTextGenerationSettings,
  CustomTemplateFile,
  Bookmark,
  ContentKeys,
  Folder,
  LanguageOption,
  Plagiarism,
  Similarity,
  Tab,
  Template,
  TemplateMeta,
  User,
  UserPlan,
  TEMPLATE_TYPES,
  FormField
} from 'utils/types';
import { del, get, post, put } from './api';

export const apiService = {
  // User

  fetchUser: () => {
    return get<{ userData: { userCredentials: User } }>({ path: '/user' });
  },

  fetchUserPlan: () => {
    return get<UserPlan>({ path: '/payment/userPlan' });
  },

  // Utility

  fetchTrends: (location: string) => {
    return get<string[]>({ path: '/getGoogleTrends', params: { location } });
  },

  // Template

  fetchTemplates: () => {
    return get<{
      templates: Template[];
      userTemplateRecord: { id: string; template_data: TemplateMeta[] };
    }>({ path: '/templates' });
  },

  fetchImageTemplates: () => {
    return get<{
      templates: Template[];
      userTemplateRecord: { id: string; template_data: TemplateMeta[] };
    }>({ path: '/templatesDalle' });
  },

  fetchAITemplates: () => {
    return get<{ templates: AITemplate[] }>({ path: '/aiTemplates' });
  },

  // Folder

  createFolder: (name: string) => {
    return post<Folder>({ path: '/folder', params: { name } });
  },

  updateFolder: (id: string, name: string) => {
    return put<Folder>({ path: '/folder', params: { id, name } });
  },

  deleteFolder: (id: string) => {
    return del<void>({ path: `/folder/${id}` });
  },

  fetchFolders: () => {
    return get<Folder[]>({ path: '/folder' });
  },

  // Article

  fetchArticle: (articleId: string) => {
    return get<{ article: Article; folder: Folder; tabs: Tab[] }>({
      path: '/article/single',
      params: { article_id: articleId }
    });
  },

  fetchArticles: () => {
    return get<{ articles: Article[]; folder: Folder }[]>({ path: '/article/1' });
  },

  createArticle: (id: string, folderId: string, title: string, isDraft: boolean) => {
    return post<{ article: Article; folder: Folder; tab: Tab }>({
      path: '/article',
      params: { folderId, title, is_draft: isDraft, id }
    });
  },

  updateArticle: (id: string, payload: Partial<Article>) => {
    return put<{ article: Article }>({
      path: '/article',
      params: { id, ...payload }
    });
  },

  deleteArticle: (id: string) => {
    return del<void>({ path: '/article', params: { id } });
  },

  // Tab

  fetchTabs: (articleId: string) => {
    return get<Tab[]>({ path: '/tab', params: { article_id: articleId } });
  },

  createTab: (articleId: string, id: string, name: string, order: number) => {
    return post<Tab>({ path: '/tab', params: { id, article_id: articleId, name, order } });
  },

  deleteTab: (id: string) => {
    return del<void>({ path: '/tab', params: { id } });
  },

  updateTab: (articleId: string, id: string, name: string, order?: number) => {
    return put<Tab>({ path: '/tab', params: { id, article_id: articleId, name, order } });
  },

  // Generation

  fetchTextGenerations: (articleId: string) => {
    return get<AITextGeneration[]>({
      path: `/suggestion/${articleId}`,
      params: { article_id: articleId }
    });
  },

  fetchImageGenerations: (articleId: string) => {
    return get<AIImageGeneration[]>({
      path: `/pictures/${articleId}`,
      params: { article_id: articleId }
    });
  },

  updateGeneration: (generationId: string, generations: AITextGeneration['generations']) => {
    return put<AITextGeneration>({
      path: '/suggestion',
      params: { generations, generationId }
    });
  },

  createTextGeneration: (
    endpoint: string,
    settings: ArticleTextGenerationSettings,
    content: ContentKeys | string,
    maxTokens: number,
    articleId: string,
    tabId: string,
    allText?: string | string[],
    prompt?: string[]
  ) => {
    return post<AITextGeneration>({
      path: `/ml/${endpoint}`,
      params: pickBy(
        { ...settings, content, allText, prompt, max_tokens: maxTokens, articleID: articleId, tab_id: tabId },
        (item) => item !== undefined || item !== null
      )
    });
  },

  createImageGeneration: (
    endpoint: string,
    settings: ArticleImageGenerationSettings,
    content: ContentKeys | string,
    articleId: string,
    tabId: string
  ) => {
    return post<AIImageGeneration>({
      path: `/ml/dalle`,
      params: { ...settings, content, articleID: articleId, tab_id: tabId }
    });
  },

  regenerate: (
    language: LanguageOption,
    max_tokens: number,
    output_language: LanguageOption,
    all_text: string,
    regenerate_text: string,
    article_id: string,
    tab_id: string
  ) => {
    return post<AITextGeneration>({
      path: `/ml/regenerate`,
      params: { language, max_tokens, output_language, all_text, regenerate_text, article_id, tab_id }
    });
  },

  // Editor

  saveEditor: (articleId: string, editorState: RawDraftContentState) => {
    return post<RawDraftContentState>({ path: '/editor', params: { id: articleId, data: editorState } });
  },

  // Bookmark

  fetchBookmarks: (articleId: string) => {
    return get<Bookmark[]>({ path: '/bookmark', params: { article_id: articleId } });
  },

  createBookmark: (bookmark: Omit<Bookmark, 'created_at'>) => {
    return post<Bookmark>({ path: '/bookmark', params: bookmark });
  },

  deleteBookmark: (articleId: string, bookmarkId: string) => {
    return del<void>({ path: '/bookmark', params: { article_id: articleId, id: bookmarkId } });
  },

  // Plagiarism

  fetchPlagiarism: (text: string) => {
    return post<Plagiarism>({ path: '/getPlagiarism', params: { text } });
  },

  // Fact Check

  fetchFactCheck: (
    generation: AITextGeneration,
    generationIndex: number,
    returnSameLanguage: boolean,
    outputLanguage: string
  ) => {
    return post<{ generation: AITextGeneration }>({
      path: '/getFactCheck',
      params: {
        generation,
        generationIndex,
        fc_output_language: returnSameLanguage,
        output_language: outputLanguage
      }
    });
  },

  // ProofRead

  fetchProofRead: (articleId: string, author: string, query: string) => {
    return post<Similarity[]>({ path: '/elastic/search', params: { article_id: articleId, author, query } });
  },

  updateProofRead: (articleId: string, author: string, query: string) => {
    return put<void>({ path: '/elastic/search', params: { article_id: articleId, author, query } });
  },

  // Custom Template

  createCustomTemplate: (payload: {
    file_ids: string[];
    name: string;
    description: string;
    output_example: string;
    template_type: TEMPLATE_TYPES;
    form: Array<FormField>;
    [key: string]: any;
  }) => {
    return post<void>({ path: '/api/automl', params: payload });
  },

  fetchCustomTemplateFiles: (type: 'text') => {
    return get<CustomTemplateFile[]>({ path: `/api/automl/file/${type}` });
  },

  createCustomTemplateFile: (
    url: string,
    thumbnail_url: string,
    name: string,
    type: string,
    file_type: string,
    size: number
  ) => {
    return post<CustomTemplateFile>({
      path: '/api/automl/file',
      params: { url, thumbnail_url, name, type, file_type, size }
    });
  },

  fetchCustomTemplateFileText: (url: string) => {
    return post<{ text: string }>({ path: '/getFileText', params: { url } });
  },

  deleteCustomTemplateFiles: (fileIds: string[]) => {
    return post<void>({ path: '/api/automl/file/delete', params: { file_ids: fileIds } });
  },

  // Admin

  fetchAdmin: () => {
    return get<{ isAdmin: boolean }>({ path: '/admin/check', isAdmin: true });
  },

  fetchAdminUsers: () => {
    return get<{ users: User[]; userPlans: UserPlan[] }>({ path: '/admin/users', isAdmin: true });
  },

  upgradeUserPlan: (userId: string, up: boolean) => {
    return post<UserPlan>({ path: '/admin/upgrade', params: { user_id: userId, up }, isAdmin: true });
  }
};
