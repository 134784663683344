import { useCallback, useRef } from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
// @ts-ignore:next-line
import TuiImageEditor from '@toast-ui/react-image-editor';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/img/icons/Close.svg';
import AppButton from 'features/common/AppButton';
import styled from 'styled-components';
import AppModal from 'features/common/AppModal';

const editorTheme = {
  'common.bi.image': 'novusMini.svg',
  'common.bisize.height': '70px',
  'common.bisize.width': 'auto',
  'downloadButton.display': 'none'
};

const ImageEditor = ({
  path,
  open,
  setDrawImage
}: {
  path: string | ArrayBuffer | null;
  open: boolean;
  setDrawImage: (payload: {
    open: boolean;
    previewImage: string | ArrayBuffer | null;
    fileImage: File | Blob | null;
  }) => void;
}) => {
  const { t } = useTranslation('article');
  const instance = useRef<any>(null);

  function dataURLtoBlob(dataurl: any) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const save = useCallback(async () => {
    setDrawImage({
      open: false,
      previewImage: instance.current?.imageEditorInst.toDataURL(),
      fileImage: dataURLtoBlob(instance.current?.imageEditorInst.toDataURL())
    });
  }, [setDrawImage]);

  const closeHandler = useCallback(() => {
    setDrawImage({ open: false, previewImage: null, fileImage: null });
  }, [setDrawImage]);

  return (
    <AppModal visible={open} onClose={closeHandler}>
      <ImageEditorContainer>
        <ImageEditorHeader>
          <CloseButton text={<CloseIcon color="white" />} onClick={closeHandler} />
          <SaveButton text={t('save')} onClick={save} />
        </ImageEditorHeader>
        <ImageEditorBody>
          <TuiImageEditor
            ref={instance}
            includeUI={{
              loadImage: { path: path, name: 'NovusMini' },
              theme: editorTheme,
              menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'text', 'filter'],
              initMenu: 'draw',
              uiSize: { width: window.innerWidth, height: window.innerHeight },
              menuBarPosition: 'right'
            }}
            cssMaxHeight={500}
            cssMaxWidth={700}
            selectionStyle={{ cornerSize: 20, rotatingPointOffset: 70 }}
            usageStatistics={true}
          />
        </ImageEditorBody>
      </ImageEditorContainer>
    </AppModal>
  );
};

const ImageEditorContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: red;
  display: flex;
  flex-direction: column;
  z-index: 10000;
`;

const ImageEditorHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;

  padding-top: 10px;
  padding-bottom: 10px;

  background: ${({ theme }) => theme.colors.purple[40]};
`;

const ImageEditorBody = styled.div`
  flex: 12;
`;

const SaveButton = styled(AppButton)`
  margin-right: 65px;
  width: 300px;
`;

const CloseButton = styled(AppButton)`
  margin-left: 15px;
  width: 75px;
`;

export default ImageEditor;
