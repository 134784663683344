import { FC, useCallback } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';

const AppPopUp: FC<{
  className?: string;
  open?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  closeWhenOverlayClicked?: boolean;
}> = ({ className, open = false, children, onClose = () => {} }) => {
  const openAnimation = useSpring({
    from: { opacity: 0, marginBottom: '-20px' },
    to: { opacity: open ? 1 : 0, marginBottom: open ? '0px' : '-20px' },
    config: { duration: 200 }
  });

  // @handlers

  const handleBackgroundClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onClose();
    },
    [onClose]
  );

  // @render

  if (!open) return null;

  return (
    <Container id="outContainer" onClick={handleBackgroundClick}>
      <span className={className} onClick={(event) => event.stopPropagation()}>
        <animated.div style={openAnimation}>{children}</animated.div>
      </span>
    </Container>
  );
};

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 999;
`;

export default AppPopUp;
