import { ReactComponent as ArrowLeft } from 'assets/img/icons/ArrowLeft.svg';
import { Spinner } from 'features/common/Spinner';
import { memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AppButton from 'features/common/AppButton';
import { AppInput } from 'features/common/AppInput';
import styled from 'styled-components';
import { useForgotPasswordMutation } from 'redux/auth/api';

export const ForgotPassword = memo(() => {
  const history = useHistory();
  const { t } = useTranslation('welcome');
  const [forgotPasswordApi, { isLoading, isError, isSuccess }] = useForgotPasswordMutation();

  const [email, setEmail] = useState('');

  // @effects

  useEffect(() => {
    if (isSuccess) toast.success(t('successForgotPassword'));
  }, [isSuccess, t]);

  useEffect(() => {
    if (isError) toast.success(t('errorForgotPassword'));
  }, [isError, t]);

  // @handlers

  const handleSubmit = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();
      if (email) forgotPasswordApi(email);
      else toast.warning(t('emptyForgotPassword'));
    },
    [email, t, forgotPasswordApi]
  );

  const handleGoBackPress = useCallback(() => {
    history.replace('/login');
  }, [history]);

  // @render

  return (
    <Container>
      <Title>{t('forgotPassword')}</Title>

      <form onSubmit={handleSubmit}>
        <Form>
          <Input name="email" placeholder={t('email')} onChange={setEmail} value={email} />
          <Submit
            text={t('submit')}
            disabled={isLoading}
            loading={isLoading}
            customSpinner={<Spinner />}
            onClick={handleSubmit}
          />
        </Form>
      </form>

      <GoBack onClick={handleGoBackPress} text={<ArrowLeft />} />
    </Container>
  );
});

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 40px;
  text-align: center;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Input = styled(AppInput)`
  border: none;
  outline: none;
  border-bottom: 1px solid #000;
  border-radius: 0;

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.purple[100]};
  }
`;

export const Submit = styled(AppButton)`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  color: ${({ theme }) => theme.colors.gray[15]};
  font-weight: 100px;

  margin: 10% 20% 2px;
  padding: 12px 50px;

  &:active {
    background-color: ${({ theme }) => theme.colors.purple[100]};
  }
`;

export const GoBack = styled(AppButton)`
  background-color: ${({ theme }) => theme.colors.gray[15]};
  width: 50px;
  height: 50px;
  color: ${({ theme }) => theme.colors.gray[100]};
  border-radius: 8px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10%;
  left: 5%;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
