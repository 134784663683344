import { ReactComponent as LeftArrow } from 'assets/img/icons/ArrowLeft.svg';
import { ReactComponent as RightArrow } from 'assets/img/icons/ArrowRight.svg';
import { Col, FlexRow, Label } from 'features/common/Styles';
import { orderBy } from 'lodash';
import { Fragment, memo, useCallback, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { getEntities } from 'redux/entity';
import styled, { useTheme } from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { Template } from 'utils/types';
import { retrieveTemplateIcon, retrieveTemplateName } from '../../utils/helpers/articleHelper';

export const HomeQuickActions = memo<{
  onCreateArticle: (template?: Template) => void;
}>(({ onCreateArticle }) => {
  const { t } = useTranslation(['home']);

  const templateMeta = useSelect(({ entity }) => getEntities(entity, 'templateMeta'));
  const rawTemplates = useSelect(({ entity }) => getEntities(entity, 'template'));

  const templates = useMemo(() => {
    const templatesWithUsage = rawTemplates.map((template) => {
      const templateData = templateMeta.find((data) => data.template_id === template.id);
      const usage_count = templateData ? -templateData.usage_count : Number.MAX_SAFE_INTEGER;
      return { ...template, usage_count };
    });
    return orderBy(templatesWithUsage, ['usage_count', 'order'], ['asc']);
  }, [templateMeta, rawTemplates]);

  // @handlers

  const handlePagination = useCallback(
    (type: 'prev' | 'next') => () => {
      const element = document.querySelector('.quick-actions');
      const scrollWidth = Math.floor(element?.clientWidth! / 210) * 210;
      const offset = type === 'prev' ? -scrollWidth : scrollWidth;
      element?.scrollBy({ left: offset, behavior: 'smooth' });
    },
    []
  );

  const handleCreateProject = useCallback(
    (template?: Template) => () => {
      onCreateArticle(template);
    },
    [onCreateArticle]
  );

  // @render

  return (
    <Col style={{ alignItems: 'flex-start', width: '100%' }}>
      <Title>{t('quickActions')}</Title>

      <FlexRow style={{ width: '100%' }}>
        <PaginationButtons onClick={handlePagination('prev')}>
          <LeftArrow width={20} height={20} />
        </PaginationButtons>

        <ListContainer className="quick-actions">
          {templates.length ? (
            <Fragment>
              <ListItem onClick={handleCreateProject()}>
                <Label style={{ fontSize: 32, marginBottom: 16 }}>📎</Label>
                <ListItemTitle>{t('createNewProject')}</ListItemTitle>
              </ListItem>

              {templates.map((template, index) => (
                <ListItem key={index} onClick={handleCreateProject(template)}>
                  <Label style={{ fontSize: 32, marginBottom: retrieveTemplateIcon(template) ? 16 : 0 }}>
                    {retrieveTemplateIcon(template)}
                  </Label>
                  <ListItemTitle>{retrieveTemplateName(template, t)}</ListItemTitle>
                </ListItem>
              ))}
            </Fragment>
          ) : null}

          {!templates.length && <Skeleton count={10} />}
        </ListContainer>

        <PaginationButtons onClick={handlePagination('next')}>
          <RightArrow width={20} height={20} />
        </PaginationButtons>
      </FlexRow>
    </Col>
  );
});

export const Skeleton = memo<{ count: number }>(({ count = 1 }) => {
  const theme = useTheme();

  return (
    <ContentLoader width={'100%'} height={'135px'} backgroundColor={theme.colors.soft[70]}>
      {Array.from({ length: count }, (_, i) => i).map((skeletonNum) => (
        <Fragment key={`quick-actions-skeleton-${skeletonNum}`}>
          <rect x={220 * skeletonNum} y={0} rx="4" ry="4" width="200px" height="135px" />
        </Fragment>
      ))}
    </ContentLoader>
  );
});

const Title = styled(Label)`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  padding-bottom: 10px;
`;

const ListContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  overflow-x: scroll;
  padding-bottom: 20px;
  transition: width 0.5s ease-in-out;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  height: 135px;
  padding: 10px;
  margin-right: 20px;
  border-radius: 8px;
  background: linear-gradient(30deg, #561a57 20%, #4745b5 80%);
  border: 1px solid ${({ theme }) => theme.colors.purple[120]};
  cursor: pointer;
`;

const ListItemTitle = styled(Label)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

const PaginationButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray[50]};
  margin-right: 10px;
  height: 135px;

  &:hover {
    color: ${({ theme }) => theme.colors.gray[100]};
  }
`;
