import CloseIcon from 'assets/img/icons/Close.svg';
import PlusDarkIcon from 'assets/img/icons/PlusDark.svg';
import AppButton from 'features/common/AppButton';
import { last, map } from 'lodash';
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSelectedTabId } from 'redux/editor';
import { getEntities, getEntity } from 'redux/entity';
import { createTab, deleteTab, updateTab } from 'redux/tab';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { useSelect } from 'utils/hooks/useSelect';
import { v4 } from 'uuid';

const MAX_TAB_COUNT = 4;
const MIN_TAB_COUNT = 1;

export const ArticleGenerationTabList = memo(() => {
  const { t } = useTranslation(['article', 'common']);
  const dispatch = useDispatch();
  const { articleId, setModalType } = useContext(ArticleContext);

  const article = useSelect(({ entity }) => getEntity(entity, 'article', articleId));
  const tabs = useSelect(({ entity }) => getEntities(entity, 'tab', ({ article_id }) => article_id === articleId));
  const selectedTabId = useSelect(({ editor }) => editor.selectedTabId?.[articleId] ?? '');
  const bookmarks = useSelect(({ entity }) =>
    getEntities(entity, 'bookmark', ({ article_id }) => article_id === articleId)
  );

  const [isChangeTabName, setIsChangeTabName] = useState(false);
  const [newTabName, setNewTabName] = useState('');

  const namedTabs = useMemo(() => {
    return tabs.map((tab, index) => ({
      ...tab,
      name: tab.has_custom_name ? tab.name : `Tab ${index + 1}`
    }));
  }, [tabs]);

  // @effects

  useEffect(() => {
    if (!map(tabs, 'id').includes(selectedTabId) && tabs.length > 0) {
      dispatch(setSelectedTabId(articleId, last(tabs)?.id ?? ''));
    }
  }, [tabs.length, selectedTabId]);

  // @handlers

  const handleBookmarksClick = useCallback(() => {
    if (bookmarks.length) setModalType('bookmark');
  }, [bookmarks.length]);

  const handleCreateTabClick = useCallback(() => {
    const name = `Tab ${tabs.length + 1}`;
    const tabId = v4();
    dispatch(createTab(articleId, tabId, name, tabs.length + 1));
    dispatch(setSelectedTabId(articleId, tabId));
  }, [article, tabs.length]);

  const handleDeleteTabClick = useCallback(
    (tabId: string) => () => {
      dispatch(deleteTab(tabId));
    },
    [tabs]
  );

  const handleCurrentTabChange = useCallback(
    (tabId: string) => () => {
      dispatch(setSelectedTabId(articleId, tabId));
    },
    [articleId]
  );

  const handleIsChangeTabName = useCallback(
    (state: boolean, tabName = '') =>
      () => {
        setNewTabName(tabName);
        setIsChangeTabName(state);
      },
    [setIsChangeTabName]
  );

  const handleTabNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewTabName(e.target.value);
    },
    [setNewTabName]
  );

  const handleTabNameChangeDone = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event?.key !== 'Enter') return;
      dispatch(updateTab(articleId, selectedTabId, newTabName));
      setNewTabName('');
      setIsChangeTabName(false);
    },
    [newTabName, selectedTabId, articleId, selectedTabId]
  );

  // @render

  return (
    <Container>
      <Backdrop onClick={handleIsChangeTabName(false)} open={isChangeTabName} />

      <Tabs>
        {namedTabs.map((tab) => (
          <TabItem key={tab.id} isActive={tab.id === selectedTabId} onClick={handleCurrentTabChange(tab.id)}>
            <TabText onClick={tab.id === selectedTabId ? handleIsChangeTabName(true, tab.name) : undefined}>
              {isChangeTabName && tab.id === selectedTabId ? (
                <TabNameInput onChange={handleTabNameChange} value={newTabName} onKeyPress={handleTabNameChangeDone} />
              ) : (
                tab.name
              )}
            </TabText>

            {tabs.length !== MIN_TAB_COUNT && (
              <RemoveTabIcon src={CloseIcon} alt="remove-tab" onClick={handleDeleteTabClick(tab.id)} />
            )}
          </TabItem>
        ))}

        {tabs.length < MAX_TAB_COUNT && (
          <TabItem onClick={handleCreateTabClick}>
            <img src={PlusDarkIcon} alt="add-tab" style={{ width: 11, height: 11 }} />
          </TabItem>
        )}
      </Tabs>

      {bookmarks.length ? (
        <BookmarksButton text={`${t('bookmarks')} (${bookmarks?.length ?? 0})`} onClick={handleBookmarksClick} />
      ) : null}
    </Container>
  );
});

const Container = styled.div`
  height: 50px;
  padding-left: 20px;
  padding-right: 43px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[70]};
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
`;

const BookmarksButton = styled(AppButton)`
  height: 40px;
  padding: 12px 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray[70]};
  color: ${({ theme }) => theme.colors.dark[100]};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-right: -12px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[70]};
  }
`;

const Tabs = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  margin-right: 24px;
  z-index: 3;
  & > div:not(:last-child) {
    margin-right: 8px;
  }
`;

const TabItem = styled.div<{ isActive?: boolean }>`
  height: ${({ isActive }) => (isActive ? 40 : 28)}px;
  transform: translateY(1px);
  border: 1px solid ${({ theme }) => theme.colors.gray[70]};
  border-bottom: 1px solid ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.gray[70])};
  border-radius: 4px 4px 0px 0px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  cursor: ${({ isActive }) => (isActive ? 'auto' : 'pointer')};
`;

const TabText = styled.p`
  color: ${({ theme }) => theme.colors.dark[100]};
  font-weight: 700;
  font-size: 12px;
  max-width: 160px;
  line-height: 16px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  padding-left: 3px;
  overflow: hidden;
`;

const RemoveTabIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 6px;
  cursor: pointer;
`;

const TabNameInput = styled.input`
  width: 80px;
  height: 100%;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[70]};
  border-radius: 4px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.dark[100]};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding: 4px 4px 4px 0px;
  text-indent: 10px;
`;

const Backdrop = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;
