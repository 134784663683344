import api from 'redux/api';
import { User, UserPlan } from 'utils/types';

const UserApi = api.injectEndpoints({
  endpoints: (build) => ({
    resetPassword: build.mutation<unknown, { token: string; password: string; confirmPassword: string }>({
      query: (payload) => ({ url: `/resetPassword`, method: 'POST', body: payload }),
      transformResponse: (response: { data: unknown }) => response.data || null
    }),
    getUserPlan: build.query<UserPlan, void>({
      query: () => ({ url: `/payment/userPlan`, method: 'GET' }),
      transformResponse: (response: { data: UserPlan }) => response.data,
      providesTags: ['UserPlan']
    }),
    updateuser: build.mutation<unknown, Partial<User>>({
      query: (payload) => ({ url: `/user/`, method: 'POST', body: payload }),
      transformResponse: (response: { data: unknown }) => response.data
    }),
    updatePassword: build.mutation<unknown, { oldPassword: string; newPassword: string }>({
      query: (payload) => ({ url: `/updatePassword`, method: 'POST', body: payload }),
      transformResponse: (response: { data: unknown }) => response.data
    })
  }),
  overrideExisting: false
});

export const { useResetPasswordMutation, useGetUserPlanQuery, useUpdateuserMutation, useUpdatePasswordMutation } =
  UserApi;
export default UserApi;
