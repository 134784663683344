import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { login, logoutHandle } from 'redux/auth/slice';
import { useSelect } from 'utils/hooks/useSelect';
import { Authorization } from 'utils/types';
import { WelcomePageLayout } from './common/WelcomePageLayout';

export const VerifySignUp = memo(() => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation('verifySignup');
  const user = useSelect(({ me }) => me.user);

  // @effects

  useEffect(() => {
    user && dispatch(logoutHandle({ userId: user.id, email: user.email }));
  }, [dispatch, user]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const customToken = queryParams.get('idToken');

    const verifyIdToken = async () => {
      try {
        if (!customToken) throw new Error();

        const credential = await signInWithCustomToken(getAuth(), customToken);
        if (!user) throw new Error('User not found');

        const token = await credential.user.getIdToken();
        const authorization: Authorization = { token, userID: credential.user.uid };
        localStorage.setItem('auth', JSON.stringify(authorization));

        dispatch(login(authorization));
      } catch (error) {
        history.push('/login');
      }

      history.push('/');
    };

    verifyIdToken();
  }, [location, dispatch, history]);

  // @render

  return <WelcomePageLayout>{t('verifying')}</WelcomePageLayout>;
});
