import React, { memo } from 'react';
import { FlexCol, centered } from './common/Styles';
import { Label } from './common/AppSelectbox/styles';

export const NotFound = memo(() => {
  return (
    <FlexCol style={{ ...centered, height: '100vh' }}>
      <Label style={{ fontSize: 44 }}>404 Not Found</Label>
    </FlexCol>
  );
});
