import { auth } from '../Firebase';
import { Authorization } from '../utils/types';
import { logRequest, logResponse } from './logger';

export type Method = 'POST' | 'GET' | 'PUT' | 'DELETE';
export type ParameterEncoding = 'body' | 'query';

export type ApiTarget = {
  method: Method;
  path: string;
  params?: Record<string, any>;
  encoding?: ParameterEncoding;
  retryCount?: number;
  isAdmin?: boolean;
};

type Success<T> = {
  data: T;
  retCode: number;
  success: true;
};
type Failure = {
  error: Error;
  retCode: number;
  success: false;
};

export type Result<T> = Success<T> | Failure;

const sendRequest = async <T>(target: ApiTarget, token: string, reAuthenticate: boolean = true): Promise<Result<T>> => {
  logRequest(target);
  const url = process.env.REACT_APP_BACKEND_URL + target.path;

  const headers: Record<string, string> = {};
  headers['Content-Type'] = 'application/json';
  headers['Access-Control-Allow-Origin'] = '*';

  if (target.isAdmin) headers['admin_key'] = process.env.REACT_APP_ADMIN_KEY ?? '';

  if (token) headers['Authorization'] = `Bearer ${token}`;
  const encoding = target.encoding ?? ['GET', 'DELETE'].includes(target.method) ? 'query' : 'body';

  try {
    const response = await (encoding === 'query'
      ? fetch(url + (target.params ? '?' : '') + new URLSearchParams(target.params), { method: target.method, headers })
      : fetch(url, { method: target.method, headers, body: JSON.stringify(target.params) }));

    if (response.status === 401 && reAuthenticate) {
      reAuthenticate = false;
      const currentUser = auth.currentUser;
      if (!currentUser) throw Error('Current firebase user is null');

      const idToken = await currentUser?.getIdToken();
      const authorization: Authorization = { token: idToken, userID: currentUser.uid };
      localStorage.setItem('auth', JSON.stringify(authorization));
      return sendRequest(target, idToken, false);
    } else if (response.status >= 400) {
      const errorMessage = await response.json();
      throw new Error(JSON.stringify(errorMessage));
    }

    const result: any = await response.json();
    logResponse(target, result, response.status);
    return result as Result<T>;
  } catch (error: any) {
    return { success: false, retCode: -1, error };
  }
};

const request = async <T>(target: ApiTarget): Promise<T> => {
  const retryCount = target.retryCount ?? 2;
  const iterator = Array(retryCount).map((_, index) => index);

  for await (const currentIteration of iterator) {
    if (currentIteration > 1) console.info(`Retrying request ${currentIteration} of ${retryCount}`);
    const storedAuthorization = localStorage.getItem('auth');

    let authorization: Authorization | null = null;
    if (storedAuthorization) {
      authorization = JSON.parse(storedAuthorization);
    } else {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        window.open(process.env.REACT_APP_APP_URL + '/login', '_self');
        throw Error('Current firebase user is null');
      }

      const idToken = await currentUser?.getIdToken();
      authorization = { token: idToken, userID: currentUser.uid };
      localStorage.setItem('auth', JSON.stringify(authorization));
    }

    const result = await sendRequest<T>(target, authorization!.token);
    if (result.success) return result.data as T;
    else throw new Error(result.error.message);
  }

  throw new Error("Something went wrong. We're working on it!");
};

export const get = async <T>(target: Omit<ApiTarget, 'method'>): Promise<T> => request({ method: 'GET', ...target });

export const post = async <T>(target: Omit<ApiTarget, 'method'>): Promise<T> => request({ method: 'POST', ...target });

export const put = async <T>(target: Omit<ApiTarget, 'method'>): Promise<T> => request({ method: 'PUT', ...target });

export const del = async <T>(target: Omit<ApiTarget, 'method'>): Promise<T> => request({ method: 'DELETE', ...target });
