import { Action, Thunk } from 'redux/store';
import { apiService } from '../service/service';
import { DeleteAction, MergeAction, deleteEntities, getEntity, mergeEntities } from './entity';

type State = {};

export const initialState: State = {};

type ActionType = MergeAction | DeleteAction | Action<'me/logout'>;

export const tabReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'me/logout':
      return initialState;

    default:
      return state;
  }
};

// @actions

export const createTab =
  (articleId: string, id: string, name: string, order: number): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .createTab(articleId, id, name, order)
      .first(() => dispatch(mergeEntities({ tab: [{ id, name, order, article_id: articleId }] })))
      .then((tab) => dispatch(mergeEntities({ tab: [tab] })))
      .observe('create_tab', dispatch)
      .catch(() => dispatch(deleteEntities({ tab: [id] })));

export const updateTab =
  (articleId: string, id: string, name: string, order?: number): Thunk<ActionType> =>
  (dispatch, getState) =>
    apiService
      .updateTab(articleId, id, name, order)
      .first(() => {
        const tab = getEntity(getState().entity, 'tab', id);
        if (tab) {
          const hasCustomName = name ? true : tab.has_custom_name;
          dispatch(mergeEntities({ tab: [{ ...tab, name, has_custom_name: hasCustomName }] }));
        }
      })
      .then((tab) => dispatch(mergeEntities({ tab: [tab] })))
      .observe('update_tab', dispatch)
      .catchError(dispatch);

export const deleteTab =
  (id: string): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .deleteTab(id)
      .first(() => dispatch(deleteEntities({ tab: [id] })))
      .observe('delete_tab', dispatch)
      .catchError(dispatch);
