import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import BoldIcon from 'assets/img/icons/Bold.svg';
import BulletListIcon from 'assets/img/icons/BulletList.svg';
import H1Icon from 'assets/img/icons/H1.svg';
import H2Icon from 'assets/img/icons/H2.svg';
import H3Icon from 'assets/img/icons/H3.svg';
import ItalicIcon from 'assets/img/icons/Italic.svg';
import NumberedListIcon from 'assets/img/icons/NumberedList.svg';
import { ReactComponent as People } from 'assets/img/icons/People.svg';
import UnderlineIcon from 'assets/img/icons/Underline.svg';
import { convertToRaw, EditorState, RawDraftContentState, RichUtils } from 'draft-js';
import AppButton from 'features/common/AppButton';
import SpinnerIcon from 'features/common/Icons/SpinnerIcon';
import { Col, Row } from 'features/common/Styles';
import { memo, MouseEvent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchPlagiarism } from 'redux/article';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { draftEditorToPdfDownload } from 'utils/helpers/articleHelper';
import { useQuery } from 'utils/hooks/useQuery';
import { useSelect } from 'utils/hooks/useSelect';

export const ArticleEditorActions = memo<{
  editorState: EditorState;
  onSaveClick: () => void;
  onProofReadClick: () => void;
  onEditorStateChange: (editorState: EditorState) => void;
}>(({ editorState, onEditorStateChange, onSaveClick, onProofReadClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('article');
  const { articleId, setModalType, setEditorText } = useContext(ArticleContext);

  const { isLoading: isSaveLoading } = useQuery('save_editor_state');
  const { isLoading: isProofReadLoading } = useQuery('fetch_proof_read');
  const { isLoading: isPlagiarismLoading } = useQuery('fetch_plagiarism');

  const similarities = useSelect(({ article }) => article.similarities[articleId] ?? []);

  // @handlers

  const handleInlineStyleButtonClick = useCallback(
    (inlineStyle: string) => () => {
      onEditorStateChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    },
    [editorState]
  );

  const handleBlockTypeButtonClick = useCallback(
    (blockType: string) => () => {
      onEditorStateChange(RichUtils.toggleBlockType(editorState, blockType));
    },
    [editorState]
  );

  const handleDownloadButtonPress = useCallback(
    (editor: RawDraftContentState) => () => {
      const docType = 'pdf';
      draftEditorToPdfDownload(editor, docType);
    },
    []
  );

  const handleViewProofreadPress = useCallback(() => {
    setEditorText(editorState.getCurrentContent().getPlainText());
    setModalType('proofRead');
  }, [editorState]);

  const handlePlagiarismCheck = useCallback(
    (event: MouseEvent) => {
      event.nativeEvent.preventDefault();
      const text = editorState.getCurrentContent().getPlainText();
      if (text) dispatch(fetchPlagiarism(articleId, text));
      else toast.error(t('common:contentNotEmpty'));
    },
    [editorState, articleId]
  );

  // @render

  return (
    <Col>
      <ActionContainer>
        <Row>
          <AdditionalEditorButton
            text={t('proofRead')}
            onClick={onProofReadClick}
            loading={isProofReadLoading}
            customSpinner={<SpinnerIcon />}
          />
          {!!similarities.length && (
            <AdditionalEditorButton
              text={
                <>
                  <People style={{ marginRight: 4 }} /> {t('view')}
                </>
              }
              onClick={handleViewProofreadPress}
            />
          )}

          <AdditionalEditorButton
            text={t('plagiarism')}
            onClick={handlePlagiarismCheck}
            customSpinner={<SpinnerIcon />}
            loading={isPlagiarismLoading}
          />
        </Row>
        <SaveButton text={t('save')} onClick={onSaveClick} loading={isSaveLoading} />
      </ActionContainer>

      <StyleContainer>
        <BottomRichButtonsContainer>
          <RichEditorButton text={<img src={BoldIcon} alt="bold" />} onClick={handleInlineStyleButtonClick('BOLD')} />
          <RichEditorButton
            text={<img src={ItalicIcon} alt="italic" />}
            onClick={handleInlineStyleButtonClick('ITALIC')}
          />
          <RichEditorButton
            text={<img src={UnderlineIcon} alt="underline" />}
            onClick={handleInlineStyleButtonClick('UNDERLINE')}
          />

          <RichEditorButtonDivider />

          <RichEditorButton text={<img src={H1Icon} alt="h1" />} onClick={handleInlineStyleButtonClick('header-one')} />
          <RichEditorButton text={<img src={H2Icon} alt="h2" />} onClick={handleInlineStyleButtonClick('header-two')} />
          <RichEditorButton
            text={<img src={H3Icon} alt="h3" />}
            onClick={handleInlineStyleButtonClick('header-three')}
          />

          <RichEditorButtonDivider />

          <RichEditorButton
            text={<img src={NumberedListIcon} alt="numberedlist" />}
            onClick={handleBlockTypeButtonClick('ordered-list-item')}
          />
          <RichEditorButton
            text={<img src={BulletListIcon} alt="bulletlist" />}
            onClick={handleBlockTypeButtonClick('unordered-list-item')}
          />
        </BottomRichButtonsContainer>

        <DownloadButton
          text={t('download')}
          onClick={handleDownloadButtonPress(convertToRaw(editorState.getCurrentContent()))}
        />
      </StyleContainer>
    </Col>
  );
});

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 9px 26px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.soft[10]};
  border-top: 1px solid ${({ theme }) => theme.colors.gray[40]};
`;

const AdditionalEditorButton = styled(AppButton)<{ adjoining?: boolean }>`
  background-color: ${({ theme }) => theme.colors.soft[40]};
  color: ${({ theme }) => theme.colors.dark[100]};
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-right: ${({ adjoining }) => (adjoining ? '4px' : '24px')};
  padding: 4px 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.soft[60]};
  }
`;

const StyleContainer = styled.div`
  padding: 28px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.soft[10]};
  border-top: 1px solid ${({ theme }) => theme.colors.gray[40]};

  & > button:not(:first-child) {
    margin-left: 4px;
  }
  & > button:not(:last-child) {
    margin-right: 4px;
  }
`;

const BottomRichButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  & > button:not(:first-child) {
    margin-left: 4px;
  }
  & > button:not(:last-child) {
    margin-right: 4px;
  }
`;

const RichEditorButton = styled(AppButton)`
  width: 34px;
  height: 34px;
  background-color: ${({ theme }) => theme.colors.soft[40]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.soft[60]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.soft[80]};
  }
`;

const RichEditorButtonDivider = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.gray[40]};
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0px 11px;
  height: 20px;
`;

const SaveButton = styled(AppButton)`
  height: 34px;
  background-color: ${({ theme }) => theme.colors.green[10]};
  color: ${({ theme }) => theme.colors.green[100]};
  &:hover {
    background-color: ${({ theme }) => theme.colors.green[100]};
    color: ${({ theme }) => theme.colors.green[10]};
  }
  &:active {
    opacity: 0.8;
  }
`;

const DownloadButton = styled(AppButton)`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  border: 1px solid ${({ theme }) => theme.colors.dark[50]};
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.gray[20]};
  }

  &:active {
    opacity: 0.8;
  }
`;
