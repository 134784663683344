import { DefaultTheme } from 'styled-components';

const novusTheme: DefaultTheme = {
  fonts: {
    primary: "'Nunito Sans', sans-serif",
    secondary: "'Nunito', sans-serif"
  },
  colors: {
    black: 'black',
    white: 'white',
    yellow: {
      10: '#FBF8DA'
    },
    gray: {
      10: '#FAFBFD',
      15: '#F6F8FC',
      20: '#EDF0F9',
      30: '#E2E5F0',
      40: '#D6DCED',
      50: '#7D89AB',
      60: '#DAE0F2',
      70: '#DCE2F1',
      100: '#1D1F2F'
    },
    purple: {
      30: '#99A9D2',
      40: '#866BA0',
      50: '#69438E',
      60: '#822CD8',
      90: '#411170',
      100: '#3b0a6c',
      120: '#350961'
    },
    red: {
      10: '#fef2f2',
      100: '#8E1111'
    },
    green: {
      10: '#D0E8CC',
      100: '#1B5C0D'
    },
    primary: {
      30: '#404163',
      40: '#3B0A6C'
    },
    dark: {
      50: '#99A9D2',
      70: '#7D89AB',
      80: '#2D2E45',
      90: '#213357',
      100: '#2F3032'
    },
    soft: {
      10: '#FAFBFD',
      20: '#F2ECF8',
      30: '#F8F9FD',
      40: '#EDF0F9',
      50: '#E2E5F0',
      60: '#E3EAF1',
      70: '#DAE0F2',
      80: '#D2D7E5'
    },
    highlight: {
      40: '#FBE4DA'
    },
    border: {
      30: 'rgba(18, 28, 58, 0.1)',
      40: 'rgba(47, 80, 179, 0.1)',
      50: 'rgba(59, 10, 108, 0.1)'
    },
    overlay: {
      40: 'rgba(47, 48, 50, 0.2)'
    }
  },
  shadows: {
    1: '0px 4px 40px rgba(158, 169, 203, 0.19)'
  }
};

export default novusTheme;
