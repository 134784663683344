import { ReactComponent as BookmarkIcon } from 'assets/img/icons/Bookmark.svg';
import { ReactComponent as BookmarkSlashIcon } from 'assets/img/icons/BookmarkSlash.svg';
import { ReactComponent as CopyIcon } from 'assets/img/icons/Copy.svg';
import { ReactComponent as DownloadIcon } from 'assets/img/icons/Download.svg';
import { Dropdown } from 'features/common/Dropdown';
import { Col, Row } from 'features/common/Styles';
import jsPDF from 'jspdf';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createBookmark, deleteBookmark } from 'redux/bookmark';
import { getEntities } from 'redux/entity';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { copyImageToClipboard } from 'utils/helpers/imageHelper';
import { useSelect } from 'utils/hooks/useSelect';
import { v4 } from 'uuid';

export const ArticleGenerationImageCard = memo<{
  id: string;
  imageUrls: string[];
}>(({ id, imageUrls }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['article', 'common']);
  const { articleId } = useContext(ArticleContext);

  const bookmarks = useSelect(({ entity }) => getEntities(entity, 'bookmark'));

  const imageBookmarks = useMemo(() => {
    return bookmarks.filter(({ type }) => type === 'image');
  }, [bookmarks]);

  const imageBookmarkUrls = useMemo(() => {
    return imageBookmarks.map(({ content }) => content);
  }, [imageBookmarks]);

  // @handlers

  const handleBookmarkClick = useCallback(
    (uri: string) => () => {
      if (imageBookmarkUrls.includes(uri)) {
        const bookmark = imageBookmarks.find(({ content }) => content === uri);
        if (bookmark) dispatch(deleteBookmark(articleId, bookmark.id));
        toast.success(t('deleteBookmarkSuccessful'));
      } else {
        if (uri) {
          dispatch(createBookmark({ id: v4(), article_id: articleId, content: uri, type: 'image' }));
          toast.success(t('bookmarkSuccessful'));
        }
      }
    },
    [articleId, imageBookmarks]
  );

  const handleCopyPress = useCallback(
    (uri: string) => () => {
      copyImageToClipboard(uri);
      toast.success(t('copySuccessful'));
    },
    [imageUrls, id, articleId, copyImageToClipboard]
  );

  const handleDownloadPress = useCallback(
    (uri: string) => async (option: number) => {
      try {
        const type = { 0: 'png', 1: 'jpeg', 2: 'pdf' }[option];
        const image = await fetch(uri);
        const imageBlob = await image.blob();

        if (type === 'pdf') {
          const reader = new FileReader();
          reader.onloadend = (currentReader) => {
            var doc = new jsPDF();
            var dataURL = (currentReader.target!.result as string).replace(/data:image\/svg\+xml;base64,/, '');
            doc.addImage(dataURL, 'JPEG', 5, 5, 200, 200);
            doc.save('NovusGeneratedImage.pdf');
          };
          reader.readAsDataURL(imageBlob);
        } else {
          const imageURL = URL.createObjectURL(imageBlob);
          const link = document.createElement('a');
          link.href = imageURL;
          link.download = `NovusGeneratedImage.${type}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        toast.success(t('downloadSuccesful'));
      } catch (err) {
        toast.error(t('common:downloadError'));
        console.log('Download error', err);
      }
    },
    [imageUrls]
  );

  // @render

  return (
    <Container>
      <PicturesContainer>
        {imageUrls.map((uri, index) => (
          <Col key={index}>
            <img
              style={{ objectFit: 'contain', borderRadius: 6, width: 320, minHeight: 320 }}
              key={index}
              onDragStart={(event) => event.dataTransfer.setData('uri', uri)}
              draggable={true}
              id={id}
              src={uri}
              alt=""
            />

            <Row style={{ marginTop: 8 }}>
              <ActionButton onClick={handleBookmarkClick(uri)}>
                {imageBookmarkUrls.includes(uri) ? <BookmarkSlashIcon /> : <BookmarkIcon />}
              </ActionButton>

              <ActionButton onClick={handleCopyPress(uri)}>
                <CopyIcon />
              </ActionButton>

              <Dropdown
                style={{ position: 'relative', width: 100, marginLeft: 6 }}
                position={{ right: 0, top: 24 }}
                options={[{ title: '.png' }, { title: '.jpeg' }, { title: '.pdf' }]}
                handleOptionClick={handleDownloadPress(uri)}
              >
                <ActionButton>
                  <DownloadIcon stroke="#2F3032" style={{ width: 14, height: 14 }} />
                </ActionButton>
              </Dropdown>
            </Row>
          </Col>
        ))}
      </PicturesContainer>
    </Container>
  );
});

const Container = styled.div`
  padding: 20px;
  padding-right: 44px;
  flex-direction: column;
  position: relative;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[60]};
`;

const PicturesContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ActionButton = styled(Col)`
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.soft[40]};
  height: 40px;
  cursor: pointer;
  min-width: 100px;
  margin-right: 10px;
  border: 1px solid ${({ theme }) => theme.colors.purple[30]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.soft[50]};
  }
`;
