import { ReactComponent as Close2SVG } from 'assets/img/icons/Close2.svg';
import OneSheetImg from 'assets/img/icons/OneSheet.png';
import { memo } from 'react';
import styled from 'styled-components';
import { CustomTemplateFile } from 'utils/types';

export const CustomTemplateUploadedFileCard = memo<{
  file: CustomTemplateFile;
  active?: boolean;
  disabled?: boolean;
  onClick?: (file: CustomTemplateFile) => void;
  className?: string;
  onClickClose?: () => void;
}>(({ file, active, disabled, onClick, className, onClickClose }) => {
  return (
    <Container
      className={className}
      active={active}
      disabled={disabled}
      onClick={disabled ? undefined : () => onClick?.(file)}
    >
      {onClickClose ? (
        <CloseIconContainer onClick={() => onClickClose()}>
          <Close2SVG width={7} height={7} />
        </CloseIconContainer>
      ) : null}

      <InnerContainer>
        <img src={OneSheetImg} alt="one-sheet" />
        <FileName>{file.name}</FileName>
      </InnerContainer>
    </Container>
  );
});

const Container = styled.div<{ active?: boolean; disabled?: boolean }>`
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  background-color: ${({ theme }) => theme.colors.gray[20]};
  ${({ disabled }) => (disabled ? 'opacity: 0.5;' : '')};
  border: ${({ active }) => (active ? 2 : 1)}px solid
    ${({ theme, active }) => (active ? theme.colors.purple[90] : theme.colors.gray[60])};
`;

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  margin: -6px -6px -6px 0px;
`;

const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FileName = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 12px;
  line-height: 11px;
  color: ${({ theme }) => theme.colors.primary[30]};
  margin-top: 14px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;
