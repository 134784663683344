import { AnalyticsBrowser } from '@segment/analytics-next';

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_FIREBASE_SEGMENT_WRITE_KEY as string
});

type Payload =
  | { event: 'Signed In'; userId: string; properties: { email: string } }
  | { event: 'Signed Out'; userId: string; properties: { email: string } }
  | { event: 'Fact Check Requested'; userId: string; properties: { sourceText: string } }
  | { event: 'Editor Screen Export'; userId: string; properties: { exportedType: string } }
  | { event: 'Article Session Time'; userId: string; properties: { articleId: string; sessionTime: number } };

export const trackEvent = (payload: Payload) => {
  if (process.env.NODE_ENV === 'development') console.info(payload);
  analytics.track(payload.event, { userId: payload.userId, ...payload.properties });
};

export const identifyUser = (userId: string, traits: { email: string }) => {
  if (process.env.NODE_ENV === 'development') console.info(userId, traits);
  analytics.identify(userId, traits);
};
