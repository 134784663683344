function PlusIcon({ size = 32 }) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3334 4.50033C17.3334 3.76395 16.7364 3.16699 16 3.16699V3.16699C15.2636 3.16699 14.6667 3.76395 14.6667 4.50033V15.167H4.00002C3.26364 15.167 2.66669 15.7639 2.66669 16.5003V16.5003C2.66669 17.2367 3.26364 17.8337 4.00002 17.8337H14.6667V28.5003C14.6667 29.2367 15.2636 29.8337 16 29.8337V29.8337C16.7364 29.8337 17.3334 29.2367 17.3334 28.5003V17.8337H28C28.7364 17.8337 29.3334 17.2367 29.3334 16.5003V16.5003C29.3334 15.7639 28.7364 15.167 28 15.167H17.3334V4.50033Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default PlusIcon;
