import { ReactComponent as NovusMiniSvg } from 'assets/img/NovusMini.svg';
import { ReactComponent as RightArrow } from 'assets/img/icons/ArrowRight.svg';
import { ReactComponent as HomeIcon } from 'assets/img/icons/Home.svg';
import { FlexCol, Row } from 'features/common/Styles';
import { memo, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { animated, easings, useSpring } from 'react-spring';
import { setSelectedImageTemplateId, setSelectedTextTemplateId } from 'redux/editor';
import styled from 'styled-components';
import { Article, DrawerSection, Template } from 'utils/types';
import { useSelect } from 'utils/hooks/useSelect';
import { ArticleDrawerBetaTemplates } from './ArticleDrawerBetaTemplates';
import { ArticleDrawerCustomTemplates } from './ArticleDrawerCustomTemplates';
import { ArticleDrawerImageTemplates } from './ArticleDrawerImageTemplates';
import { ArticleDrawerNavigator } from './ArticleDrawerNavigator';
import { ArticleDrawerTemplates } from './ArticleDrawerTemplates';
import { ArticleDrawerTemplateSettings } from './ArticleDrawerTemplateSettings';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { saveArticle } from 'redux/article';
import { getEntity } from 'redux/entity';

export const ArticleDrawer = memo<{
  onArticleClick: (article: Article) => void;
}>(({ onArticleClick }) => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { articleId, setModalType } = useContext(ArticleContext);

  const generationType = useSelect(({ editor }) => editor.generationType?.[articleId] || 'text');
  const article = useSelect(({ entity }) => getEntity(entity, 'article', articleId));
  const folder = useSelect(({ entity }) => getEntity(entity, 'folder', article?.folder_id || ''));

  const [showTemplateConfiguration, setShowTemplateConfiguration] = useState(false);
  const [selectedSection, setSelectedSection] = useState<DrawerSection | null>(null);
  const [open, setOpen] = useState(true);

  const openAnimation = useSpring({
    from: { opacity: 1, width: '230px' },
    to: { width: open ? '230px' : '0px' },
    config: { duration: 250, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  const arrowAnimation = useSpring({
    from: { opacity: 1 },
    to: { rotate: open ? '180deg' : '0deg' },
    config: { duration: 250, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  // @handlers

  const handleHomeClick = useCallback(() => {
    dispatch(saveArticle(articleId));
    if (folder?.is_draft || !article?.folder_id) setModalType('saveDraft');
    else replace('/');
  }, [replace, articleId, folder]);

  const handleDrawerToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleBackClick = useCallback(() => {
    setSelectedSection(null);
  }, []);

  const handleTemplateClick = useCallback(
    (template: Template) => {
      if (template.parent === 'UserTemplate' && template.status === 'in_progress') {
        setModalType('customTemplatePreparing');
        return;
      }

      if (generationType === 'image') dispatch(setSelectedImageTemplateId(articleId, template.id, true));
      if (generationType === 'text') dispatch(setSelectedTextTemplateId(articleId, template.id, true));

      setShowTemplateConfiguration(true);
    },
    [articleId, generationType]
  );

  // @render

  const renderDrawerContent = useCallback(() => {
    if (showTemplateConfiguration) {
      return (
        <ArticleDrawerTemplateSettings onBackClick={() => setShowTemplateConfiguration(false)} articleId={articleId} />
      );
    } else if (selectedSection === 'templates') {
      return <ArticleDrawerTemplates onBackClick={handleBackClick} onTemplateClick={handleTemplateClick} />;
    } else if (selectedSection === 'beta') {
      return <ArticleDrawerBetaTemplates onBackClick={handleBackClick} />;
    } else if (selectedSection === 'customTemplates') {
      return <ArticleDrawerCustomTemplates onBackClick={handleBackClick} onTemplateClick={handleTemplateClick} />;
    } else {
      if (generationType === 'image') {
        return <ArticleDrawerImageTemplates articleId={articleId} onTemplateClick={handleTemplateClick} />;
      } else {
        return (
          <ArticleDrawerNavigator
            articleId={articleId}
            onTemplateClick={handleTemplateClick}
            onArticleClick={onArticleClick}
            onSectionClick={setSelectedSection}
          />
        );
      }
    }
  }, [selectedSection, articleId, onArticleClick, generationType, showTemplateConfiguration]);

  return (
    <Container>
      <Content style={openAnimation}>
        <Row style={{ padding: 20, paddingBottom: 12, alignItems: 'center', justifyContent: 'space-between' }}>
          <NovusMiniSvg color="white" height={30} width={30} />
          <HomeIcon color="white" height={20} width={20} onClick={handleHomeClick} cursor="pointer" />
        </Row>

        <ScrollContainer>{renderDrawerContent()}</ScrollContainer>
      </Content>

      <DrawerOnOff onClick={handleDrawerToggle}>
        <DrawerBackgroundLayer>
          <animated.div style={arrowAnimation}>
            <RightArrow width={20} height={20} />
          </animated.div>
        </DrawerBackgroundLayer>
      </DrawerOnOff>
    </Container>
  );
});

const Container = styled(Row)`
  display: flex;
  height: 100vh;
  justify-content: space-between;
  overflow: hidden;
`;

const ScrollContainer = styled(FlexCol)`
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled(animated.div)`
  display: flex;
  background: linear-gradient(130deg, #561a55 0%, #641da9 50%);
  flex-direction: column;
  justify-content: space-between;
`;

const DrawerBackgroundLayer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  padding: 0px 5px;
  align-items: center;
  cursor: pointer;
`;

const DrawerOnOff = styled.div`
  background: linear-gradient(130deg, #561a55 0%, #641da9 50%);
  color: ${({ theme }) => theme.colors.white};
`;
