import AppButton from 'features/common/AppButton';
import { AppCheckbox } from 'features/common/AppCheckbox';
import { AppInput } from 'features/common/AppInput';
import { Spinner } from 'features/common/Spinner';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from 'redux/api';
import { login, useCheckShouldResetPasswordMutation } from 'redux/auth/api';
import styled from 'styled-components';
import AppPopUp from './common/AppPopUp';

export const Login: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('welcome');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keepMeLogged, setKeepMeLogged] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pageError, setPageError] = useState<Error | null>(null);

  const [checkShouldResetPassword] = useCheckShouldResetPasswordMutation();
  const [resetPasswordLink, setResetPasswordLink] = useState<string | null>(null);

  // @effects

  useEffect(() => {
    if (pageError) toast.error(t('welcome:message.mailOrPasswordIncorrect'));
  }, [pageError, t]);

  // @handlers

  const handleSubmit = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();

      if (!email || !password) return;
      if (window.screen.width < 700) {
        toast.error(t('welcome:message.noMobile'));
        return;
      }

      try {
        setIsLoading(true);

        const { link } = await checkShouldResetPassword(email).unwrap();

        if (link) setResetPasswordLink(link);
        else await login({ email, password });

        setIsLoading(false);

        dispatch(
          api.util.invalidateTags(['TextGenerations', 'ImageGenerations', 'EditorState', 'Bookmarks', 'Articles'])
        );
        history.replace('/');
      } catch (error: any) {
        setPageError(error);
        setIsLoading(false);
      }
    },
    [email, dispatch, password, t, checkShouldResetPassword]
  );

  const handleForgotPasswordPress = useCallback(() => {
    history.replace('forgot-password');
  }, [history]);

  const handleKeepMeLoginPress = useCallback(() => {
    setKeepMeLogged((prev) => !prev);
  }, []);

  const handleResetPasswordLink = useCallback(() => {
    if (resetPasswordLink) setResetPasswordLink(null);
  }, [resetPasswordLink]);

  // @render

  return (
    <Container>
      <Title>{t('loginToAccount')}</Title>

      <form onSubmit={handleSubmit}>
        <Form>
          <Input name="email" placeholder={t('email')} onChange={setEmail} value={email} />

          <Input
            name="password"
            autoComplete="current-password"
            placeholder={t('password')}
            onChange={setPassword}
            value={password}
            type="password"
          />

          <WelcomeProperty>
            <AppCheckbox
              value={keepMeLogged}
              text={t('welcome:labels.keepMeLogged')}
              onChange={handleKeepMeLoginPress}
            />

            <GoTo onClick={handleForgotPasswordPress}>{t('forgotPassword')}</GoTo>
          </WelcomeProperty>

          <Submit
            text={<>{t('signIn')}</>}
            loading={isLoading}
            customSpinner={<Spinner />}
            disabled={isLoading}
            onClick={handleSubmit}
          />
        </Form>
      </form>

      <AppPopUp open={!!resetPasswordLink}>
        <ResetPasswordContainer>
          <ResetPasswordTitle>{t('passwordExpired')}</ResetPasswordTitle>
          <ResetPasswordSubTitle>{t('passwordExpiredDescription')}</ResetPasswordSubTitle>
          <ResetButton text={t('okay')} onClick={handleResetPasswordLink} />
        </ResetPasswordContainer>
      </AppPopUp>

      <GoToSignUp href={`${process.env.REACT_APP_AI_URL}/plans`}>{t('signUpMessage')}</GoToSignUp>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ResetPasswordContainer = styled.div`
  width: 620px;
  height: 326px;

  padding: 10%;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const ResetPasswordTitle = styled.p`
  font-family: Nunito Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 24px;
`;

const ResetPasswordSubTitle = styled.p`
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 24px;
`;

export const ResetButton = styled(AppButton)`
  margin-top: 25px;
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 8px;
  gap: 10px;
  width: 240px;
  height: 54px;
  background: ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;

  &:hover {
    background: ${({ theme }) => theme.colors.purple[50]};
  }

  &:active {
    background: ${({ theme }) => theme.colors.purple[100]};
  }
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 40px;
  text-align: center;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Input = styled(AppInput)`
  border: none;
  outline: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.purple[100]};
  }
`;

const Submit = styled(AppButton)`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  color: ${({ theme }) => theme.colors.gray[15]};
  font-weight: 100px;
  margin: 15% 2px;
  padding: 12px 50px;
  &:active {
    background-color: ${({ theme }) => theme.colors.purple[100]};
  }
`;

const WelcomeProperty = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;

const GoTo = styled.span`
  background-color: transparent;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.dark[80]};
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

const GoToSignUp = styled.a`
  background-color: transparent;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.dark[80]};
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;
