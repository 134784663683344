import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Label } from './Styles';

export const Dropdown: React.FC<
  PropsWithChildren<{
    style?: React.CSSProperties;
    disabled?: boolean;
    position: { right: number; top: number };
    options: { title: string; icon?: React.ReactNode }[];
    alwaysOnTop?: boolean;
    onToggle?: (open: boolean) => void;
    handleOptionClick: (option: number) => void;
  }>
> = ({ style, children, position, options, disabled = false, alwaysOnTop = false, onToggle, handleOptionClick }) => {
  const drop = React.useRef<HTMLDivElement | null>(null);
  const [displayDropdown, setDisplayDropdown] = useState(false);

  // @effects

  useEffect(() => {
    document.addEventListener('click', handleDropdownCloseClick);
    return () => {
      document.removeEventListener('click', handleDropdownCloseClick);
    };
  }, []);

  useEffect(() => {
    onToggle?.(displayDropdown);
  }, [displayDropdown]);

  // @handlers

  const handleDropdownCloseClick = (event: MouseEvent | any) => {
    if (drop.current && !drop.current.contains(event.target)) {
      event.stopPropagation();
      setDisplayDropdown(false);
    }
  };

  const handleToggleDropdown = (event: any) => {
    if (disabled) return;
    event.stopPropagation();
    setDisplayDropdown((prev) => !prev);
  };

  const handleOptionsDropdownOptionClick = (option: number) => {
    handleOptionClick?.(option);
    setTimeout(() => setDisplayDropdown(false), 300);
  };

  // @render

  return (
    <div style={{ ...style, zIndex: alwaysOnTop ? 1000 : 0 }}>
      <DropdownIcon className="dropdown" style={{ position: 'relative' }} ref={drop} onClick={handleToggleDropdown}>
        {children}
        {displayDropdown && (
          <Drop
            style={{ right: position.right, top: position.top }}
            data={options}
            onItemClick={handleOptionsDropdownOptionClick}
          />
        )}
      </DropdownIcon>
    </div>
  );
};

export const Drop: React.FC<{
  style?: React.CSSProperties;
  data: { title: string; icon?: any }[];
  onItemClick: (index: number) => void;
}> = ({ style, data = [], onItemClick }) => {
  // @handlers

  const handleDropdownOptionClick = useCallback(
    (index: number) => () => {
      onItemClick(index);
    },
    []
  );

  // @render

  const renderOption = (item: { title: string; icon?: any }, index: number) => {
    const Icon = item.icon;
    return (
      <DropdownItem
        key={index}
        onClick={handleDropdownOptionClick(index)}
        style={{ borderBottomWidth: index === data.length - 1 ? 0 : 1 }}
      >
        {Icon ? <IconContainer>{<Icon />}</IconContainer> : null}
        <Label style={{ fontWeight: 600, fontSize: 15, marginTop: 3 }}>{item.title}</Label>
      </DropdownItem>
    );
  };
  return (
    <DropdownItemContainer style={style} className="no-select">
      {data.map((item, index) => renderOption(item, index))}
    </DropdownItemContainer>
  );
};

const DropdownItemContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 10px 10px;
  position: absolute;
  border-radius: 4px;
  right: 0px;
  top: 24px;
  width: 250px;
  z-index: 20;

  & :hover {
    background-color: #f5f5f5;
  }
`;

const IconContainer = styled.div`
  height: 20px;
  margin-right: 8px;
`;

const DropdownItem = styled.div`
  align-items: center;
  border-top-width: 1px;
  display: flex;
  font-size: 16px;
  padding: 9px 12px 9px 12px;
  min-height: 36px;
  border-radius: 4px;
  user-select: none;
  border-bottom: 1px solid #f5f5f5;
`;

const DropdownIcon = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  z-index: 10;
`;
