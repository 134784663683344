import produce from 'immer';
import { uniqBy } from 'lodash';
import { apiService } from 'service/service';
import { uploadCustomTemplateFileToStorage } from 'utils/helpers/storageHelper';
import { CustomTemplateFile, FormField, TEMPLATE_TYPES } from 'utils/types';
import { fetchTemplates } from './article';
import { Action, Thunk } from './store';

type State = {
  files: CustomTemplateFile[];
  selectedFiles: CustomTemplateFile[];
  fileUploadData: {
    totalFilesSize: number;
    uploadedFilesSize: number;
    uploadedFileCount: number;
    totalFileCount: number;
    isDone: boolean;
  };
};

export const initialState: State = {
  files: [],
  selectedFiles: [],
  fileUploadData: {
    totalFilesSize: 0,
    uploadedFilesSize: 0,
    uploadedFileCount: 0,
    totalFileCount: 0,
    isDone: false
  }
};

type ActionType =
  | { type: 'customTemplate/setFiles'; files: CustomTemplateFile[] }
  | { type: 'customTemplate/setSelectedFiles'; files: CustomTemplateFile[] }
  | {
      type: 'customTemplate/setUploadData';
      uploadedFilesSize: number;
      totalFilesSize: number;
      uploadedFileCount: number;
      totalFileCount: number;
    }
  | Action<'me/logout'>;

export const customTemplateReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'customTemplate/setFiles':
      return produce(state, (draft) => {
        draft.files = action.files;
      });

    case 'customTemplate/setUploadData':
      return produce(state, (draft) => {
        draft.fileUploadData = {
          uploadedFilesSize: action.uploadedFilesSize,
          totalFilesSize: action.totalFilesSize,
          uploadedFileCount: action.uploadedFileCount,
          totalFileCount: action.totalFileCount,
          isDone: action.totalFileCount === 0 ? false : action.uploadedFileCount === action.totalFileCount
        };
      });

    case 'customTemplate/setSelectedFiles':
      return produce(state, (draft) => {
        draft.selectedFiles = action.files;
      });

    case 'me/logout':
      return initialState;

    default:
      return state;
  }
};

// @Actions

export const setCustomTemplateSelectedFiles =
  (files: CustomTemplateFile[]): Thunk<ActionType> =>
  (dispatch) => {
    dispatch({ type: 'customTemplate/setSelectedFiles', files: uniqBy(files, 'id') });
  };

export const resetCustomTemplateSelectedFiles = (): Thunk<ActionType> => (dispatch) => {
  dispatch({ type: 'customTemplate/setSelectedFiles', files: [] });
};

export const fetchCustomTemplateFiles = (): Thunk<ActionType> => (dispatch) =>
  apiService
    .fetchCustomTemplateFiles('text')
    .then((files) => dispatch({ type: 'customTemplate/setFiles', files }))
    .observe('fetch_custom_ai_files', dispatch)
    .catchError(dispatch);

export const deleteCustomTemplateFiles =
  (fileIds: string[]): Thunk<ActionType> =>
  (dispatch, getState) =>
    apiService
      .deleteCustomTemplateFiles(fileIds)
      .first(() => {
        let selectedFiles = getState().customTemplate.selectedFiles;
        selectedFiles = selectedFiles.filter((file) => !fileIds.includes(file.id));
        dispatch({ type: 'customTemplate/setSelectedFiles', files: selectedFiles });

        let files = getState().customTemplate.files;
        files = files.filter((file) => !fileIds.includes(file.id));
        dispatch({ type: 'customTemplate/setFiles', files });
      })
      .observe('delete_custom_ai_files', dispatch)
      .catchError(dispatch);

export const createCustomTemplate =
  (payload: {
    file_ids: string[];
    name: string;
    description: string;
    output_example: string;
    template_type: TEMPLATE_TYPES;
    form: Array<FormField>;
    [key: string]: any;
  }): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .createCustomTemplate(payload)
      .then(() => dispatch(fetchTemplates()))
      .observe('create_custom_ai_template', dispatch)
      .catchError(dispatch);

export const uploadCustomTemplateFile =
  (file: File, index: number): Thunk<ActionType> =>
  (dispatch, getState) =>
    uploadCustomTemplateFileToStorage(file, getState().me.user?.id)
      .then(({ url, name, thumbnail, fileType, size, type }) =>
        apiService.createCustomTemplateFile(url, thumbnail, name, type, fileType, size)
      )
      .then((newFile) => {
        const files = getState().customTemplate.files ?? [];
        dispatch({ type: 'customTemplate/setFiles', files: [...files, newFile] });
        const uploadData = getState().customTemplate.fileUploadData;
        dispatch(
          setCustomTemplateFileUploadData(
            uploadData.uploadedFilesSize + file.size,
            uploadData.totalFilesSize,
            uploadData.uploadedFileCount + 1,
            uploadData.totalFileCount
          )
        );
      })
      .observe('upload_custom_ai_file', dispatch)
      .catchError(dispatch);

export const setCustomTemplateFileUploadData =
  (uploadedSize: number, totalSize: number, uploadedCount: number, totalCount: number): Thunk<ActionType> =>
  (dispatch) =>
    dispatch({
      type: 'customTemplate/setUploadData',
      uploadedFilesSize: uploadedSize,
      totalFilesSize: totalSize,
      uploadedFileCount: uploadedCount,
      totalFileCount: totalCount
    });

export const resetCustomTemplateFileUploadData = (): Thunk<ActionType> => (dispatch) =>
  dispatch({
    type: 'customTemplate/setUploadData',
    uploadedFilesSize: 0,
    totalFilesSize: 0,
    uploadedFileCount: 0,
    totalFileCount: 0
  });
