import { ReactComponent as Trash2SVG } from 'assets/img/icons/Trash2.svg';
import AppButton from 'features/common/AppButton';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  deleteCustomTemplateFiles,
  resetCustomTemplateSelectedFiles,
  setCustomTemplateSelectedFiles
} from 'redux/customTemplate';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { CustomTemplateFile } from 'utils/types';
import { CustomTemplateDeleteFilePopup } from './CustomTemplateDeleteFilePopup';
import { CustomTemplateUploadedFileCard } from './CustomTemplateUploadedFileCard';
import { uniqBy } from 'lodash';

const DELETE_BUTTON_TRANSITION_DURATION = 0.3;

type DeletePopupConfig = { open: boolean; title: string; description: string };

export const CustomTemplateMyFilesTab = memo(() => {
  const { t } = useTranslation('article');

  const dispatch = useDispatch();
  const files = useSelect(({ customTemplate }) => customTemplate.files);
  const addedFiles = useSelect(({ customTemplate }) => customTemplate.selectedFiles);

  const trashButtonTimeoutRef = useRef<NodeJS.Timeout>();

  const [selectedFiles, setSelectedFiles] = useState<CustomTemplateFile[]>([]);
  const [deleteButtonText, setDeleteButtonText] = useState('');
  const [deletePopupConfig, setDeletePopupConfig] = useState<DeletePopupConfig>({
    open: false,
    title: '',
    description: ''
  });

  // @handlers

  const handleSelectFile = useCallback((clickedFile: CustomTemplateFile) => {
    setSelectedFiles((oldSelectedFiles) => {
      const fileIndex = oldSelectedFiles.findIndex((file) => file.id === clickedFile.id);
      if (fileIndex === -1) return uniqBy([...oldSelectedFiles, clickedFile], 'id');
      else {
        const newSelectedFiles = [...oldSelectedFiles];
        newSelectedFiles.splice(fileIndex, 1);
        return uniqBy(newSelectedFiles, 'id');
      }
    });
  }, []);

  const handleFileAddClick = useCallback(() => {
    dispatch(setCustomTemplateSelectedFiles(addedFiles.concat(selectedFiles)));
    setSelectedFiles([]);
  }, [dispatch, selectedFiles, addedFiles]);

  const handleDeleteButtonHover = useCallback(() => {
    clearTimeout(trashButtonTimeoutRef.current);

    trashButtonTimeoutRef.current = setTimeout(() => {
      if (selectedFiles.length === 0) setDeleteButtonText(t('deleteAllButton'));
      else setDeleteButtonText(t('deleteSomeButton'));
    }, DELETE_BUTTON_TRANSITION_DURATION * 1000);
  }, [selectedFiles.length, t]);

  const handleDeleteButtonHoverOur = useCallback(() => {
    clearTimeout(trashButtonTimeoutRef.current);
    setDeleteButtonText('');
  }, []);

  const handleCloseDeleteButton = useCallback(() => {
    setDeletePopupConfig((oldConfig) => ({ ...oldConfig, open: false }));
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    const areThereSelectedFiles = selectedFiles.length !== 0;
    setDeletePopupConfig({
      open: true,
      title: areThereSelectedFiles ? t('deleteSomeTitle') : t('deleteAllTitle'),
      description: areThereSelectedFiles ? t('deleteSomeDescription') : t('deleteAllDescription')
    });
  }, [selectedFiles.length, t]);

  const handleDeleteFileClick = useCallback(() => {
    const areThereSelectedFiles = selectedFiles.length !== 0;
    if (!areThereSelectedFiles && files.length === 0) return handleCloseDeleteButton();

    const filesToDelete = areThereSelectedFiles ? selectedFiles.map((file) => file.id) : files.map((file) => file.id);
    dispatch(deleteCustomTemplateFiles(filesToDelete));

    handleCloseDeleteButton();

    if (areThereSelectedFiles) setCustomTemplateSelectedFiles([]);
    else dispatch(resetCustomTemplateSelectedFiles());
  }, [selectedFiles, files, handleCloseDeleteButton]);

  // @render

  return (
    <>
      <Container>
        <FilesContainer>
          {[...files]
            .sort((a, b) => b.created_at - a.created_at)
            .map((file, fileIndex) => {
              const isActive = selectedFiles.findIndex((selectedFile) => selectedFile.id === file.id) !== -1;
              const isAdded = addedFiles.findIndex((addedFile) => addedFile.id === file.id) !== -1;

              return (
                <CustomTemplateUploadedFileCard
                  key={`file-${file.id}-${fileIndex}`}
                  file={file}
                  active={isActive}
                  disabled={!Boolean(file.id) || isAdded}
                  onClick={handleSelectFile}
                />
              );
            })}
        </FilesContainer>

        <ActionButtonsContainer>
          <AddButton
            text={t('customTemplateAddFileButton')}
            onClick={handleFileAddClick}
            disabled={selectedFiles.length === 0}
          />
          <div onMouseOver={handleDeleteButtonHover} onMouseOut={handleDeleteButtonHoverOur}>
            <TrashButton
              text={
                <TrashButtonTextContainer>
                  <Trash2SVG />
                  <p>{deleteButtonText}</p>
                </TrashButtonTextContainer>
              }
              onClick={handleDeleteButtonClick}
            />
          </div>
        </ActionButtonsContainer>
      </Container>

      <CustomTemplateDeleteFilePopup
        {...deletePopupConfig}
        onClickDelete={handleDeleteFileClick}
        onClose={handleCloseDeleteButton}
      />
    </>
  );
});

const Container = styled.div`
  height: 100%;
  overflow: hidden;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FilesContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 96px;
  gap: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AddButton = styled(AppButton)`
  flex: 1;
`;

const TrashButton = styled(AppButton)`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  width: 50px;
  transition: width ${DELETE_BUTTON_TRANSITION_DURATION}s;
  &:hover {
    width: 280px;
  }
`;

const TrashButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
