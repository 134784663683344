import produce from 'immer';
import { uniqBy } from 'lodash';
import { Action, Thunk } from 'redux/store';
import { apiService } from '../service/service';
import { User, UserPlan } from '../utils/types';
import { DeleteAction, MergeAction } from './entity';

type State = {
  isAdmin: boolean | null;
  users: User[];
  userPlans: UserPlan[];
};

export const initialState: State = {
  isAdmin: null,
  users: [],
  userPlans: []
};

type ActionType =
  | { type: 'admin/setIsAdmin'; isAdmin: boolean | null }
  | { type: 'admin/setUsers'; users: User[]; userPlans: UserPlan[] }
  | MergeAction
  | DeleteAction
  | Action<'me/logout'>;

export const adminReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'admin/setIsAdmin':
      return produce(state, (draft) => {
        draft.isAdmin = action.isAdmin;
      });

    case 'admin/setUsers':
      return produce(state, (draft) => {
        draft.users = action.users;
        draft.userPlans = action.userPlans;
      });

    case 'me/logout':
      return initialState;

    default:
      return state;
  }
};

// @actions

export const checkIsAdmin = (): Thunk<ActionType> => (dispatch) =>
  apiService
    .fetchAdmin()
    .then(({ isAdmin }) => dispatch({ type: 'admin/setIsAdmin', isAdmin: isAdmin }))
    .observe('check_is_admin', dispatch)
    .catchError(dispatch);

export const resetAdmin = (): Thunk<ActionType> => (dispatch) => dispatch({ type: 'admin/setIsAdmin', isAdmin: null });

export const fetchAllUsers = (): Thunk<ActionType> => (dispatch) =>
  apiService
    .fetchAdminUsers()
    .then(({ users, userPlans }) => dispatch({ type: 'admin/setUsers', users: uniqBy(users, 'id'), userPlans }))
    .observe('fetch_all_users', dispatch)
    .catchError(dispatch);

export const upgradeUserPlan =
  (userId: string, up: boolean): Thunk<ActionType> =>
  (dispatch, getState) =>
    apiService
      .upgradeUserPlan(userId, up)
      .then((plan) => {
        const users = uniqBy(getState().admin.users, 'id');
        const userPlans = uniqBy([plan, ...getState().admin.userPlans], 'user_id');
        dispatch({ type: 'admin/setUsers', users, userPlans });
      })
      .observe('upgrade_user_plan', dispatch)
      .catchError(dispatch);
