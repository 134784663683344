import dayjs from 'dayjs';
import { FlexCol, Label, Row } from 'features/common/Styles';
import { memo, useCallback } from 'react';
import styled from 'styled-components';
import { Article } from 'utils/types';

export const DrawerFolderArticleListItem = memo<{
  article: Article;
  isLastItem: boolean;
  onArticleClick: (article: Article) => void;
}>(({ article, isLastItem, onArticleClick }) => {
  // @handlers

  const handleArticleClick = useCallback(() => {
    onArticleClick(article);
  }, [article, onArticleClick]);

  // @render

  return (
    <Container style={{ borderBottomWidth: isLastItem ? 0 : 1 }} onClick={handleArticleClick}>
      <FlexCol style={{ alignItems: 'flex-start' }}>
        <Row>
          <Title style={{ marginRight: 6 }}>{'📄'}</Title>
          <Title>{article.title || `Draft: ${dayjs(article.created_at).format('MMMM DD, HH:mm')}`}</Title>
        </Row>
      </FlexCol>
    </Container>
  );
});

const Container = styled(Row)`
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  padding: 10px 10px;
  margin: 0px 0px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 7px;
  margin-top: 4px;
  background-color: rgba(255, 255, 255, 0.05);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Title = styled(Label)`
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  line-height: 22px;
  color: white;
`;
