import React, { memo } from 'react';
import { Col, FlexRow, Label, Row } from '../common/Styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/img/icons/Close.svg';
import styled from 'styled-components';
import { ReactComponent as EyeIcon } from 'assets/img/icons/Eye.svg';
import { ReactComponent as EyeSlashIcon } from 'assets/img/icons/EyeSlash.svg';
import { templateKeywordColors } from '../../utils/helpers/templateHelper';

export const ArticleStats = memo<{
  totalWords: number;
  keywords: { keyword: string; count: number }[];
  highlightKeywords: boolean;
  onHighlightKeywordsPress: () => void;
  onClosePress?: () => void;
}>(({ keywords, totalWords, highlightKeywords, onClosePress, onHighlightKeywordsPress }) => {
  const { t } = useTranslation(['stats']);

  // @render

  return (
    <Container style={{ paddingBottom: keywords.length ? 6 : 10 }}>
      <Row style={{ justifyContent: 'space-between' }}>
        <Row>
          <Title>{t('totalWordCount')}</Title>
          <Title style={{ fontWeight: 800 }}>{totalWords}</Title>
        </Row>

        {onClosePress ? (
          <CloseButton>
            <CloseIcon />
          </CloseButton>
        ) : null}
      </Row>

      {keywords.length !== 0 ? (
        <Row style={{ marginTop: 10, justifyContent: 'space-between' }}>
          <FlexRow style={{ flexWrap: 'wrap' }}>
            {keywords.map(({ keyword, count }, index) => (
              <ArticleStatsKeyword key={index} keyword={keyword} count={count} color={templateKeywordColors[index]} />
            ))}
          </FlexRow>

          <CloseButton style={{ height: 36 }} onClick={onHighlightKeywordsPress}>
            {highlightKeywords ? <EyeSlashIcon width={16} /> : <EyeIcon width={16} />}
          </CloseButton>
        </Row>
      ) : null}
    </Container>
  );
});

const ArticleStatsKeyword = memo<{
  keyword: string;
  count: number;
  color: string;
}>(({ keyword, count, color }) => {
  return (
    <StatsContainer style={{ backgroundColor: color }}>
      <Keyword>{keyword}</Keyword>
      <Keyword style={{ fontWeight: 700 }}>{count}</Keyword>
    </StatsContainer>
  );
});

const Container = styled(Col)`
  background-color: #edf0f9;
  position: relative;
  border: 1px solid rgba(69, 98, 171, 0.5);
  padding: 10px;
  padding-bottom: 6px;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: -14px;
`;

const StatsContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 2px;
  margin-right: 4px;
  margin-bottom: 4px;
`;

const CloseButton = styled(Row)`
  cursor: pointer;
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
`;

const Title = styled(Label)`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-right: 4px;
`;

const Keyword = styled(Label)`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 4px;
`;
