import { useState, useEffect } from 'react';
import { AppInput } from 'features/common/AppInput';
import AppButton from 'features/common/AppButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUpdatePasswordMutation } from 'redux/user/api';
import { Spinner } from 'features/common/Spinner';
import { toast } from 'react-toastify';

const PasswordInformation = () => {
  const { t } = useTranslation('account');

  const [updatePasswordApi, { isLoading: isUpdateLoading, isError: isUpdateError, isSuccess: isUpdateSuccess }] =
    useUpdatePasswordMutation();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    if (isUpdateError) toast.error(t('updateError'));
    else if (isUpdateSuccess) toast.success(t('updateSuccess'));
  }, [isUpdateLoading, isUpdateError, isUpdateSuccess, t]);

  return (
    <Container>
      <Title>{t('passwordInformation')}</Title>
      <AppInput value={oldPassword} onChange={setOldPassword} placeholder={t('oldPassword')} type="password" />
      <AppInput value={newPassword} onChange={setNewPassword} placeholder={t('newPassword')} type="password" />
      <SaveButton
        customSpinner={<Spinner />}
        loading={isUpdateLoading}
        text={t('save')}
        onClick={() => updatePasswordApi({ oldPassword, newPassword })}
      />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.soft[40]};
  flex: 10;
  padding: 40px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  & :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Title = styled.p`
  font-family: Nunito Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 48px !important;
`;

const SaveButton = styled(AppButton)`
  margin-top: 48px !important;

  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 8px;
  gap: 10px;

  width: 200px;
  height: 40px;

  background: ${({ theme }) => theme.colors.dark[100]};
  border-radius: 4px;

  flex: none;
  order: 2;
  flex-grow: 0;
`;

export default PasswordInformation;
