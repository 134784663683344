import { AppSliderInput } from 'features/common/AppSliderInput';
import { Col } from 'features/common/Styles';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setImageGenerationSettings } from 'redux/editor';
import { getEntity } from 'redux/entity';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { useSelect } from 'utils/hooks/useSelect';

export const ArticleDrawerImageTemplateSettings = memo(() => {
  const { t } = useTranslation('article');
  const dispatch = useDispatch();
  const { articleId } = useContext(ArticleContext);

  const imageSettings = useSelect(({ editor }) => editor.imageGenerationSettings);
  const imageTemplate = useSelect(({ editor, entity }) => {
    const templateId = editor.selectedImageTemplateId[articleId];
    return getEntity(entity, 'template', templateId);
  });

  return (
    <Container>
      <Slider
        shouldShow={imageTemplate?.render.includes('numberOfImage')}
        label={t('numberOfImage')}
        value={imageSettings.numberOfImage}
        valueConverter={
          imageTemplate?.endpoint === 'stable_diffusion' ? (oldValue) => Math.pow(oldValue, 2) : undefined
        }
        additionalInfo=""
        min={imageTemplate?.render_value?.['numberOfImage']?.start ?? 1}
        max={imageTemplate?.render_value?.['numberOfImage']?.end ?? 10}
        step={1}
        setter={(newValue) => dispatch(setImageGenerationSettings({ numberOfImage: newValue as number }))}
      />

      <Slider
        shouldShow={imageTemplate?.render.includes('aestheticRating')}
        label={t('aestheticRating')}
        value={imageSettings.aestheticRating}
        additionalInfo=""
        min={imageTemplate?.render_value?.['aestheticRating']?.start ?? 0}
        max={imageTemplate?.render_value?.['aestheticRating']?.end ?? 100}
        setter={(newValue) => dispatch(setImageGenerationSettings({ aestheticRating: newValue as number }))}
      />

      <Slider
        shouldShow={imageTemplate?.render.includes('aestheticWeight')}
        label={t('aestheticWeight')}
        value={imageSettings.aestheticWeight}
        additionalInfo=""
        min={imageTemplate?.render_value?.['aestheticWeight']?.start ?? 0}
        max={imageTemplate?.render_value?.['aestheticWeight']?.end ?? 1}
        step={0.1}
        setter={(newValue) => dispatch(setImageGenerationSettings({ aestheticWeight: newValue as number }))}
      />

      <Slider
        shouldShow={imageTemplate?.render.includes('guidanceScale')}
        label={t('guidanceScale')}
        value={imageSettings.guidanceScale}
        additionalInfo=""
        min={imageTemplate?.render_value?.['guidanceScale']?.start ?? 0}
        max={imageTemplate?.render_value?.['guidanceScale']?.end ?? 100}
        step={0.1}
        setter={(newValue) => dispatch(setImageGenerationSettings({ guidanceScale: newValue as number }))}
      />

      <Slider
        shouldShow={imageTemplate?.render.includes('promptStrength')}
        label={t('promptStrength')}
        value={imageSettings.promptStrength}
        additionalInfo=""
        min={imageTemplate?.render_value?.['promptStrength']?.start ?? 0}
        max={imageTemplate?.render_value?.['promptStrength']?.end ?? 100}
        step={0.1}
        setter={(newValue) => dispatch(setImageGenerationSettings({ promptStrength: newValue as number }))}
      />

      <Slider
        shouldShow={imageTemplate?.render.includes('relevance')}
        label={t('relevance')}
        value={imageSettings.relevance}
        additionalInfo=""
        min={imageTemplate?.render_value?.['relevance']?.start ?? 0}
        max={imageTemplate?.render_value?.['relevance']?.end ?? 100}
        setter={(newValue) => dispatch(setImageGenerationSettings({ relevance: newValue as number }))}
      />

      <Slider
        shouldShow={imageTemplate?.render.includes('seed')}
        label={t('seed')}
        value={imageSettings.seed}
        additionalInfo={''}
        min={imageTemplate?.render_value?.['seed']?.start ?? 0}
        max={imageTemplate?.render_value?.['seed']?.end ?? 100}
        setter={(newValue) => dispatch(setImageGenerationSettings({ seed: newValue as number }))}
      />

      <Slider
        shouldShow={imageTemplate?.render.includes('trialSteps')}
        label={t('trialSteps')}
        value={imageSettings.trialSteps}
        additionalInfo={''}
        min={imageTemplate?.render_value?.['trialSteps']?.start ?? 0}
        max={imageTemplate?.render_value?.['trialSteps']?.end ?? 100}
        setter={(newValue) => dispatch(setImageGenerationSettings({ trialSteps: newValue as number }))}
      />
    </Container>
  );
});

const Slider = memo<{
  shouldShow?: boolean;
  label: string;
  value: number;
  valueConverter?: (oldValue: number) => number;
  additionalInfo: string;
  step?: number;
  min: number;
  max: number;
  setter: (payload: number | number[]) => void;
}>(({ label, value, valueConverter, shouldShow, additionalInfo, step, min, max, setter }) => {
  const { t } = useTranslation('article');
  const finalValue = valueConverter?.(value) ?? value;

  return (
    <Col style={{ display: shouldShow ? 'flex' : 'none' }}>
      <Label>
        {label} {t('value')}: {value}
      </Label>
      <AppSliderInput defaultValue={finalValue} onChange={setter} step={step} min={min} max={max} />
      <AdditionalInfo>{additionalInfo}</AdditionalInfo>
    </Col>
  );
});

const Container = styled.div`
  padding-top: 16px;

  & > div:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const Label = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
`;

const AdditionalInfo = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  margin-top: 6px;
`;
