import { useEffect } from 'react';

export const useKeydown = (key: 'Escape' | 'Enter', callback: () => void, deps: any[]) => {
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === key) callback();
    };

    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [callback, key, ...deps]);
};

export const useEnterKeydown = (callback: () => void, deps: any[]) => {
  useKeydown('Enter', callback, deps);
};

export const useEscapeKeydown = (callback: () => void, deps: any[]) => {
  useKeydown('Escape', callback, deps);
};
