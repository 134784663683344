import { memo, useContext, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { ArticleContext } from 'utils/contexts/ArticleContext';

export const ArticleGenerationHighlightTag = memo<{
  children: React.ReactNode;
  style: React.CSSProperties;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  objectiveIndex: number;
  generationId: string;
  suggestionIndex: number;
}>(({ children, style, disabled, onClick, objectiveIndex, generationId, suggestionIndex }) => {
  const theme = useTheme();
  const { articleId } = useContext(ArticleContext);
  const combinedFacts = useSelect(({ article }) => article.combinedFacts[articleId] ?? []);

  const factsToAdd = useMemo(() => {
    return combinedFacts.filter(
      (item) =>
        item.generationId === generationId &&
        item.suggestionIndex === suggestionIndex &&
        item.objectiveIndex === objectiveIndex
    );
  }, [combinedFacts, generationId, suggestionIndex, objectiveIndex]);

  return (
    <>
      <span
        onClick={disabled ? undefined : onClick}
        style={{
          cursor: disabled ? 'auto' : 'pointer',
          backgroundColor: disabled ? 'transparent' : style.backgroundColor
        }}
      >
        {children}
      </span>{' '}
      {factsToAdd.map((item, factsIndex) =>
        item.domain ? (
          <a
            key={`added-fact-${factsIndex}-${item.generationId}-${item.suggestionIndex}`}
            href={item.domain}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: theme.colors.purple[60] }}
          >
            {item.content}
          </a>
        ) : (
          <span
            key={`added-fact-${factsIndex}-${item.generationId}-${item.suggestionIndex}`}
            style={{ color: theme.colors.purple[60] }}
          >
            {item.content}
          </span>
        )
      )}
    </>
  );
});
