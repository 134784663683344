import AppButton from 'features/common/AppButton';
import { uniq } from 'lodash';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchFactCheck } from 'redux/article';
import { createBookmark, deleteBookmark } from 'redux/bookmark';
import { getEntities, getEntity } from 'redux/entity';
import styled from 'styled-components';
import { trackEvent } from 'utils/analytics';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { templateKeywordColors } from 'utils/helpers/templateHelper';
import { useQuery } from 'utils/hooks/useQuery';
import { useSelect } from 'utils/hooks/useSelect';
import { AITextGeneration, ButtonFactCheck, Fact, FactMatch } from 'utils/types';
import { v4 } from 'uuid';
import { ArticleStats } from '../ArticleStats';
import { ArticleGenerationFactModal } from './ArticleGenerationFactModal';
import { ArticleGenerationHighlightTag } from './ArticleGenerationHighlightTag';
import { FlexRow } from '../../common/Styles';

export const ArticleGenerationTextCard = memo<{
  generationId: string;
  suggestionIndex: number;
  content: string;
  keywords: string[];
  objectives: string[];
  objectives_en: string[];
  fact?: Fact;
  buttonFact?: ButtonFactCheck;
  generations: AITextGeneration;
}>(({ content, keywords, objectives, objectives_en, fact, suggestionIndex, generationId, generations, buttonFact }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const { t } = useTranslation(['article', 'common']);
  const userId = useSelect(({ me }) => me.user?.id);
  const { articleId } = useContext(ArticleContext);

  const article = useSelect(({ entity }) => getEntity(entity, 'article', articleId));
  const settings = useSelect(({ editor }) => editor.textGenerationSettings);
  const bookmarks = useSelect(({ entity }) => getEntities(entity, 'bookmark'));
  const { isLoading: isFactCheckLoading } = useQuery('fetch_fact_check' + generationId);

  const [isFactsModalOpen, setIsFactsModalOpen] = useState(false);
  const [activeObjectiveIndex, setActiveObjectiveIndex] = useState(0);
  const [activeObjective, setActiveObjective] = useState('');
  const [activeObjectivePosY, setActiveObjectivePosY] = useState(0);
  const [highlightKeywords, setHighlightKeywords] = useState(true);

  const bookmark = useMemo(() => {
    return bookmarks?.find((item) => item.type === 'text' && item.content === content);
  }, [bookmarks, content]);

  const totalWordCount = useMemo(() => {
    return generations.generations[suggestionIndex].content.trim().split(/\s+/).length;
  }, [generations, suggestionIndex]);

  const templateKeywords = useMemo(() => {
    const text = generations.generations[suggestionIndex].content;
    const templateInputs = generations.generations[suggestionIndex].template_keywords ?? [];
    const items = uniq((keywords ?? []).concat(templateInputs)).reduce((acc, item) => {
      const regex = new RegExp(item.toLowerCase(), 'g');
      const matches = text.toLowerCase().match(regex);
      const count = matches?.length ?? 0;
      acc.push({ keyword: item, count });
      return acc;
    }, [] as { keyword: string; count: number }[]);

    return items
      .sort((a, b) => b.count - a.count)
      .filter((item) => item.count >= 2)
      .slice(0, templateKeywordColors.length);
  }, [generations, suggestionIndex, keywords, templateKeywordColors]);

  // @utils

  const matchedFacts: FactMatch[] = useMemo(() => {
    if (!fact) return [];
    return fact.match_list.map((matches, index) => ({
      content: fact.news_list?.[index]?.slice(matches.start, matches.end),
      objective_index: matches.objective_index,
      source_domain: fact.source_domain
    }));
  }, [fact]);

  const objectiveIndexesThatHaveFacts = useMemo(() => {
    return matchedFacts.map((item) => item.objective_index);
  }, [matchedFacts]);

  const getFactsForObjective = useCallback(
    (objectiveIndex: number) => {
      return matchedFacts.filter((item) => {
        return item.objective_index === objectiveIndex;
      });
    },
    [matchedFacts]
  );

  // @effects

  useEffect(() => {
    if (objectives_en.length || objectives.length) {
      setHighlightKeywords(false);
    }
  }, [objectives_en, objectives]);

  // @handlers

  const handleFactCheckClick = useCallback(() => {
    const sourceText = generations.generations[suggestionIndex].content;
    const { output_language: outputLanguage, fc_output_language: sameFactLanguage } = settings;
    dispatch(fetchFactCheck(articleId, generations, suggestionIndex, sameFactLanguage, outputLanguage));
    trackEvent({ event: 'Fact Check Requested', userId: userId!, properties: { sourceText } });
  }, [articleId, generations, settings, suggestionIndex, t, userId]);

  const handleCopyClick = useCallback(() => {
    navigator.clipboard.writeText(content);
    toast.success(t('copySuccessful'));
  }, [content, t]);

  const handleToggleBookmarkClick = useCallback(() => {
    if (bookmark) {
      dispatch(deleteBookmark(articleId, bookmark.id));
      toast.success(t('deleteBookmarkSuccessful'));
    } else {
      dispatch(createBookmark({ id: v4(), article_id: articleId, content, type: 'text' }));
      toast.success(t('bookmarkSuccessful'));
    }
  }, [content, article, bookmark]);

  const handleClickHighlightedText = useCallback(
    (event: React.MouseEvent<HTMLSpanElement>, objectiveIndex: number) => {
      const clickPosition = event.currentTarget.offsetTop;
      const contentHeight = containerRef.current?.parentElement?.offsetTop ?? 0;

      setActiveObjectiveIndex(objectiveIndex);
      setActiveObjective(objectives_en[objectiveIndex]);

      setActiveObjectivePosY(clickPosition + contentHeight);
      setIsFactsModalOpen(true);
    },
    [objectives_en]
  );

  // @render

  const renderHighlightedKeyword = useCallback(
    (props: { children: string; style: React.CSSProperties }) => {
      const { children, style } = props;
      const keywordIndex = templateKeywords.findIndex((item) => item.keyword.toLowerCase() === children.toLowerCase());
      const backgroundColor = templateKeywordColors[keywordIndex];
      return <KeywordHighlight style={{ ...style, backgroundColor }}>{children}</KeywordHighlight>;
    },
    [templateKeywords, templateKeywordColors]
  );

  const renderHighlightedObjective = useCallback(
    (props: { children: string; style: React.CSSProperties; highlightIndex: number }) => {
      const { children, style, highlightIndex } = props;
      const hasFact = !objectiveIndexesThatHaveFacts.includes(highlightIndex);
      const hasButtonFact = !!((buttonFact?.[objectives_en?.[highlightIndex] ?? ''] ?? [])?.length === 0);
      const disabled = hasFact && hasButtonFact;

      return (
        <ArticleGenerationHighlightTag
          style={style}
          disabled={disabled}
          onClick={(e) => handleClickHighlightedText(e, highlightIndex)}
          objectiveIndex={highlightIndex}
          generationId={generationId}
          suggestionIndex={suggestionIndex}
        >
          {children}
        </ArticleGenerationHighlightTag>
      );
    },
    [
      objectives_en,
      generationId,
      suggestionIndex,
      buttonFact,
      objectiveIndexesThatHaveFacts,
      handleClickHighlightedText
    ]
  );

  return (
    <Container ref={containerRef}>
      <Sentences
        searchWords={highlightKeywords ? templateKeywords.map((item) => item.keyword.trim()) : objectives}
        autoEscape={true}
        textToHighlight={content}
        highlightTag={highlightKeywords ? renderHighlightedKeyword : renderHighlightedObjective}
      />

      <ArticleGenerationFactModal
        isOpen={isFactsModalOpen}
        onClose={() => setIsFactsModalOpen(false)}
        objectivePositionY={activeObjectivePosY}
        facts={getFactsForObjective(activeObjectiveIndex)}
        buttonFact={buttonFact}
        generationId={generationId}
        suggestionIndex={suggestionIndex}
        objective={activeObjective}
        activeObjectiveIndex={activeObjectiveIndex}
      />

      <ArticleStats
        totalWords={totalWordCount}
        keywords={templateKeywords}
        highlightKeywords={highlightKeywords}
        onHighlightKeywordsPress={() => setHighlightKeywords((prev) => !prev)}
      />

      <Actions>
        <Button text={t('copy')} onClick={handleCopyClick} />
        <Button text={Boolean(bookmark) ? t('removeFromBookmarks') : t('save')} onClick={handleToggleBookmarkClick} />
        <Button text="Fact Check" onClick={handleFactCheckClick} loading={isFactCheckLoading} />
      </Actions>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #dce2f3;
  padding: 24px 28px;
  flex-direction: column;
`;

const KeywordHighlight = styled.span`
  padding: 0px 4px;
  border-radius: 2px;
`;

const Actions = styled(FlexRow)`
  display: flex;
  margin-top: 24px;
  & :not(:last-child) {
    margin-right: 8px;
  }
`;

const Button = styled(AppButton)`
  border: 1px solid rgba(69, 98, 171, 0.5);
  background-color: ${({ loading, theme }) => (loading ? theme.colors.dark[100] : '#edf0f9')};
  padding: 10px;
  border-radius: 4px;
  flex: 1;

  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  min-width: 104px;
  color: ${({ loading, theme }) => (loading ? theme.colors.gray[30] : '#2f3032')};

  &:hover {
    color: ${({ loading, theme }) => (loading ? theme.colors.gray[30] : 'white')};
    background-color: ${({ loading, theme }) => (loading ? theme.colors.dark[100] : theme.colors.primary[30])};
  }
`;

const Sentences = styled(Highlighter).attrs(({ theme }) => ({
  highlightStyle: { backgroundColor: theme.colors.highlight[40] }
}))`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2d2e45;
  word-break: break-word;
  white-space: pre-wrap;
`;
