import { uniq } from 'lodash';
import { Template } from '../types';

export const extractTemplateKeywords = (
  template: Template,
  formInput: { [formFieldKey: string]: string }
): string[] => {
  let templateKeywords: string[] = [];
  if (template.has_keywords && template.form) {
    for (let i = 0; i < template.form.length; i++) {
      const formField = template.form[i];
      if (!formField?.is_keyword) continue;

      const userInput: string = formInput?.[formField.id] ?? '';
      const userKeywords = userInput.split(',').map((keyword) => keyword.trim());
      templateKeywords.push(...userKeywords);
    }
  }

  return uniq(templateKeywords);
};

// provides pastel colors for template keywords
export const templateKeywordColors = [
  '#a2d2ff',
  '#ffcfd2',
  '#e7c6ff',
  '#84dcc6',
  '#bde0fe',
  '#d0b8ac',
  '#b388eb',
  '#ffafcc',
  '#ff70a6',
  '#ff9770',
  '#b9fbc0',
  '#98f5e1',
  '#8eecf5',
  '#ff686b',
  '#e7c6ff',
  '#a3c4f3'
];
