import produce from 'immer';
import { compact, head } from 'lodash';
import { AITemplate, Article, Bookmark, Folder, Tab, Template, TemplateMeta } from '../utils/types';

type ID = string | number;
type Identifiable = { id: ID };

export type Entities = {
  folder: Folder;
  article: Article;
  tab: Tab;
  template: Template;
  templateMeta: TemplateMeta;
  aiTemplate: AITemplate;
  bookmark: Bookmark;
};

export type State = {
  [T in keyof Entities]: Record<ID, Entities[T]>;
};

export type Payload = Partial<{
  [T in keyof Entities]: Entities[T][];
}>;

const initialState: State = {
  folder: {},
  article: {},
  tab: {},
  template: {},
  templateMeta: {},
  aiTemplate: {},
  bookmark: {}
};

// @Reducer

export const entityReducer = (
  state = initialState,
  action: MergeAction | DeleteAction | ReplaceAction | { type: 'me/logout' }
): State => {
  switch (action.type) {
    case 'entity/merge': {
      return produce(state, (draft) => {
        for (const entry of Object.entries(action.entities)) {
          if (!entry[0] || !entry[1]?.length) continue;

          const entityType = entry[0] as keyof State;
          const entities = entry[1] as Identifiable[];
          const entityRecord = compact(entities).reduce((acc, element) => ({ ...acc, [element.id]: element }), {});
          draft[entityType] = { ...draft[entityType], ...entityRecord };
        }
      });
    }

    case 'entity/delete':
      return produce(state, (draft) => {
        for (const [key, entityIds] of Object.entries(action.entities)) {
          for (const entityId of entityIds) delete draft[key as keyof State][entityId];
        }
      });

    case 'entity/replace':
      return produce(state, (draft) => {
        for (const entry of Object.entries(action.entities)) {
          const entityType = entry[0] as keyof State;
          const entities = entry[1] as Identifiable[];

          if (action.replaceIf) {
            for (const existingEntry of Object.entries(draft[entityType] ?? {})) {
              const [key, item] = existingEntry;
              if (action.replaceIf(item)) delete draft[entityType][key];
            }
            const entityRecord = entities.reduce((acc, element) => ({ ...acc, [element.id]: element }), {});
            draft[entityType] = { ...draft[entityType], ...entityRecord };
          } else {
            const entityRecord = entities.reduce((acc, element) => ({ ...acc, [element.id]: element }), {});
            draft[entityType] = entityRecord;
          }
        }
      });

    case 'me/logout':
      return initialState;

    default:
      return state;
  }
};

// @Actions

export const mergeEntities = (entities: Payload): MergeAction => ({
  type: 'entity/merge',
  entities
});

export const deleteEntities = (entities: Partial<Record<keyof State, ID[]>>): DeleteAction => ({
  type: 'entity/delete',
  entities
});

export const replaceEntities = (entities: Payload, replaceIf?: (entity: any) => boolean): ReplaceAction => ({
  type: 'entity/replace',
  entities,
  replaceIf
});

// @Selectors

export const getEntity = <T extends keyof State>(
  state: State,
  entityType: T,
  primaryId?: ID | null
): Entities[T] | null => {
  const entities = state[entityType] as Record<ID, Entities[T]>;
  return primaryId ? entities[primaryId] ?? null : null;
};

export const getEntities = <T extends keyof State>(
  state: State,
  entityType: T,
  filter?: (entity: Entities[T]) => boolean
): Entities[T][] => {
  const entities = Object.values(state[entityType] ?? {}) as Entities[T][];
  return filter ? entities.filter(filter) : entities;
};

export const getFirstEntity = <T extends keyof State>(
  state: State,
  entityType: T,
  filter: (entity: Entities[T]) => boolean
): Entities[T] | null => {
  return head(getEntities(state, entityType, filter)) ?? null;
};

// @Action Types

export type MergeAction = {
  type: 'entity/merge';
  entities: Payload;
};

export type DeleteAction = {
  type: 'entity/delete';
  entities: Partial<Record<keyof State, ID[]>>;
};

export type ReplaceAction = {
  type: 'entity/replace';
  entities: Payload;
  replaceIf?: (entity: any) => boolean;
};
