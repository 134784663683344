import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const DefaultText = styled.p`
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #7d89ab;
`;

export const InformationTextTop = styled(DefaultText)`
  margin-top: 24px;
`;
