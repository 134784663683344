import { useState } from 'react';
import { useDispatch } from 'react-redux';

import ArrowDownDarkIcon from 'assets/img/icons/ArrowDownDark.svg';
import AppModal from 'features/common/AppModal';
import AppSelectbox from 'features/common/AppSelectbox';
import { SUPPORTED_INPUT_OUTPUT_LANGS } from 'config/constants';
import { useSelect } from 'utils/hooks/useSelect';
import { LanguageOption } from 'utils/types';

import AppButton from 'features/common/AppButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { setTextGenerationSettings } from 'redux/editor';

export const ArticleLanguagePicker: React.FC = () => {
  const { t } = useTranslation('article');
  const dispatch = useDispatch();
  const settings = useSelect(({ editor }) => editor.textGenerationSettings);

  const [isVisible, setIsVisible] = useState(false);

  const selectedInputLanguage = SUPPORTED_INPUT_OUTPUT_LANGS.find((item) => item.value === settings.language);
  const selectedOutputLanguage = SUPPORTED_INPUT_OUTPUT_LANGS.find((item) => item.value === settings.output_language);

  return (
    <>
      <InputOutputLanguageButton
        text={
          <>
            <span>
              {selectedInputLanguage?.label}, {selectedOutputLanguage?.label}
            </span>
            <img src={ArrowDownDarkIcon} alt="arrow-down" />
          </>
        }
        onClick={() => setIsVisible(true)}
      />

      <AppModal visible={isVisible} onClose={() => setIsVisible(false)}>
        <ModalContainer>
          <ModalTitle>{t('languageSettings')}</ModalTitle>

          <AppSelectbox
            label={t('inputLanguage')}
            defaultValue={selectedInputLanguage ?? SUPPORTED_INPUT_OUTPUT_LANGS[0]}
            options={SUPPORTED_INPUT_OUTPUT_LANGS}
            onChange={(newValues) =>
              dispatch(setTextGenerationSettings({ language: newValues[0].value as LanguageOption }))
            }
          />

          <AppSelectbox
            label={t('outputLanguage')}
            defaultValue={selectedOutputLanguage ?? SUPPORTED_INPUT_OUTPUT_LANGS[0]}
            options={SUPPORTED_INPUT_OUTPUT_LANGS}
            onChange={(newValues) =>
              dispatch(setTextGenerationSettings({ output_language: newValues[0].value as LanguageOption }))
            }
          />
        </ModalContainer>
      </AppModal>
    </>
  );
};

const InputOutputLanguageButton = styled(AppButton)`
  min-width: 185px;
  padding: 7px 12px;
  background-color: ${({ theme }) => theme.colors.soft[40]};
  &:hover {
    background-color: ${({ theme }) => theme.colors.soft[40]};
  }

  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary[30]};
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
`;

const ModalContainer = styled.div`
  width: 380px;
  height: 327px;
  border-radius: 16px;
  padding: 0px 60px;
  padding-top: 48px;
  padding-bottom: 60px;
  background-color: ${({ theme }) => theme.colors.white};

  & > div:last-child {
    margin-top: 32px;
  }
`;

const ModalTitle = styled.p`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
`;
