import AppButton from 'features/common/AppButton';
import SpinnerIcon from 'features/common/Icons/SpinnerIcon';
import { map } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createCustomTemplate, resetCustomTemplateSelectedFiles } from 'redux/customTemplate';
import {
  generateRandomTemplateName,
  getFormFieldsForCustomTemplate,
  getInitialTemplateRelatedFields
} from 'utils/customTemplate';
import { useQuery } from 'utils/hooks/useQuery';
import { useSelect } from 'utils/hooks/useSelect';
import { CustomTemplateDefaultFields, CustomTemplateRelatedFields, TEMPLATE_TYPES } from 'utils/types';
import { Col, FlexCol, FlexRow } from '../common/Styles';
import { CustomTemplateAddedFiles } from './CustomTemplateAddedFiles';
import { CustomTemplateForm } from './CustomTemplateForm';
import { CustomTemplateUpload } from './CustomTemplateUpload';

export const CustomTemplateCreator = memo<{
  onTemplateCreated: () => void;
}>(({ onTemplateCreated }) => {
  const { t } = useTranslation(['article', 'common']);

  const dispatch = useDispatch();
  const files = useSelect(({ customTemplate }) => customTemplate.selectedFiles);
  const { isLoading, isSuccess } = useQuery('create_custom_ai_template');

  const [defaultFields, setDefaultFields] = useState<CustomTemplateDefaultFields>({
    templateName: generateRandomTemplateName(),
    selectedTemplateType: TEMPLATE_TYPES.INSTAGRAM_MARKET_PRODUCT,
    outputExample: '',
    templateDescription: ''
  });
  const [templateRelatedFields, setTemplateRelatedFields] = useState<CustomTemplateRelatedFields>(
    getInitialTemplateRelatedFields(TEMPLATE_TYPES.INSTAGRAM_MARKET_PRODUCT)
  );
  const [errorField, setErrorField] = useState('');

  // @effects

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('customTemplateCreated'));
      onTemplateCreated();
    }
  }, [isSuccess]);

  useEffect(() => {
    dispatch(resetCustomTemplateSelectedFiles());
  }, []);

  // @handlers

  const getFirstInvalidField = useCallback(() => {
    for (const [fieldKey, fieldValue] of Object.entries(templateRelatedFields)) {
      if (fieldValue.length === 0) return fieldKey;
    }
    if (defaultFields.templateName.length === 0) return 'templateName';
    if (defaultFields.selectedTemplateType.length === 0) return 'selectedTemplateType';

    return '';
  }, [defaultFields, templateRelatedFields]);

  const handleCreateClick = useCallback(async () => {
    try {
      const firstInvalidField = getFirstInvalidField();
      if (firstInvalidField) return setErrorField(firstInvalidField);
      else setErrorField('');

      dispatch(
        createCustomTemplate({
          name: defaultFields.templateName,
          template_type: defaultFields.selectedTemplateType,
          description: defaultFields.templateDescription,
          output_example: defaultFields.outputExample,
          file_ids: map(files, 'id'),
          form: getFormFieldsForCustomTemplate(defaultFields.selectedTemplateType),
          ...templateRelatedFields
        })
      );
    } catch (error) {
      toast.error(t('somethingsWrongError'));
    }
  }, [getFirstInvalidField, defaultFields, templateRelatedFields, files]);

  // @render

  return (
    <FlexCol style={{ overflow: 'hidden', paddingTop: 24 }}>
      <FlexRow style={{ overflow: 'hidden', alignItems: 'stretch', gap: 40 }}>
        <FlexCol style={{ overflow: 'hidden', gap: 10 }}>
          <CustomTemplateForm
            defaultFields={defaultFields}
            setDefaultFields={setDefaultFields}
            templateRelatedFields={templateRelatedFields}
            setTemplateRelatedFields={setTemplateRelatedFields}
            errorField={errorField}
          />
        </FlexCol>

        <FlexCol style={{ minWidth: '50%', maxWidth: '50%' }}>
          <CustomTemplateUpload />
        </FlexCol>
      </FlexRow>

      <CustomTemplateAddedFiles />

      <Col style={{ paddingTop: 12, paddingBottom: 30 }}>
        <AppButton
          disabled={!files.length}
          text={t('create')}
          onClick={handleCreateClick}
          loading={isLoading}
          customSpinner={<SpinnerIcon />}
        />
      </Col>
    </FlexCol>
  );
});
