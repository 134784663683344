import AppButton from 'features/common/AppButton';
import AppSelectbox from 'features/common/AppSelectbox';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { generateRandomTemplateName, getInitialTemplateRelatedFields } from 'utils/customTemplate';
import {
  AppSelectboxOption,
  CustomTemplateDefaultFields,
  CustomTemplateRelatedFields,
  TEMPLATE_TYPES
} from 'utils/types';
import { CustomTemplateInput } from './CustomTemplateInput';
import { FlexCol } from '../common/Styles';

const TemplateTypeOptions = [
  { label: '📱 Instagram Market Product', value: TEMPLATE_TYPES.INSTAGRAM_MARKET_PRODUCT },
  { label: '📺 Ad Generation', value: TEMPLATE_TYPES.AD_GENERATION },
  { label: '🛍 Etsy Product Description', value: TEMPLATE_TYPES.ETSY_PRODUCT_DESCRIPTION },
  { label: '🏷 Google Ads Description', value: TEMPLATE_TYPES.GOOGLE_ADS_DESCRIPTION },
  { label: '📃 Video Script', value: TEMPLATE_TYPES.VIDEO_SCRIPT },
  { label: '🎥 Video Description', value: TEMPLATE_TYPES.VIDEO_DESCRIPTION },
  { label: '🤓 Case Study', value: TEMPLATE_TYPES.CASE_STUDY },
  { label: '🏡 Real Estate Listing', value: TEMPLATE_TYPES.REAL_ESTATE_LISTING },
  { label: '🥶 Cold E-mail', value: TEMPLATE_TYPES.COLD_EMAIL },
  { label: '📈 Sales Sequence', value: TEMPLATE_TYPES.SALES_SEQUENCE },
  { label: '📌 Pain Point Generator', value: TEMPLATE_TYPES.PAIN_POINT_GENERATOR },
  { label: '📑 SEO Article', value: TEMPLATE_TYPES.SEO_ARTICLE },
  { label: '💥 Elevator Pitch', value: TEMPLATE_TYPES.ELEVATOR_PITCH },
  { label: '✏️ Free-form', value: TEMPLATE_TYPES.FREE_FORM }
];

export const CustomTemplateForm = memo<{
  defaultFields: CustomTemplateDefaultFields;
  errorField?: string;
  templateRelatedFields: CustomTemplateRelatedFields;
  setDefaultFields: (newValue: CustomTemplateDefaultFields) => void;
  setTemplateRelatedFields: (newValue: CustomTemplateRelatedFields) => void;
}>(({ defaultFields, setDefaultFields, templateRelatedFields, setTemplateRelatedFields, errorField }) => {
  const { t } = useTranslation(['article']);

  const templateFields = useMemo(() => {
    return t('form', { returnObjects: true }) as {
      [key: string]: { [key: string]: { title: string; description: string; placeholder: string } };
    };
  }, [t]);

  // @effects

  useEffect(() => {
    if (!errorField) return;
    const label = document.getElementById(errorField);
    label?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, [errorField]);

  // @handlers

  const handleChangeRequiredFiled = useCallback(
    (key: keyof CustomTemplateDefaultFields, newValue: string) => {
      setDefaultFields({ ...defaultFields, [key]: newValue });
    },
    [defaultFields, setDefaultFields]
  );
  const handleChangeOptionalField = useCallback(
    (key: string, newValue: string) => {
      setTemplateRelatedFields({ ...templateRelatedFields, [key]: newValue });
    },
    [templateRelatedFields, setTemplateRelatedFields]
  );

  const handleChangeTemplateType = useCallback(
    (newSelectedValues: AppSelectboxOption[]) => {
      const selectedTemplateType = newSelectedValues[0].value as TEMPLATE_TYPES;
      setDefaultFields({ ...defaultFields, selectedTemplateType });
      setTemplateRelatedFields(getInitialTemplateRelatedFields(selectedTemplateType));
    },
    [defaultFields, setDefaultFields, setTemplateRelatedFields]
  );

  // @render

  return (
    <FormInputArea>
      <TemplateNameContainer>
        <HalfWidthInput
          id="templateName"
          label="Template Name"
          onChange={(newName) => handleChangeRequiredFiled('templateName', newName)}
          value={defaultFields.templateName}
          error={errorField === 'templateName'}
        />

        <DiceButton
          variant="link"
          text="🎲"
          onClick={() => handleChangeRequiredFiled('templateName', generateRandomTemplateName())}
        />
      </TemplateNameContainer>

      <TemplateTypeSelectbox
        defaultValue={TemplateTypeOptions[0]}
        options={TemplateTypeOptions}
        onChange={handleChangeTemplateType}
      />

      <FlexCol style={{ marginTop: 24 }}>
        {Object.entries(templateFields[defaultFields.selectedTemplateType] ?? {}).map(([fieldName, field]) => (
          <CustomTemplateInput
            id={fieldName}
            key={fieldName}
            label={field.title}
            description={field.description}
            placeholder={field.placeholder}
            value={templateRelatedFields[fieldName] ?? ''}
            onChange={(newValue) => handleChangeOptionalField(fieldName, newValue)}
            required
            error={errorField === fieldName}
          />
        ))}
        <CustomTemplateInput
          label={t('customAiOutputExampleLabel')}
          description={t('customAiOutputExampleDescription')}
          placeholder={t('customAiOutputExamplePlaceholder')}
          value={defaultFields.outputExample}
          onChange={(newOutput) => handleChangeRequiredFiled('outputExample', newOutput)}
          multiline
        />
        <CustomTemplateInput
          label={t('customAiTemplateDescriptionLabel')}
          description={t('customAiTemplateDescriptionDescription')}
          placeholder={t('customAiTemplateDescriptionPlaceholder')}
          value={defaultFields.templateDescription}
          onChange={(newDescription) => handleChangeRequiredFiled('templateDescription', newDescription)}
          multiline
        />
      </FlexCol>
    </FormInputArea>
  );
});

//#region Styles

const FormInputArea = styled.div`
  flex: 1;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const TemplateNameContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const HalfWidthInput = styled(CustomTemplateInput)`
  width: 350px;
`;

const DiceButton = styled(AppButton)`
  margin-bottom: 10px;
`;

const TemplateTypeSelectbox = styled(AppSelectbox)`
  width: 350px;
  margin-bottom: 6px;

  & .novus-select {
    background-color: transparent !important;
    border: 1px solid ${({ theme }) => theme.colors.gray[20]} !important;
    padding-left: 0px;
  }
`;

//#endregion
