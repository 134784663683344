import { ArticleHeader } from 'features/article/ArticleHeader';
import { ArticleGenerationList } from 'features/article/generation';
import { ArticleInputEditor } from 'features/article/input';
import { FlexCol, FlexRow } from 'features/common/Styles';
import { ArticleDrawer } from 'features/drawer/article';
import { head } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useHistory } from 'react-router-dom';
import { createArticle, fetchArticle, fetchGenerations, fetchTemplates } from 'redux/article';
import { setArticleInputType, setSelectedImageTemplateId, setSelectedTextTemplateId } from 'redux/editor';
import { getEntities, getEntity } from 'redux/entity';
import { trackEvent } from 'utils/analytics';
import { ArticleProvider } from 'utils/contexts/ArticleContext';
import { useSelect } from 'utils/hooks/useSelect';
import { Article } from 'utils/types';
import { CustomAIModal } from '../customTemplate';
import { ArticleGenerationBookmarkModal } from './generation/ArticleGenerationBookmarkModal';
import { ArticleEditorProofread } from './input/editor/ArticleEditorProofread';
import { SaveArticleModal } from './modal/SaveArticleModal';
import { ArticleEditorPlagiarism } from './input/editor/ArticleEditorPlagiarism';
import { useQuery } from 'utils/hooks/useQuery';

export const ArticlePage: React.FC<{
  articleId: string;
  newArticle?: boolean;
}> = ({ articleId, newArticle }) => {
  const sessionStartTime = useRef(Date.now()).current;

  const dispatch = useDispatch();
  const { push, replace } = useHistory();

  const user = useSelect(({ me }) => me.user);
  const article = useSelect(({ entity }) => getEntity(entity, 'article', articleId));

  const { isLoading: isCreating } = useQuery('create_article');
  const { isFailure } = useQuery('fetch_article_' + articleId);
  const templates = useSelect(({ entity }) => getEntities(entity, 'template'));
  const textTemplateId = useSelect(({ editor }) => editor.selectedTextTemplateId?.[articleId] ?? '');
  const imageTemplateId = useSelect(({ editor }) => editor.selectedImageTemplateId?.[articleId] ?? '');

  const onLayout = (sizes: number[]) => {
    document.cookie = `react-resizable-panels:layout=${JSON.stringify(sizes)}`;
  };

  // @effects

  useEffect(() => {
    if (articleId && isFailure && !isCreating) push('/');
  }, [articleId, isFailure, isCreating]);

  useEffect(() => {
    if (articleId) {
      dispatch(fetchGenerations(articleId));
      dispatch(fetchTemplates());
    }

    return () => {
      if (user && articleId) {
        const properties = { articleId, sessionTime: Date.now() - sessionStartTime };
        trackEvent({ event: 'Article Session Time', userId: user.id!, properties });
      }
      dispatch(setArticleInputType('form'));
    };
  }, [articleId, user, article?.id]);

  useEffect(() => {
    if (newArticle) dispatch(createArticle(articleId, '', '', true));
    else dispatch(fetchArticle(articleId));
  }, [newArticle]);

  useEffect(() => {
    //  dispatch(fetchArticle(articleId));

    const templateId = article?.selected_template_id;
    if (templateId !== textTemplateId) {
      if (templateId) dispatch(setSelectedTextTemplateId(articleId, templateId));
    }
  }, [articleId]);

  useEffect(() => {
    if (!textTemplateId) {
      const templateId = head(templates.filter(({ type }) => type === 'text'))?.id;
      if (templateId) dispatch(setSelectedTextTemplateId(articleId, templateId));
    }
    if (!imageTemplateId) {
      const templateId = head(templates.filter(({ type }) => type === 'image'))?.id;
      if (templateId) dispatch(setSelectedImageTemplateId(articleId, templateId));
    }
  }, [textTemplateId, imageTemplateId]);

  // @handlers

  const handleArticleClick = useCallback(
    (selectedArticle: Article) => {
      if (selectedArticle.id !== articleId) push(`/article/${selectedArticle.id}`);
    },
    [articleId]
  );

  const handleArticleSaveComplete = useCallback(() => {
    replace('/');
  }, []);

  // @render

  return (
    <FlexRow>
      <ArticleProvider articleId={articleId}>
        <ArticleDrawer onArticleClick={handleArticleClick} />

        <FlexCol>
          <ArticleHeader />

          <FlexRow>
            <PanelGroup direction="horizontal" onLayout={onLayout}>
              <Panel defaultSize={50}>
                <ArticleInputEditor />
              </Panel>

              <PanelResizeHandle style={{ width: 2, backgroundColor: '#DCE2F1' }} />

              <Panel defaultSize={50}>
                <ArticleGenerationList />
              </Panel>
            </PanelGroup>
          </FlexRow>
        </FlexCol>

        <CustomAIModal />

        <SaveArticleModal onSaveComplete={handleArticleSaveComplete} />

        <ArticleEditorProofread />
        <ArticleEditorPlagiarism />
        <ArticleGenerationBookmarkModal />
      </ArticleProvider>
    </FlexRow>
  );
};
