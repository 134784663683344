import { createSlice } from '@reduxjs/toolkit';
import { getAuth } from 'firebase/auth';
import { Authorization } from 'utils/types';
import { trackEvent } from 'utils/analytics';

type AuthState = {
  isAuth: Authorization | null;
};

const initialState: AuthState = {
  isAuth: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth') || '') : null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: { payload: Authorization }) {
      state.isAuth = action.payload;
      localStorage.setItem('auth', JSON.stringify(state.isAuth));
    },
    login(state, action: { payload: Authorization }) {
      state.isAuth = action.payload;
      localStorage.setItem('auth', JSON.stringify(state.isAuth));
    },
    logoutHandle: (state, action: { payload: { email: string; userId: string } }) => {
      const { payload } = action;
      state.isAuth = null;
      localStorage.removeItem('auth');
      Object.keys(sessionStorage).forEach((e) => {
        sessionStorage.removeItem(e);
      });
      getAuth().signOut();
      trackEvent({ event: 'Signed Out', userId: payload.userId, properties: { email: payload.email } });
    }
  }
});

export const { setAuth, logoutHandle, login } = authSlice.actions;
export default authSlice.reducer;
