import ArrowDownIcon from 'assets/img/icons/ArrowDown.svg';

import { Container, DropdownHandleImg } from './styles';

function SelectboxDropdownHandle({ isDropdownOpen }: { isDropdownOpen: boolean }) {
  return (
    <Container>
      <DropdownHandleImg isDropdownOpen={isDropdownOpen} src={ArrowDownIcon} alt="arrow-down" />
    </Container>
  );
}

export default SelectboxDropdownHandle;
