import AppPopUp from 'features/common/AppPopUp';
import { Col, FlexCol, Label, MainButton, centered } from 'features/common/Styles';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteArticle } from 'redux/article';
import styled from 'styled-components';
import { HomeContext } from 'utils/contexts/HomeContext';
import { useEnterKeydown, useEscapeKeydown } from 'utils/hooks/useKeydown';

export const DeleteArticleModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('home');

  const { selectedArticleId, setSelectedArticleId, modalType, setModalType } = useContext(HomeContext);

  // @effects

  useEscapeKeydown(() => {
    if (modalType === 'deleteArticle') setModalType(null);
  }, [modalType]);

  useEnterKeydown(() => {
    if (modalType === 'deleteArticle') handleDeleteClick();
  }, [selectedArticleId, modalType]);

  // @handlers

  const handleDeleteClick = useCallback(() => {
    if (selectedArticleId) dispatch(deleteArticle(selectedArticleId));
    setSelectedArticleId(null);
    setModalType(null);
  }, [selectedArticleId, modalType]);

  // @render

  return (
    <AppPopUp open={modalType === 'deleteArticle'} onClose={() => setModalType(null)}>
      <Container style={centered}>
        <FlexCol style={{ ...centered, borderRadius: 8 }}>
          <Title style={{ fontSize: 40, marginBottom: 20, marginTop: 28 }}>{'📄'}</Title>
          <Title>{t('deleteProjectTitle')} </Title>
          <InformationText>{t('deleteProjectInfo')}</InformationText>
        </FlexCol>

        <BottomContainer>
          <MainButton text={t('delete')} loading={false} onClick={handleDeleteClick} />
        </BottomContainer>
      </Container>
    </AppPopUp>
  );
};

const Container = styled(Col)`
  width: 420px;
  height: 248px;
  background: #ffffff;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
`;

const BottomContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

const Title = styled(Label)`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 10px;
  text-align: center;
`;

const InformationText = styled(Label)`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
`;
