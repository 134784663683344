import { ReactComponent as RightArrow } from 'assets/img/icons/ArrowRight.svg';
import { FlexCol, Label, Row } from 'features/common/Styles';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { retrieveTemplateIcon, retrieveTemplateName } from 'utils/helpers/articleHelper';
import { Template } from 'utils/types';

export const ArticleDrawerTemplateListItem = memo<{
  template: Template;
  isLastItem: boolean;
  isSelected: boolean;
  removePadding?: boolean;
  onTemplateClick: (template: Template) => void;
}>(({ template, isLastItem, isSelected, removePadding, onTemplateClick }) => {
  const { t } = useTranslation(['article', 'common']);

  // @handlers

  const handleTemplateClick = useCallback(() => {
    onTemplateClick(template);
  }, [template, onTemplateClick]);

  // @render

  return (
    <Container
      className={isSelected ? 'gradient-border' : ''}
      style={{
        borderBottomWidth: isLastItem ? 0 : 1,
        minWidth: removePadding ? 190 : 183,
        marginLeft: removePadding ? 0 : 7,
        marginBottom: isLastItem ? 8 : 0
      }}
      onClick={handleTemplateClick}
    >
      <FlexCol
        style={{
          zIndex: 2,
          alignItems: 'flex-start',
          borderRadius: 4,
          backgroundColor: isSelected ? 'rgb(106,40,162)' : 'transparent'
        }}
      >
        <Row style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: 10 }}>
          <Title>{`${retrieveTemplateIcon(template)} ${retrieveTemplateName(template, t)}`}</Title>
          <RightArrow width={12} height={12} color={'white'} opacity={0.5} />
        </Row>
      </FlexCol>
    </Container>
  );
});

const Container = styled(Row)`
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  margin: 0px 0px;
  cursor: pointer;
  padding: 1px;
  border-radius: 4px;
  margin-left: 7px;
  margin-top: 4px;
  background-color: rgba(255, 255, 255, 0.05);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Title = styled(Label)`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: white;
  flex: 1;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-align: left;
  overflow: hidden;
`;
