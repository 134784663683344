import AppButton from 'features/common/AppButton';
import { AppInput } from 'features/common/AppInput';
import AppPopUp from 'features/common/AppPopUp';
import { Col } from 'features/common/Styles';
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getEntity } from 'redux/entity';
import { updateFolder } from 'redux/folder';
import styled from 'styled-components';
import { HomeContext } from 'utils/contexts/HomeContext';
import { useEnterKeydown, useEscapeKeydown } from 'utils/hooks/useKeydown';
import { useSelect } from 'utils/hooks/useSelect';

export const EditFolderModal = memo(() => {
  const ref = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const { t } = useTranslation(['home', 'common']);
  const { selectedFolderId, modalType, setModalType, setSelectedFolderId } = useContext(HomeContext);

  const folder = useSelect(({ entity }) => getEntity(entity, 'folder', selectedFolderId));
  const [folderName, setFolderName] = useState<string>(folder?.name ?? '');

  // @effects

  useEscapeKeydown(() => {
    if (modalType === 'editFolder') setModalType(null);
  }, [modalType]);

  useEnterKeydown(() => {
    if (modalType === 'editFolder') handleUpdateClick();
  }, [folderName, folder, modalType]);

  useEffect(() => {
    if (modalType === null) {
      setSelectedFolderId(null);
      setFolderName('');
    } else if (modalType === 'editFolder') {
      ref.current?.focus();
    }
  }, [modalType]);

  useEffect(() => {
    if (folder) setFolderName(folder.name ?? '');
  }, [selectedFolderId]);

  // @handlers

  const handleUpdateClick = useCallback(() => {
    if (folderName.trim() === '') return toast.info(t('notEmptyFolderName'));
    if (selectedFolderId) {
      dispatch(updateFolder(selectedFolderId, folderName));
      setModalType(null);
    }
  }, [selectedFolderId, folderName]);

  const handleCloseModal = useCallback(() => {
    setModalType(null);
  }, []);

  // @render

  return (
    <AppPopUp open={modalType === 'editFolder'} onClose={handleCloseModal}>
      <Container>
        <Title>{`📁  ${t('editFolder')}`}</Title>
        <ProjectNameInput ref={ref} placeholder={t('folderName')} onChange={setFolderName} value={folderName} />
        <SaveButton text={t('save')} onClick={handleUpdateClick} />
      </Container>
    </AppPopUp>
  );
});

const Container = styled(Col)`
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
`;

const Title = styled.p`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.colors.black};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ProjectNameInput = styled(AppInput)`
  min-width: 380px !important;
  width: 380px !important;
`;

const SaveButton = styled(AppButton)`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 20px;
  background: ${({ theme }) => theme.colors.dark[100]};
  border-radius: 4px;
`;
