import SentencesListIllustratorSvg from 'assets/img/SentencesListIllustrator.svg';
import emptyGenerationLottieAnimation from 'assets/json/emptyGenerationLottie.json';
import Lottie from 'lottie-react';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { useQuery } from 'utils/hooks/useQuery';
import { Container, DefaultText, InformationTextTop } from './styles';

export const SentencesListIllustrator = memo(() => {
  const { t } = useTranslation('article');
  const { articleId } = useContext(ArticleContext);

  const { isLoading: isRegenerateLoading } = useQuery('regenerate_text' + articleId);
  const { isLoading: isTextLoading } = useQuery('generate_text');
  const { isLoading: isImageLoading } = useQuery('generate_image');

  const isLoading = isRegenerateLoading || isTextLoading || isImageLoading;

  // @render

  return (
    <Container>
      {isLoading ? (
        <Lottie animationData={emptyGenerationLottieAnimation} loop={true} style={{ height: 200 }} />
      ) : (
        <>
          <img src={SentencesListIllustratorSvg} alt="Sentences Illustrator" />
          <InformationTextTop>{t('noGenerationTop')}</InformationTextTop>
          <DefaultText>{t('noGenerationBottom')}</DefaultText>
        </>
      )}
    </Container>
  );
});
