import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CustomTemplateInformationStep } from './CustomTemplateInformationStep';
import { ReactComponent as CustomTemplateIllustration } from 'assets/img/CustomTemplateIllustration.svg';
import { memo } from 'react';

export const CustomTemplateOnboarding = memo<{
  onClickContinue: () => void;
}>(({ onClickContinue }) => {
  const { t } = useTranslation('article');

  // @render

  return (
    <CustomTemplateInformationStep
      title={t('onboardingTitle')}
      ctaButtonText={t('onboardingButton')}
      onClickCTAButton={onClickContinue}
    >
      <CustomTemplateIllustration />

      <Description>{t('onboardingDescription')}</Description>
    </CustomTemplateInformationStep>
  );
});

const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.dark[70]};
  margin-top: 30px;
`;
