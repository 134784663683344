import { MouseEvent, ReactNode, forwardRef } from 'react';

import { LinkButton, NormalButton, StyledSpinner } from './styles';

type AppButtonVariant = 'default' | 'link';

type AppButtonProps = {
  text: string | ReactNode | Function;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  variant?: AppButtonVariant;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  customSpinner?: ReactNode;
};

const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>(
  ({ text, variant = 'default', loading, disabled, onClick, className, customSpinner, style }, ref) => {
    const ButtonComp = variant === 'link' ? LinkButton : NormalButton;
    return (
      <ButtonComp ref={ref} onClick={onClick} disabled={disabled || loading} className={className} style={style}>
        {loading && <StyledSpinner customSpinner={customSpinner} isThereText={Boolean(text)} />}
        {text}
      </ButtonComp>
    );
  }
);

export default AppButton;
