import { ReactComponent as EditIcon } from 'assets/img/icons/Edit.svg';
import { ReactComponent as Dots } from 'assets/img/icons/dots-x3.svg';
import { ReactComponent as DownArrow } from 'assets/img/icons/down-arrow.svg';
import { ReactComponent as TrashIcon } from 'assets/img/icons/trash_small.svg';
import { orderBy } from 'lodash';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { animated, easings, useSpring } from 'react-spring';
import { getEntities, getEntity } from 'redux/entity';
import { toggleOpenFolderId } from 'redux/folder';
import styled from 'styled-components';
import { HomeContext } from 'utils/contexts/HomeContext';
import { useSelect } from 'utils/hooks/useSelect';
import { Article } from 'utils/types';
import { Dropdown } from '../common/Dropdown';
import { Col, FlexRow, Label, Row } from '../common/Styles';
import { HomeFolderArticleListItem } from './HomeFolderArticleListItem';

export const HomeFolderAccordion = memo<{
  folderId: string;
  index: number;
  onArticleClick: (article: Article) => void;
}>(({ folderId, index, onArticleClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['home']);
  const { setSelectedFolderId, setModalType } = useContext(HomeContext);

  const openFolderIds = useSelect(({ folder }) => folder.openFolderIds);
  const folders = useSelect(({ entity }) => getEntities(entity, 'folder'));
  const folder = useSelect(({ entity }) => getEntity(entity, 'folder', folderId));
  const articles = useSelect(({ entity }) => {
    const allArticles = getEntities(entity, 'article').filter(({ folder_id }) => folder_id === folderId);
    return orderBy(allArticles, ['created_at'], ['desc']);
  });

  const [zIndex, setZIndex] = useState(index);
  const [open, setOpen] = useState(openFolderIds.includes(folderId));
  const [childDropdownOpen, setChildDropdownOpen] = useState(false);

  const openAnimation = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: open ? 1 : 0,
      maxHeight: open ? `${articles.length * 90}px` : '0px',
      display: open ? 'block' : 'none'
    },
    config: { duration: 250, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  const arrowAnimation = useSpring({
    from: { opacity: 1 },
    to: { rotate: open ? '-180deg' : '0deg' },
    config: { duration: open ? 250 : 100, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  const dropdownOptions = useMemo(() => {
    const options = [{ title: t('edit'), icon: EditIcon }];
    if (folders.length > 1) options.push({ title: t('delete'), icon: TrashIcon });
    return options;
  }, [folders.length]);

  // @effects

  useEffect(() => {
    dispatch(toggleOpenFolderId(folderId, open));
  }, [open, folderId]);

  // @handlers

  const handleToggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleArticleClick = useCallback(
    (article: Article) => {
      onArticleClick(article);
    },
    [onArticleClick]
  );

  const handleDropdownOptionClick = useCallback(
    (option: number) => {
      if (option === 0) {
        setSelectedFolderId(folderId);
        setModalType('editFolder');
      } else if (option === 1) {
        setSelectedFolderId(folderId);
        setModalType('deleteFolder');
      }
    },
    [setSelectedFolderId, folderId]
  );

  const handleDropdownToggle = useCallback((isOpen: boolean) => {
    if (isOpen) setZIndex(Number.MAX_SAFE_INTEGER);
    else setZIndex(index);
  }, []);

  const handleChildDropdownToggle = useCallback((isOpen: boolean) => {
    setChildDropdownOpen(isOpen);
    if (isOpen) setZIndex(Number.MAX_SAFE_INTEGER);
    else setZIndex(index);
  }, []);

  // @render

  return (
    <Wrapper index={zIndex}>
      <Container>
        <TitleContainer onClick={handleToggleOpen}>
          <FlexRow style={{ alignItems: 'center' }}>
            <Title style={{ marginTop: 1 }}>📁</Title>
            <Title>{folder?.name || 'Folder'}</Title>
            <Subtitle>{`(${articles.length})`}</Subtitle>

            <animated.div style={{ ...arrowAnimation, marginTop: 1 }}>
              <DownArrow />
            </animated.div>
          </FlexRow>

          <Dropdown
            alwaysOnTop
            onToggle={handleDropdownToggle}
            disabled={childDropdownOpen}
            style={{ position: 'relative', marginLeft: 12 }}
            position={{ right: 0, top: 24 }}
            options={dropdownOptions}
            handleOptionClick={handleDropdownOptionClick}
          >
            <Row style={{ padding: 12, marginRight: -6, borderRadius: 4 }}>
              <Dots style={{ width: 16 }} />
            </Row>
          </Dropdown>
        </TitleContainer>

        <animated.div style={{ ...openAnimation, width: '100%' }}>
          <ListContainer>
            {articles.map((article, itemIndex) => (
              <HomeFolderArticleListItem
                key={article.id}
                article={article}
                index={itemIndex}
                enabled={open}
                isLastItem={itemIndex === articles.length - 1}
                onDropdownToggle={handleChildDropdownToggle}
                onArticleClick={handleArticleClick}
              />
            ))}
          </ListContainer>
        </animated.div>
      </Container>
    </Wrapper>
  );
});

const Wrapper = styled(Col)<{ index: number }>`
  background-color: white;
  padding-bottom: 8px;
  z-index: ${({ index }) => index};
`;

const Container = styled(animated.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
`;

const ListContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled(Row)`
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  padding: 10px 20px;
  width: 100%;
  border-radius: 8px;
  min-height: 62px;
`;

const Title = styled(Label)`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-right: 6px;
`;

const Subtitle = styled(Label)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 6px;
  opacity: 0.3;
  margin-left: -1px;
  margin-right: 10px;
`;
