import { ApiTarget } from './api';

export const log = (title: string, info: string[]) => {
  if (process.env.NODE_ENV === 'development' && false) {
    console.info(`\n----- ${title} -----\n\n${info.join('\n')}\n\n`);
  }
};

export const reportRequest = (statusCode: number, target: ApiTarget) => {
  return `\n----- ${'Returned status code: ' + statusCode} -----\n\n${[
    `URL: ${process.env.REACT_APP_BACKEND_URL + target.path}`,
    `Method: ${target.method}`,
    `Params: ${JSON.stringify(target.params ?? {}, null, 2)}`
  ].join('\n')}\n\n`;
};

export const logRequest = (target: ApiTarget) => {
  log('Api Request', [
    `URL: ${process.env.REACT_APP_BACKEND_URL + target.path}`,
    `Method: ${target.method}`,
    `Params: ${JSON.stringify(target.params ?? {}, null, 2)}`
  ]);
};

export const logResponse = (target: ApiTarget, data?: Record<string, any>, status?: number) => {
  log('Api Response', [
    `URL: ${process.env.REACT_APP_BACKEND_URL + target.path}`,
    `Method: ${target.method}`,
    `Status: ${status}`,
    `Params: ${JSON.stringify(target.params ?? {}, null, 2)}`,
    `Data: ${JSON.stringify(data, null, 2).slice(0, 1000)}`
  ]);
};
