import { Portal } from 'react-portal';
import { Overlay } from './styles';

type AppModalProps = {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
};
function AppModal({ visible, onClose, children }: AppModalProps) {
  if (!visible) return null;
  return (
    <Portal>
      <Overlay onClick={onClose}>
        <div onClick={(e) => e.stopPropagation()}>{children}</div>
      </Overlay>
    </Portal>
  );
}

export default AppModal;
