import { ArticleGenerationTypeSelect } from 'features/article/input/ArticleGenerationTypeSelect';
import AppButton from 'features/common/AppButton';
import { Row } from 'features/common/Styles';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setArticleGenerationType, setArticleInputType } from 'redux/editor';
import styled, { CSSProperties } from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { ArticleFormType } from 'utils/types';
import { ArticleContext } from 'utils/contexts/ArticleContext';

export const ArticleInputHeader = memo<{ style?: CSSProperties }>(({ style }) => {
  const { t } = useTranslation('article');
  const dispatch = useDispatch();
  const { articleId } = useContext(ArticleContext);

  const inputType = useSelect(({ editor }) => editor.inputType);

  const buttonText = useMemo(() => {
    return inputType === 'form' ? t('goToEditor') : t('goToInput');
  }, []);

  // @handlers

  const handleGenerationTypeChange = useCallback(
    (generationType: ArticleFormType) => {
      dispatch(setArticleGenerationType(articleId, generationType));
    },
    [dispatch, articleId]
  );

  const handleToggleInputType = useCallback(() => {
    dispatch(setArticleInputType(inputType === 'form' ? 'editor' : 'form'));
  }, [dispatch, inputType]);

  // @render

  return (
    <Row style={{ justifyContent: 'space-between', ...style }}>
      <ArticleGenerationTypeSelect articleId={articleId} onGenerationTypeChange={handleGenerationTypeChange} />
      <CreatorTypeButton text={buttonText} onClick={handleToggleInputType} />
    </Row>
  );
});

const CreatorTypeButton = styled(AppButton)`
  height: 32px;
  padding: 7px 26px;
  color: ${({ theme }) => theme.colors.primary[40]};
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.soft[20]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border[30]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.soft[20]};
  }
`;
