import AppButton from 'features/common/AppButton';
import { AppInput } from 'features/common/AppInput';
import AppPopUp from 'features/common/AppPopUp';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCheckResetPasswordTokenMutation } from 'redux/auth/api';
import { useResetPasswordMutation } from 'redux/user/api';
import styled from 'styled-components';

export const ResetPassword = () => {
  const { t } = useTranslation('account');
  const history = useHistory();
  const params = useParams<{ token: string }>();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [invalidToken, setInvalid] = useState(false);

  const [checkResetPasswordApi, { isError: isErrorCheckToken }] = useCheckResetPasswordTokenMutation();
  const [resetPasswordApi, { isSuccess: resetSuccess }] = useResetPasswordMutation();
  const checkToken = async () => {
    const response = await checkResetPasswordApi(params.token).unwrap();
    if (!response.success) setInvalid(true);
  };

  // @effects

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInvalid(isErrorCheckToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorCheckToken]);

  const onSave = async () => {
    if (password !== confirmPassword) {
      toast.error(t('passwordsDoNotMatch'));
      return;
    }

    const requestBody = { token: params.token, password: password, confirmPassword: confirmPassword };

    await resetPasswordApi(requestBody);
  };

  // @render

  return (
    <Container>
      <Title>{t('resetPassword')}</Title>
      <AppInput value={password} onChange={setPassword} placeholder={t('newPassword')} type="password" />
      <AppInput
        value={confirmPassword}
        onChange={setConfirmPassword}
        placeholder={t('confirmPassword')}
        type="password"
      />
      <SaveButton text={t('save')} onClick={onSave} />

      <AppPopUp open={invalidToken || resetSuccess}>
        <PopUpContainer>
          {invalidToken && t('invalidCode')}
          {resetSuccess && t('successfullyResetPassword')}
          <GoToHomeButton
            text={t('Go to home')}
            onClick={() => {
              history.replace('/');
            }}
          />
        </PopUpContainer>
      </AppPopUp>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.purple[50]};
  flex: 10;
  padding: 40px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  & :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Title = styled.p`
  font-family: Nunito Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 48px !important;
`;

const SaveButton = styled(AppButton)`
  margin-top: 48px !important;
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 8px;
  gap: 10px;
  width: 200px;
  height: 40px;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
`;

const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 40px 70px;
  border-radius: 8px;
`;

const GoToHomeButton = styled(AppButton)`
  margin-top: 48px !important;
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 8px;
  gap: 10px;
  width: 200px;
  height: 40px;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
`;
