import {
  BYTE_SIZED_TEMPLATE_NAME,
  MAX_TOKEN_LENGTH,
  SUGGESTIONS_TEMPLATE_NAME,
  SUMMARIZE_TEMPLATE_NAME
} from 'config/constants';
import AppButton from 'features/common/AppButton';
import AppPopUp from 'features/common/AppPopUp';
import { memo, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { regenerateText } from 'redux/article';
import { getEntity } from 'redux/entity';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { useEscapeKeydown } from 'utils/hooks/useKeydown';
import { useQuery } from 'utils/hooks/useQuery';
import { useSelect } from 'utils/hooks/useSelect';
import { Similarity, TextLengthOption } from 'utils/types';

export const ArticleEditorProofread = memo(() => {
  const { t } = useTranslation('article');
  const dispatch = useDispatch();

  const { articleId, modalType, setModalType, editorText } = useContext(ArticleContext);

  const similarities = useSelect(({ article }) => article.similarities[articleId] ?? []);
  const settings = useSelect(({ editor }) => editor.textGenerationSettings);
  const template = useSelect(({ editor, entity }) => {
    const templateId = editor.selectedImageTemplateId[articleId];
    return getEntity(entity, 'template', templateId);
  });

  const tabId = useSelect(({ editor }) => editor.selectedTabId[articleId] ?? '');
  const article = useSelect(({ entity }) => getEntity(entity, 'article', articleId));
  const { isSuccess, isFailure } = useQuery('regenerate_text' + articleId);
  const sortedSimilarities = similarities && [...similarities]?.sort((a, b) => b.score - a.score);

  // @effects

  useEffect(() => {
    if (isSuccess) toast.success(t('regenerateSuccessInfo'));
    if (isFailure) toast.error(t('regenerateErrorInfo'));
  }, [isSuccess, isFailure]);

  useEscapeKeydown(() => {
    if (modalType === 'proofRead') setModalType(null);
  }, [modalType]);

  // @handlers

  const handleRegenerate = useCallback(
    (text: string) => () => {
      let tokens = MAX_TOKEN_LENGTH[(settings.textLength || 'short') as TextLengthOption];
      if (template?.name === BYTE_SIZED_TEMPLATE_NAME) tokens = 40;
      else if (template?.name === SUMMARIZE_TEMPLATE_NAME) tokens = tokens / 2;
      else if (template?.name === SUGGESTIONS_TEMPLATE_NAME) tokens = 175;

      const { language: lang, output_language: outLang } = settings;
      dispatch(regenerateText(lang, tokens, outLang, editorText, text, articleId, tabId));
    },
    [settings, template, editorText, article?.id, tabId]
  );

  // @render

  return (
    <AppPopUp open={modalType === 'proofRead'} onClose={() => setModalType(null)}>
      <Container>
        {sortedSimilarities?.map((item: Similarity, index: number) => (
          <Item key={index}>
            <RowContainer>
              <MatchedTag>{`${t('you')}:`}</MatchedTag>
              <MatchedText matched={item.exact_match}>
                {editorText?.slice(item.start, item.end)}
                <ButtonContainer>
                  <Button
                    onClick={handleRegenerate(editorText?.slice(item.start, item.end))}
                    text={t('againWrite')}
                    matched={item.exact_match}
                  />
                </ButtonContainer>
              </MatchedText>
            </RowContainer>

            <RowContainer>
              <PercentageTag>{`%${Math.floor(item.score)}`}</PercentageTag> <OtherText>{item.sentence}</OtherText>
            </RowContainer>
          </Item>
        ))}
      </Container>
    </AppPopUp>
  );
});

const Container = styled.div`
  min-width: 600px;
  height: 400px;
  overflow-y: auto;
  gap: 24px;
  box-shadow: ${({ theme }) => theme.shadows[1]};

  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.dark[50]};
  padding: 32px;
  border-radius: 8px;
`;

const Item = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[60]};
`;

const RowContainer = styled.span`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
`;

const MatchedText = styled.span<{ matched: boolean }>`
  width: 500px;
  background: ${({ matched, theme }) => (matched ? theme.colors.red[10] : theme.colors.yellow[10])};
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.dark[100]};
  padding: 12px 16px;
  border-radius: 4px;
`;

const Tag = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;
  white-space: nowrap;

  font-family: Nunito Sans;
  background: ${({ theme }) => theme.colors.gray[60]};
  border-radius: 4px;

  margin-right: 8px;
  letter-spacing: 0em;
  text-align: center;
  font-weight: 600;
`;

const MatchedTag = styled(Tag)`
  font-size: 14px;
  line-height: 19px;
`;

const PercentageTag = styled(Tag)`
  font-size: 12px;
  line-height: 16px;
`;

const OtherText = styled.span`
  width: 500px;
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  color: ${({ theme }) => theme.colors.black};
  padding: 12px 16px;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: 8px;

  margin-top: 16px;

  & > :last-child {
    margin-left: 16px;
  }
`;

const Button = styled(AppButton)<{ matched: boolean }>`
  background: ${({ theme, matched }) => (matched ? '#F4D1D1' : '#F3F0D0')};
  height: 32px;
  border: 1px solid ${({ theme, matched }) => (matched ? '#E7B6B6' : '#E2DEAD')};
  color: ${({ theme, matched }) => (matched ? '#8E1111' : '#605A24')};

  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  &:hover {
    background: ${({ theme, matched }) => (matched ? '#E7B6B6' : '#E2DEAD')};
    color: ${({ theme, matched }) => (matched ? '#8E1111' : '#605A24')};
  }

  &:active {
    opacity: 0.8;
  }
`;
