import AlarmClock from 'assets/img/icons/AlarmClock.png';
import ChartIncreasing from 'assets/img/icons/ChartIncreasing.png';
import CrystalBall from 'assets/img/icons/CrystalBall.png';
import Dna from 'assets/img/icons/Dna.png';
import Gear from 'assets/img/icons/Gear.png';
import GlobeWithMeridians from 'assets/img/icons/GlobeWithMeridians.png';
import PersonRunning from 'assets/img/icons/PersonRunning.png';
import PersonSurfing from 'assets/img/icons/PersonSurfing.png';
import Sheets from 'assets/img/icons/Sheets.png';
import SpeechBalloon from 'assets/img/icons/SpeechBalloon.png';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CustomTemplateInformationCard, ItemTitleIcon } from './CustomTemplateInformationCard';
import { CustomTemplateInformationStep } from './CustomTemplateInformationStep';
import { memo } from 'react';

const InstructionsIcons = {
  1: <ItemTitleIcon src={PersonRunning} alt="person-running" />,
  2: <ItemTitleIcon src={Sheets} alt="sheets" />,
  3: <ItemTitleIcon src={Gear} alt="gear" />,
  4: <ItemTitleIcon src={AlarmClock} alt="alarm-clock" />,
  5: <ItemTitleIcon src={SpeechBalloon} alt="speech-balloon" />,
  6: <ItemTitleIcon src={CrystalBall} alt="crystal-balloon" />,
  7: <ItemTitleIcon src={PersonSurfing} alt="person-surfing" />,
  8: <ItemTitleIcon src={ChartIncreasing} alt="chart-increasing" />,
  9: <ItemTitleIcon src={Dna} alt="dna" />,
  10: <ItemTitleIcon src={GlobeWithMeridians} alt="globe-with-meridians" />
};

export const CustomTemplateInstructions = memo<{ onClickContinue: () => void }>(({ onClickContinue }) => {
  const { t } = useTranslation('article');

  const getNextButtonText = () => {
    return (
      <ButtonTextContainer>
        <ButtonText>{t('instructionButton')}</ButtonText>
        <ItemTitleIcon src={PersonSurfing} alt="person-surfing" />
      </ButtonTextContainer>
    );
  };

  return (
    <CustomTemplateInformationStep
      title={t('instructionsTitle')}
      ctaButtonText={getNextButtonText()}
      onClickCTAButton={onClickContinue}
      blurButton
    >
      <Container>
        {Array.from({ length: 10 }, (_, index) => index + 1).map((index) => (
          <CustomTemplateInformationCard
            key={index}
            title={t(`instructionsItemTitles.${index}`)}
            description={t(`instructionsItemDescriptions.${index}`)}
            icon={InstructionsIcons[index as keyof typeof InstructionsIcons]}
          />
        ))}

        <DummyCard />
      </Container>
    </CustomTemplateInformationStep>
  );
});

const Container = styled.div`
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DummyCard = styled.div`
  width: 100%;
  height: 200px;
  flex-shrink: 0;
`;

const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.primary};
`;
