import styled from 'styled-components';

export const ArticleTextInputField: React.FC<{
  id: string;
  title: string;
  label: string;
  required?: boolean;
  value?: string;
  [key: string]: any;
}> = ({ id, title, label, required, value, multiline, ...rest }) => (
  <Container>
    {title && <Title>{title}</Title>}
    {label && title !== label && <Label htmlFor={title}>{label}</Label>}
    <FormTextInput
      id={id}
      name={title}
      value={value}
      required={required}
      multiline={multiline}
      placeholder={label}
      {...rest}
    />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Title = styled.h3`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.dark[100]};
`;

const Label = styled.label`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
  line-height: 19px;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.colors.dark[70]};
`;

const FormTextInput = styled.textarea<{ multiline?: boolean }>`
  background-color: #fafbfd;
  border: 1px solid ${({ theme }) => theme.colors.soft[70]};
  border-radius: 4px;
  resize: none;
  height: ${({ multiline }) => (multiline ? '90px' : '40px')};
  outline: none;
  padding: 10px 14px;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary[40]};
  }
`;
