import AppButton from 'features/common/AppButton';
import { cloneDeep } from 'lodash';
import { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { useDispatch } from 'react-redux';
import { updateGeneration } from 'redux/article';
import { getEntity } from 'redux/entity';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { useSelect } from 'utils/hooks/useSelect';
import { AddedFact, ButtonFactCheck, FactMatch } from 'utils/types';

export const ArticleGenerationFactModal = memo<{
  isOpen: boolean;
  onClose: () => void;
  facts: FactMatch[];
  buttonFact?: ButtonFactCheck;
  className?: string;
  objectivePositionY: number;
  generationId: string;
  suggestionIndex: number;
  objective: string;
  activeObjectiveIndex: number;
}>(
  ({
    isOpen,
    onClose,
    className,
    objectivePositionY,
    facts,
    buttonFact,
    suggestionIndex,
    generationId,
    objective,
    activeObjectiveIndex
  }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('article');
    const { articleId } = useContext(ArticleContext);

    const article = useSelect(({ entity }) => getEntity(entity, 'article', articleId));
    const generation = useSelect((state) => {
      const generations = state.article.textGenerations[articleId] ?? [];
      return generations.find(({ id }) => id === generationId);
    });

    // @handlers

    const handleAddMediaClick = useCallback(
      (content: string, objectiveIndex: number, domain?: string) => () => {
        const fact: AddedFact = { content, generationId, suggestionIndex, objectiveIndex, domain };
        if (!article || !generation) return;

        const targetGeneration = generation.generations[suggestionIndex];
        const targetObjective = targetGeneration.statement_objectives[objectiveIndex];
        const position = targetGeneration.content.indexOf(targetObjective) + targetObjective.length;

        const prefix = targetGeneration.content.slice(0, position);
        const suffix = targetGeneration.content.slice(position);
        const updatedContent = prefix + ' ' + content + suffix;

        const generations = cloneDeep(generation.generations);
        generations[suggestionIndex].content = updatedContent;

        dispatch(updateGeneration(generationId, generations, fact, articleId));
        onClose();
      },
      [onClose, generationId, suggestionIndex, article, generation, articleId]
    );

    // @render

    if (!isOpen) return null;

    return (
      <>
        <Portal>
          <Overlay onClick={onClose} />
        </Portal>

        <Container className={className} objectivePositionY={objectivePositionY}>
          {buttonFact?.[objective]
            ? buttonFact[objective].map((item, index) => (
                <FactCardsContainer key={`fact-${activeObjectiveIndex}-${index}`}>
                  <FactText>
                    {item.rewritten}
                    <SourceText as="a" href={item.link} target="_blank" rel="noopener noreferrer">
                      {t('showContentSource')}
                    </SourceText>
                  </FactText>

                  <ButtonContainer>
                    <FactModalButton
                      text={t('addWithLink')}
                      onClick={handleAddMediaClick(item.rewritten, activeObjectiveIndex, item.link)}
                    />
                    <FactModalButton
                      text={t('add')}
                      onClick={handleAddMediaClick(item.rewritten, activeObjectiveIndex)}
                    />
                  </ButtonContainer>
                </FactCardsContainer>
              ))
            : facts.map((item, index) => (
                <FactCardsContainer key={`fact-${item.objective_index}-${index}`}>
                  <FactText>
                    {item.content}
                    <SourceText as="a" href={item.source_domain} target="_blank" rel="noopener noreferrer">
                      {t('showContentSource')}
                    </SourceText>
                  </FactText>

                  <ButtonContainer>
                    <FactModalButton
                      text="Add with link"
                      onClick={handleAddMediaClick(item.content, item.objective_index, item.source_domain)}
                    />
                    <FactModalButton text="Add" onClick={handleAddMediaClick(item.content, item.objective_index)} />
                  </ButtonContainer>
                </FactCardsContainer>
              ))}
        </Container>
      </>
    );
  }
);

const Container = styled.div<{ objectivePositionY: number }>`
  z-index: 1000;
  position: absolute;
  top: ${({ objectivePositionY }) => objectivePositionY - 100 ?? 0}px;
  left: 24px;
  right: 24px;
  max-height: 380px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.soft[30]};
  border: 1px solid ${({ theme }) => theme.colors.purple[30]};
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 16px 33px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const FactCardsContainer = styled.div`
  padding: 16px 0px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[70]};
  }
`;

const FactText = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.dark[80]};
  font-size: 12px;
  line-height: 24px;
`;

const SourceText = styled(FactText)`
  color: ${({ theme }) => theme.colors.primary[40]};
  text-decoration: none;
  margin-left: 6px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;

  & > button:not(:last-child) {
    margin-right: 8px;
  }
`;

const FactModalButton = styled(AppButton)`
  height: 32px;
  background-color: ${({ theme }) => theme.colors.gray[20]};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  padding: 4px 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[30]};
  }
`;
