import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { store } from 'redux/store';
import { Authorization } from 'utils/types';
import { auth } from '../Firebase';
import { login } from './auth/slice';

type BaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;
const baseUrl = process.env.REACT_APP_BACKEND_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const storedAuth = localStorage.getItem('auth');
    if (storedAuth) {
      const authorization: Authorization = JSON.parse(storedAuth);
      headers.set('Authorization', `Bearer ${authorization?.token}`);
      headers.set('UserID', authorization?.userID);
    }
    return headers;
  }
});

const baseQueryWithReAuth: BaseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // @ts-ignore
  const status = result.error?.originalStatus || result.error?.status || -1;

  if (status === 401) {
    if (!auth.currentUser) {
      // TODO logout
      return result;
    }

    const token = await auth.currentUser.getIdToken();
    const { uid: userID } = auth.currentUser;
    const authorization: Authorization = { token, userID };
    localStorage.setItem('auth', JSON.stringify(authorization));
    store.dispatch(login(authorization));

    result = await baseQuery(args, api, extraOptions);
  }
  return result;
};

export default createApi({
  tagTypes: ['Articles', 'TextGenerations', 'ImageGenerations', 'EditorState', 'Bookmarks', 'UserPlan'],
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({})
});
