import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  padding: 0 12px;
`;

export const DropdownHandleImg = styled.img<{
  isDropdownOpen: boolean;
}>`
  transform: scaleY(${({ isDropdownOpen }) => (isDropdownOpen ? -1 : 1)});
  color: rgba(250, 251, 253, 0.5);
`;
