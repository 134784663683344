import { ChangeEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const CustomTemplateInput = memo<{
  id?: string;
  label?: string;
  description?: string;
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  required?: boolean;
  className?: string;
  multiline?: boolean;
  error?: boolean;
}>(({ id, label, description, value, onChange, placeholder, required, className, multiline, error }) => {
  const { t } = useTranslation('article');

  return (
    <Container className={className}>
      {label && (
        <Label id={id}>
          {label}
          {required ? '*' : ''}
        </Label>
      )}
      {description && <Description>{description}</Description>}

      <TextInput
        as={multiline ? 'textarea' : 'input'}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
        placeholder={placeholder}
        error={error}
      />

      {required && <BottomInfo error={error}>{t('customTemplateFieldRequired')}</BottomInfo>}
    </Container>
  );
});

//#region Styles

const Container = styled.div`
  margin-bottom: 6px;
`;

const Label = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.primary[30]};

  margin-bottom: 6px;
`;

const Description = styled(Label)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.dark[100]};
`;

const TextInput = styled.input<{ error?: boolean }>`
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.red[100] : theme.colors.soft[40])};
  border-radius: 4px;
  padding: 10px;
  padding-left: 12px;

  width: 100%;

  ${({ value }) => (!value ? 'font-style: italic;' : '')};
  color: ${({ theme, value }) => ((value ?? '').toString().length === 0 ? '#CDD1DE' : theme.colors.black)};
`;

const BottomInfo = styled.p<{ error?: boolean }>`
  text-align: end;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme, error }) => (error ? theme.colors.red[100] : '#CDD1DE')};

  margin-top: 6px;
`;

//#endregion
