import useURLQuery from 'utils/hooks/useURLQuery';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useValidateUpgradePlanCheckoutSessionMutation } from 'redux/auth/api';
import { AccountPageContainer } from './AccountPageContainer';
import styled from 'styled-components';

function UpgradePlanResult() {
  const history = useHistory();
  const urlQuery = useURLQuery();

  const [validateSessionApi] = useValidateUpgradePlanCheckoutSessionMutation();

  const checkSession = useCallback(async () => {
    try {
      const sessionId = urlQuery.get('session_id');
      if (!sessionId) throw new Error('No session id');

      await validateSessionApi(sessionId).unwrap();
    } catch (error) {
      console.log('🐞 ~ error', error);
    }

    history.replace('/');
  }, [validateSessionApi, urlQuery, history]);

  useEffect(() => {
    checkSession();
  }, [checkSession]);

  return (
    <AccountPageContainer activeStep="/upgrade-plan">
      <Container>Loading...</Container>
    </AccountPageContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
`;

export default UpgradePlanResult;
