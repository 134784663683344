import AppButton from 'features/common/AppButton';
import AppPopUp from 'features/common/AppPopUp';
import AppSelectbox from 'features/common/AppSelectbox';
import { Label } from 'features/common/Styles';
import { memo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { updateArticle } from 'redux/article';
import { getEntities, getEntity } from 'redux/entity';
import { useSelect } from 'utils/hooks/useSelect';
import styled from 'styled-components';
import { HomeContext } from 'utils/contexts/HomeContext';
import { useEnterKeydown, useEscapeKeydown } from 'utils/hooks/useKeydown';

export const MoveArticleModal = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['home', 'common']);

  const { selectedArticleId, modalType, setModalType } = useContext(HomeContext);
  const article = useSelect(({ entity }) => getEntity(entity, 'article', selectedArticleId));
  const folders = useSelect(({ entity }) => getEntities(entity, 'folder'));

  const currentFolder = folders.find((folder) => folder.id === article?.folder_id);
  const [folderId, setFolderId] = useState<string | null>(article?.folder_id || null);

  // @effects

  useEscapeKeydown(() => {
    if (modalType === 'moveArticle') setModalType(null);
  }, [modalType]);

  useEnterKeydown(() => {
    if (modalType === 'moveArticle') handleMoveClick();
  }, [currentFolder, article, folderId, modalType]);

  // @handlers

  const handleMoveClick = useCallback(() => {
    if (!folderId) return toast.info(t('notEmptyfolderId'));
    if (article) dispatch(updateArticle(article.id, { folder_id: folderId }));
    setModalType(null);
  }, [article, selectedArticleId, folderId]);

  const handleChangeFolder = useCallback((newValues) => {
    setFolderId(newValues[0].value);
  }, []);

  // @render

  return (
    <AppPopUp open={modalType === 'moveArticle'} onClose={() => setModalType(null)}>
      <Container>
        <Title>{`📄 ${t('moveProject')}`}</Title>
        <Info>{t('selectFolderTo')}</Info>

        <SelectBox
          options={folders.map(({ id, name }) => ({ label: name, value: id }))}
          defaultValue={currentFolder ? { label: currentFolder.name, value: currentFolder.id } : undefined}
          placeholder={t('folder')}
          onChange={handleChangeFolder}
        />
        <SaveButton text={t('move')} onClick={handleMoveClick} />
      </Container>
    </AppPopUp>
  );
});

const Info = styled.span`
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 8px;
  width: 360px;
  color: ${(props) => props.theme.colors.dark[70]};
`;

const Container = styled.div`
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const Title = styled(Label)`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SaveButton = styled(AppButton)`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 20px;
  background: ${({ theme }) => theme.colors.dark[100]};
  border-radius: 4px;
`;

const SelectBox = styled(AppSelectbox)`
  & > div {
    border: 1px solid ${({ theme }) => theme.colors.gray[60]};
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px 4px;

    & > div {
      background: transparent !important;
      border: none !important;

      & > :not(:nth-child(1), :nth-child(2)) {
        border-radius: 4px;
        border: rgba(255, 255, 255, 0.08) 1px solid;
        padding: 4px 0px;
      }

      & > div > span {
        color: ${({ theme }) => theme.colors.black};
        border: none;
        font-family: Nunito Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        padding: 12px 16px;
        width: 100%;
      }

      & > div > p {
        color: ${({ theme }) => theme.colors.dark[100]} !important;
        font-family: Nunito Sans;
        font-size: 14px;
        font-weight: 700;
      }

      & > div > div > * {
        color: ${({ theme }) => theme.colors.dark[70]} !important;
      }
    }
  }
`;
