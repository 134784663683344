import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { memo } from 'react';
import styled from 'styled-components';

export const AppSliderInput = memo<{
  defaultValue: number;
  onChange: (newValue: number | number[]) => void;
  min?: number;
  max?: number;
  step?: number;
}>(({ defaultValue, onChange, min, max, step = 1 }) => {
  return <StyledSlider defaultValue={defaultValue} onChange={onChange} min={min} max={max} step={step} />;
});

export const StyledSlider = styled(Slider).attrs(({ theme }) => ({
  trackStyle: { backgroundColor: 'rgba(255,255,255,0.8)' },
  railStyle: { backgroundColor: 'rgba(255,255,255,0.08)', cursor: 'pointer' },
  handleStyle: {
    width: 14,
    height: 14,
    backgroundColor: 'rgba(255, 255, 255,1)',
    border: `2px solid ${theme.colors.white}`,
    opacity: 1
  }
}))`
  & > .rc-slider-handle-dragging {
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.primary[30]} !important;
  }
`;
