import { RawDraftContentState } from 'draft-js';
import produce from 'immer';
import { cloneDeep, flatten, map, uniqBy } from 'lodash';
import { toast } from 'react-toastify';
import { Action, Thunk } from 'redux/store';
import {
  BYTE_SIZED_TEMPLATE_NAME,
  MAX_TOKEN_LENGTH,
  SUGGESTIONS_TEMPLATE_NAME,
  SUMMARIZE_TEMPLATE_NAME,
  VENTI_ARTICLE_TEMPLATE_NAME
} from '../config/constants';
import { apiService } from '../service/service';
import {
  AIImageGeneration,
  AITextGeneration,
  AddedFact,
  Article,
  ArticleImageGenerationSettings,
  ArticleTextGenerationSettings,
  Bookmark,
  LanguageOption,
  Plagiarism,
  Similarity,
  Template
} from '../utils/types';
import {
  DeleteAction,
  MergeAction,
  ReplaceAction,
  deleteEntities,
  getEntity,
  mergeEntities,
  replaceEntities
} from './entity';
import { createTab } from './tab';

type State = {
  textGenerations: Record<string, AITextGeneration[]>;
  imageGenerations: Record<string, AIImageGeneration[]>;
  combinedFacts: Record<string, AddedFact[]>;
  similarities: Record<string, Similarity[]>;
  bookmarks: Record<string, Bookmark[]>;
  plagiarism: Record<string, Plagiarism | null>;
};

export const initialState: State = {
  textGenerations: {},
  imageGenerations: {},
  combinedFacts: {},
  similarities: {},
  bookmarks: {},
  plagiarism: {}
};

type ActionType =
  | { type: 'article/setTextGenerations'; generations: AITextGeneration[]; articleId: string }
  | { type: 'article/setImageGenerations'; generations: AIImageGeneration[]; articleId: string }
  | { type: 'article/setSimilarities'; similarities: Similarity[]; articleId: string }
  | { type: 'article/setCombinedFacts'; combinedFacts: AddedFact[]; articleId: string }
  | { type: 'article/setBookmarks'; bookmarks: Bookmark[]; articleId: string }
  | { type: 'article/setPlagiarism'; plagiarism: Plagiarism | null; articleId: string }
  | MergeAction
  | ReplaceAction
  | DeleteAction
  | Action<'me/logout'>;

export const articleReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'article/setTextGenerations':
      return produce(state, (draft) => {
        draft.textGenerations[action.articleId] = action.generations;
      });

    case 'article/setImageGenerations':
      return produce(state, (draft) => {
        draft.imageGenerations[action.articleId] = action.generations;
      });

    case 'article/setSimilarities':
      return produce(state, (draft) => {
        draft.similarities[action.articleId] = action.similarities;
      });

    case 'article/setCombinedFacts':
      return produce(state, (draft) => {
        draft.combinedFacts[action.articleId] = action.combinedFacts;
      });

    case 'article/setBookmarks':
      return produce(state, (draft) => {
        draft.bookmarks[action.articleId] = action.bookmarks;
      });

    case 'article/setPlagiarism':
      return produce(state, (draft) => {
        draft.plagiarism[action.articleId] = action.plagiarism;
      });

    case 'me/logout':
      return initialState;

    default:
      return state;
  }
};

// @actions

export const fetchArticle =
  (articleId: string): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .fetchArticle(articleId)
      .then(({ article, folder, tabs }) =>
        dispatch(mergeEntities({ article: [article], folder: folder ? [folder] : [], tab: tabs }))
      )
      .observe('fetch_article_' + articleId, dispatch)
      .catchError(dispatch);

export const fetchArticles = (): Thunk<ActionType> => (dispatch) =>
  apiService
    .fetchArticles()
    .then((items) => {
      const articles = flatten(map(items, 'articles'));
      const folders = map(items, 'folder');
      dispatch(replaceEntities({ article: articles, folder: folders }));
    })
    .observe('fetch_articles', dispatch)
    .catchError(dispatch);

export const createArticle =
  (id: string, folderId: string, name: string, isDraft: boolean): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .createArticle(id, folderId, name, isDraft)
      .first(() => dispatch(createTab(id, id, 'Tab 1', -1)))
      .then(({ article, folder, tab }) => dispatch(mergeEntities({ article: [article], folder: [folder], tab: [tab] })))
      .observe('create_article', dispatch)
      .catchError(dispatch);

export const updateArticle =
  (articleId: string, payload: Partial<Article>): Thunk<ActionType> =>
  (dispatch, getState) =>
    apiService
      .updateArticle(articleId, payload)
      .first(() => {
        let article = getEntity(getState().entity, 'article', articleId);
        if (article) dispatch(mergeEntities({ article: [{ ...article, ...payload }] }));
      })
      .then(({ article }) => dispatch(mergeEntities({ article: [article] })))
      .observe('update_article', dispatch)
      .catchError(dispatch);

export const saveArticle =
  (articleId: string): Thunk<ActionType> =>
  (dispatch, getState) => {
    const article = getEntity(getState().entity, 'article', articleId);
    if (article) dispatch(updateArticle(articleId, { ...article }));
  };

export const deleteArticle =
  (articleId: string): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .deleteArticle(articleId)
      .first(() => dispatch(deleteEntities({ article: [articleId] })))
      .observe('delete_article', dispatch)
      .catchError(dispatch);

export const fetchTemplates = (): Thunk<ActionType> => (dispatch) =>
  Promise.all([apiService.fetchTemplates(), apiService.fetchAITemplates(), apiService.fetchImageTemplates()])
    .then(([textTemplates, { templates: aiTemplates }, imageTemplates]) => {
      const templates = textTemplates.templates.concat(imageTemplates.templates);
      const textRecord = textTemplates.userTemplateRecord?.template_data ?? [];
      const imageRecord = imageTemplates.userTemplateRecord?.template_data ?? [];
      const templateMeta = textRecord.concat(imageRecord);
      dispatch(mergeEntities({ template: templates, templateMeta, aiTemplate: aiTemplates }));
    })
    .observe('fetch_templates', dispatch)
    .catchError(dispatch);

export const fetchGenerations =
  (articleId: string): Thunk<ActionType> =>
  (dispatch) =>
    Promise.all([
      apiService.fetchTextGenerations(articleId),
      apiService.fetchImageGenerations(articleId),
      apiService.fetchBookmarks(articleId)
    ])
      .then(([textGenerations, imageGenerations, bookmarks]) => {
        dispatch({ type: 'article/setTextGenerations', generations: textGenerations, articleId });
        dispatch({ type: 'article/setImageGenerations', generations: imageGenerations, articleId });
        dispatch(replaceEntities({ bookmark: bookmarks }));
      })
      .observe('fetch_generations', dispatch)
      .catchError(dispatch);

export const generateText =
  (template: Template, article: Article, settings: ArticleTextGenerationSettings, tabId: string): Thunk<ActionType> =>
  (dispatch, getState) => {
    const userId = getState().me.user?.id;

    let textSettings = cloneDeep(settings);
    let maxTokens = MAX_TOKEN_LENGTH[settings.textLength || 'short'];
    let articleId = article.id;
    let allText = article.paragraph_prompt ?? '';
    let content = article.text_prompt[template.id] ?? '';
    let prompt: string[] = [''];

    switch (template.name) {
      case VENTI_ARTICLE_TEMPLATE_NAME:
        prompt = typeof content === 'string' ? [content] : [''];
        break;

      case BYTE_SIZED_TEMPLATE_NAME:
        maxTokens = 40;
        break;

      case SUMMARIZE_TEMPLATE_NAME:
        maxTokens = maxTokens / 2;
        break;

      case SUGGESTIONS_TEMPLATE_NAME:
        maxTokens = 175;
        textSettings.temperature = 0.7;
        textSettings.frequency_penalty = 0.2;
        textSettings.presence_penalty = 0.2;
        textSettings.session_id = `${userId}-${article.id}`;
        break;
    }

    return apiService
      .createTextGeneration(template.endpoint, textSettings, content, maxTokens, article.id, tabId, allText, prompt)
      .then((generation) => {
        let generations = getState().article.textGenerations[articleId] ?? [];
        generations = generations.concat(generation);
        dispatch({ type: 'article/setTextGenerations', generations, articleId });
      })
      .observe('generate_text', dispatch)
      .catchError(dispatch);
  };

export const generateImage =
  (template: Template, article: Article, settings: ArticleImageGenerationSettings, tabId: string): Thunk<ActionType> =>
  (dispatch, getState) => {
    const content = article.image_prompt[template.id] ?? '';
    const articleId = article.id;

    apiService
      .createImageGeneration(template.endpoint, settings, content, articleId, tabId)
      .then((generation) => {
        let generations = getState().article.imageGenerations[articleId] ?? [];
        generations = generations.concat(generation);
        dispatch({ type: 'article/setImageGenerations', generations, articleId });
      })
      .observe('generate_image', dispatch)
      .catchError(dispatch);
  };

export const updateGeneration =
  (
    generationId: string,
    generations: AITextGeneration['generations'],
    fact: AddedFact,
    articleId: string
  ): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .updateGeneration(generationId, generations)
      .first(() => dispatch({ type: 'article/setCombinedFacts', combinedFacts: [fact], articleId }))
      .observe('update_generation', dispatch)
      .catchError(dispatch);

export const regenerateText =
  (
    language: LanguageOption,
    maxTokens: number,
    outputLanguage: LanguageOption,
    allText: string,
    focusText: string,
    articleId: string,
    tabId: string
  ): Thunk<ActionType> =>
  (dispatch, getState) =>
    apiService
      .regenerate(language, maxTokens, outputLanguage, allText, focusText, articleId, tabId)
      .then((generation) => {
        let generations = getState().article.textGenerations[articleId] ?? [];
        generations = generations.concat(generation);
        dispatch({ type: 'article/setTextGenerations', generations, articleId });
      })
      .observe('regenerate_text' + articleId, dispatch)
      .catchError(dispatch);

export const fetchSimilarities =
  (articleId: string, content: string, userId: string, state: RawDraftContentState): Thunk<ActionType> =>
  (dispatch, getState) => {
    const article = getEntity(getState().entity, 'article', articleId);
    if (!article) return;

    apiService
      .fetchProofRead(articleId, userId, content)
      .first(() => dispatch(mergeEntities({ article: [{ ...article, editor_content: state }] })))
      .then((similarities) => dispatch({ type: 'article/setSimilarities', similarities, articleId }))
      .then(() => apiService.updateProofRead(articleId, userId, content))
      .observe('fetch_proof_read', dispatch)
      .catchError(dispatch);
  };

export const resetSimilarities =
  (articleId: string): Thunk<ActionType> =>
  (dispatch) =>
    dispatch({ type: 'article/setSimilarities', similarities: [], articleId });

export const fetchFactCheck =
  (
    articleId: string,
    generation: AITextGeneration,
    generationIndex: number,
    returnSameLanguage: boolean,
    outputLanguage: string
  ): Thunk<ActionType> =>
  (dispatch, getState) =>
    apiService
      .fetchFactCheck(generation, generationIndex, returnSameLanguage, outputLanguage)
      .then(({ generation: item }) => {
        // Replace text generation with fact checked generation
        let generations = cloneDeep(getState().article.textGenerations[articleId] ?? []);
        generations = uniqBy([item, ...generations], 'id');
        dispatch({ type: 'article/setTextGenerations', generations, articleId });
      })
      .observe('fetch_fact_check' + generation.id, dispatch)
      .catchError(dispatch);

export const fetchPlagiarism =
  (articleId: string, text: string): Thunk<ActionType> =>
  (dispatch) =>
    apiService
      .fetchPlagiarism(text)
      .then((plagiarism) => {
        if (plagiarism.globalPlagiarism) dispatch({ type: 'article/setPlagiarism', plagiarism, articleId });
        else toast.success('Your text is original! No plagiarism detected.');
      })
      .observe('fetch_plagiarism', dispatch)
      .catchError(dispatch);

export const resetPlagiarism =
  (articleId: string): Thunk<ActionType> =>
  (dispatch) =>
    dispatch({ type: 'article/setPlagiarism', plagiarism: null, articleId });
