import { ReactComponent as NovusMiniSvg } from 'assets/img/NovusMini.svg';
import { ReactComponent as RightArrow } from 'assets/img/icons/ArrowRight.svg';
import { ReactComponent as EyeIcon } from 'assets/img/icons/Home.svg';
import { ReactComponent as HelpIcon } from 'assets/img/icons/help.svg';
import { ReactComponent as UpgradeIcon } from 'assets/img/icons/upgrade.svg';
import PlusIcon from 'features/common/Icons/Plus';
import { Col, FlexCol, Label } from 'features/common/Styles';
import { UserAvatar } from 'features/common/UserAvatar';
import { memo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { animated, easings, useSpring } from 'react-spring';
import styled from 'styled-components';
import { HomeContext } from 'utils/contexts/HomeContext';
import { useSelect } from 'utils/hooks/useSelect';
import { Template } from 'utils/types';
import { DrawerRecentTemplateList } from './DrawerRecentTemplateList';

export const HomeDrawer = memo<{
  onCreateArticle: (template?: Template) => void;
}>(({ onCreateArticle }) => {
  const { push } = useHistory();
  const { setModalType } = useContext(HomeContext);

  const user = useSelect(({ me }) => me.user);
  const { t } = useTranslation(['article', 'common', 'home']);

  const [open, setOpen] = useState(true);

  const openAnimation = useSpring({
    from: { opacity: 1, width: '230px' },
    to: { width: open ? '230px' : '0px' },
    config: { duration: 250, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  const arrowAnimation = useSpring({
    from: { opacity: 1 },
    to: { rotate: open ? '180deg' : '0deg' },
    config: { duration: 250, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  // @handlers

  const handleCreateCustomTemplate = useCallback(() => {
    setModalType('customTemplate');
  }, []);

  const handleDrawerToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleLogout = useCallback(() => {
    setModalType('logout');
  }, []);

  const navigate = useCallback(
    (path: string) => () => {
      push(path);
    },
    []
  );

  // @render

  return (
    <Container>
      <Content style={openAnimation}>
        <Col style={{ padding: 20, paddingBottom: 0 }}>
          <NovusMiniSvg color="white" height={30} width={30} />
          <Col style={{ marginTop: 20 }}>
            <CustomTemplateButton onClick={() => onCreateArticle()}>
              <PlusIcon size={14} />
              <Label style={{ fontWeight: 600, fontSize: 14, color: 'white', marginLeft: 4 }}>
                {t('home:createNewProject')}
              </Label>
            </CustomTemplateButton>

            <CustomTemplateButton onClick={handleCreateCustomTemplate}>
              <Label style={{ fontWeight: 600, fontSize: 14, color: 'white' }}>{t('customTemplate')}</Label>
            </CustomTemplateButton>
          </Col>
        </Col>
        <ScrollContainer>
          <FlexCol style={{ padding: 20, paddingTop: 0 }}>
            <DrawerRecentTemplateList onTemplateClick={onCreateArticle} />
          </FlexCol>

          <DrawerBottom>
            <DrawerProperty>
              <DrawerPropertyItem onClick={navigate('/account/personal-information')}>
                <UserAvatar />
                {t('home:profile')}
              </DrawerPropertyItem>

              <DrawerPropertyItem onClick={navigate('/account/upgrade-plan')}>
                <UpgradeIcon />
                {t('home:upgradePlan')}
              </DrawerPropertyItem>

              {user?.is_admin ? (
                <DrawerPropertyItem onClick={navigate('/admin')}>
                  <EyeIcon />
                  {t('home:adminPanel')}
                </DrawerPropertyItem>
              ) : null}

              <DrawerPropertyItem>
                <HelpIcon />
                {t('home:helpCenter')}
              </DrawerPropertyItem>

              <DrawerPropertyItem onClick={handleLogout}>{t('home:signOut')}</DrawerPropertyItem>
            </DrawerProperty>
          </DrawerBottom>
        </ScrollContainer>
      </Content>

      <DrawerOnOff onClick={handleDrawerToggle}>
        <DrawerBackgroundLayer>
          <animated.div style={arrowAnimation}>
            <RightArrow width={20} height={20} />
          </animated.div>
        </DrawerBackgroundLayer>
      </DrawerOnOff>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: space-between;
  overflow: hidden;
`;

const Content = styled(animated.div)`
  display: flex;
  background: linear-gradient(130deg, #561a55 0%, #641da9 50%);
  flex-direction: column;
  justify-content: space-between;
`;

const ScrollContainer = styled(FlexCol)`
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DrawerBackgroundLayer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  padding: 0px 5px;
  align-items: center;
  cursor: pointer;
`;

const DrawerOnOff = styled.div`
  background: linear-gradient(130deg, #561a55 0%, #641da9 50%);
  color: ${({ theme }) => theme.colors.white};
`;

const DrawerBottom = styled.div`
  padding: 20px;
`;

const DrawerProperty = styled(Label)`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  min-width: 180px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 20px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  & > :not(:last-child) {
    margin-bottom: 13px;
  }
`;

const DrawerPropertyItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > :first-child {
    margin-right: 10px;
  }
`;

const CustomTemplateButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: rgba(255, 255, 255, 0.05);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
