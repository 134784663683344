import AppButton from 'features/common/AppButton';
import { AppInput } from 'features/common/AppInput';
import AppPopUp from 'features/common/AppPopUp';
import { Label } from 'features/common/Styles';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { animated } from 'react-spring';
import { toast } from 'react-toastify';
import { createFolder } from 'redux/folder';
import styled from 'styled-components';
import { HomeContext } from 'utils/contexts/HomeContext';
import { useEnterKeydown, useEscapeKeydown } from 'utils/hooks/useKeydown';
import { useQuery } from 'utils/hooks/useQuery';

export const NewFolderModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('home');

  const { isLoading, isSuccess } = useQuery('create_folder');
  const { modalType, setModalType } = useContext(HomeContext);
  const [folderName, setFolderName] = useState('');

  const open = useMemo(() => modalType === 'newFolder', [modalType]);

  // @effects

  useEscapeKeydown(() => {
    if (modalType === 'newFolder') handlePopupClose();
  }, [modalType]);

  useEnterKeydown(() => {
    if (modalType === 'newFolder') handleFolderCreate();
  }, [folderName, modalType]);

  useEffect(() => {
    if (modalType === null) setFolderName('');
  }, [modalType]);

  useEffect(() => {
    if (isSuccess) handlePopupClose();
  }, [isSuccess]);

  // @handlers

  const handlePopupClose = useCallback(() => {
    setModalType(null);
    setFolderName('');
  }, []);

  const handleFolderCreate = useCallback(() => {
    if (folderName.trim() === '') return toast.info(t('notEmptyFolderName'));
    dispatch(createFolder(folderName));
  }, [folderName]);

  // @render

  return (
    <AppPopUp open={open} onClose={handlePopupClose}>
      <Container style={{}}>
        <InnerContainer>
          <Title>📁 {t('newFolder')}</Title>
          <ProjectNameInput value={folderName} onChange={setFolderName} placeholder={t('folderName')} />
          <SaveButton text={t('create')} onClick={handleFolderCreate} loading={isLoading} />
        </InnerContainer>
      </Container>
    </AppPopUp>
  );
};

const Container = styled(animated.div)`
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
`;

const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;
  padding: 20px;
`;

const Title = styled(Label)`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  padding: 15px 0;
`;

const ProjectNameInput = styled(AppInput)`
  min-width: 380px;
`;

const SaveButton = styled(AppButton)`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  height: 48px;
  margin-top: 20px;
  background: ${(props) => props.theme.colors.dark[100]};
  &:hover {
    background: ${(props) => props.theme.colors.dark[90]};
  }
  &:active {
    background: ${(props) => props.theme.colors.dark[80]};
  }
`;
