const createImage = (options: { src: string }) => {
  options = options || {};
  const img = Image ? new Image() : document.createElement('img');
  if (options.src) img.src = options.src;
  return img;
};

const copyToClipboard = async (pngBlob: Blob | null) => {
  try {
    if (pngBlob === null) throw new Error('No image found');
    const clipboardItem = new ClipboardItem({ [pngBlob.type]: pngBlob });
    await navigator.clipboard.write([clipboardItem]);
  } catch (error) {
    console.error(error);
  }
};

const convertToPng = (imgBlob: Blob) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const imageEl = createImage({ src: window.URL.createObjectURL(imgBlob) });
  imageEl.onload = (e: any) => {
    canvas.width = e.target?.width;
    canvas.height = e.target?.height;
    ctx?.drawImage(e.target, 0, 0, e.target.width, e.target.height);
    canvas.toBlob(copyToClipboard, 'image/png', 1);
  };
};

export const convertURLToPngBlobSourceUrl = async (src: string, onCompletion: (url: string) => void) => {
  const img = await fetch(src);
  const imgBlob = await img.blob();
  const extension = src.split('.').pop();
  const convertibleExtensions = ['jpeg', 'jpg', 'gif'];

  if (convertibleExtensions.indexOf(extension?.toLowerCase() ?? '') !== -1) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const imageEl = createImage({ src: URL.createObjectURL(imgBlob) });
    imageEl.onload = (e: any) => {
      canvas.width = e.target?.width;
      canvas.height = e.target?.height;
      ctx?.drawImage(e.target, 0, 0, e.target.width, e.target.height);
      canvas.toBlob(
        (blob) => {
          if (blob === null) return;
          onCompletion(URL.createObjectURL(blob));
        },
        'image/png',
        1
      );
    };
  } else if (extension?.toLowerCase() === 'png') {
    onCompletion(URL.createObjectURL(imgBlob));
  }
};

export const copyImageToClipboard = async (src: string) => {
  const img = await fetch(src);
  const imgBlob = await img.blob();
  const extension = src.split('.').pop();
  const convertibleExtensions = ['jpeg', 'jpg', 'gif'];

  if (convertibleExtensions.indexOf(extension?.toLowerCase() ?? '')) {
    return convertToPng(imgBlob);
  } else if (extension?.toLowerCase() === 'png') {
    return copyToClipboard(imgBlob);
  }
};
