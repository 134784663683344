import { ReactComponent as Close2SVG } from 'assets/img/icons/Close2.svg';
import OneSheetImg from 'assets/img/icons/OneSheet.png';
import AppButton from 'features/common/AppButton';
import AppPopUp from 'features/common/AppPopUp';
import { memo } from 'react';
import styled from 'styled-components';

export const CustomTemplateDeleteFilePopup = memo<{
  open: boolean;
  onClickDelete: () => void;
  onClose: () => void;
  title: string;
  description: string;
}>(({ open, onClickDelete, onClose, title, description }) => {
  return (
    <AppPopUp open={open} onClose={onClose}>
      <Container>
        <CloseIconContainer>
          <Close2SVG onClick={onClose} />
        </CloseIconContainer>

        <InnerContainer>
          <img src={OneSheetImg} alt="one-sheet" width={40} height={40} />
          <Title>{title}</Title>
          <Description>{description}</Description>
        </InnerContainer>

        <DeleteButton text="Delete" onClick={onClickDelete} />
      </Container>
    </AppPopUp>
  );
});

const Container = styled.div`
  max-width: 420px;
  padding: 20px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.primary[30]};
  text-align: center;
`;

const Description = styled(Title)`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

const DeleteButton = styled(AppButton)`
  width: 100%;
  margin-top: 30px;
  background-color: ${({ theme }) => theme.colors.red[100]};
`;
