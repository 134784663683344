import { AppButtonGroup } from 'features/common/AppButtonGroup';
import { memo, useState } from 'react';
import styled from 'styled-components';
import { CustomTemplateMyFilesTab } from './CustomTemplateMyFilesTab';
import { CustomTemplateUploadTab } from './CustomTemplateUploadTab';
import { useTranslation } from 'react-i18next';
import { useSelect } from '../../utils/hooks/useSelect';

const UPLOAD_TAB = 'upload';
const MY_FILES_TAB = 'myFiles';

export const CustomTemplateUpload = memo(() => {
  const files = useSelect(({ customTemplate }) => customTemplate.files);
  const [selectedTab, setSelectedTab] = useState(files.length ? MY_FILES_TAB : UPLOAD_TAB);
  const { t } = useTranslation('article');

  const TABS = [
    { label: t('uploadNew'), value: UPLOAD_TAB },
    { label: t('myFiles'), value: MY_FILES_TAB }
  ];

  // @render

  return (
    <Container>
      <TabContainer>
        <AppButtonGroup value={selectedTab} options={TABS} onChange={setSelectedTab} />
      </TabContainer>

      <InnerContainer>
        {selectedTab === UPLOAD_TAB ? (
          <CustomTemplateUploadTab onFilesUploaded={() => setSelectedTab(MY_FILES_TAB)} />
        ) : (
          <CustomTemplateMyFilesTab />
        )}
      </InnerContainer>
    </Container>
  );
});

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InnerContainer = styled.div`
  flex: 1;
  overflow: hidden;
  border: 1px dashed ${({ theme }) => theme.colors.soft[50]};
  border-radius: 4px;
`;
