import { LanguageSelect } from 'features/common/LanguageSelect';
import { memo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateArticle } from 'redux/article';
import { getEntity } from 'redux/entity';
import styled from 'styled-components';
import { useQuery } from 'utils/hooks/useQuery';
import { useSelect } from 'utils/hooks/useSelect';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import dayjs from 'dayjs';
import { ReactComponent as EditIcon } from 'assets/img/icons/Edit.svg';
import { Row } from '../common/Styles';

export const ArticleHeader = memo(() => {
  const { t } = useTranslation('article');
  const dispatch = useDispatch();
  const { articleId } = useContext(ArticleContext);

  const article = useSelect(({ entity }) => getEntity(entity, 'article', articleId));
  const { isLoading } = useQuery('fact_check');

  const [editProjectName, setEditProjectName] = useState(false);
  const [articleName, setArticleName] = useState('');

  // @handlers

  const handleIsChangeArticleName = useCallback(
    (isChange: boolean) => () => {
      setEditProjectName(isChange);
      setArticleName(article?.title ?? '');
    },
    [article?.title]
  );

  const handleNewArticleName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setArticleName(e.target.value);
    },
    [setArticleName]
  );

  const handleEnterKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event?.key === 'Enter' && article) {
        dispatch(updateArticle(articleId, { title: articleName }));
        setEditProjectName(false);
      } else if (event?.key === 'Escape') {
        setEditProjectName(false);
      }
    },
    [article, articleName]
  );

  // @render

  return (
    <Container>
      <Backdrop onClick={handleIsChangeArticleName(false)} open={editProjectName} />

      <LeftAreaContainer>
        <Title onClick={handleIsChangeArticleName(true)}>
          {editProjectName ? (
            <ProjectNameInput
              onChange={handleNewArticleName}
              value={articleName ?? article?.title}
              onKeyPress={handleEnterKeyPress}
            />
          ) : (
            <Row style={{ alignItems: 'center' }}>
              {article?.title || `Draft: ${dayjs(article?.created_at).format('MMMM DD, HH:mm')}`}
              <EditIcon style={{ marginLeft: 10, marginBottom: 1 }} width={14} height={14} />
            </Row>
          )}
        </Title>
      </LeftAreaContainer>

      <RightAreaContainer>
        {isLoading ? (
          <FactCheckLoaderContainer>
            <LoadingText>{t('factCheckIsLoading')}</LoadingText>
          </FactCheckLoaderContainer>
        ) : (
          <LanguageSelect />
        )}
      </RightAreaContainer>
    </Container>
  );
});

const Container = styled.div`
  grid-column: 2 / span 2;
  display: flex;
  padding: 10px 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.soft[70]};
`;

const LeftAreaContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
  z-index: 3;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.dark[100]};
`;

const RightAreaContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FactCheckLoaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  border-radius: 4px;
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
  min-height: 36px;
  justify-content: center;
  align-items: center;
`;

const LoadingText = styled.span`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme }) => theme.colors.dark[90]};
`;

const ProjectNameInput = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.gray[70]};
  border-radius: 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.dark[100]};
  padding: 8.5px 12px 8.5px 2px;
  margin-left: -12px;
  text-indent: 10px;
  max-height: 32px;

  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const Backdrop = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;
