import { signInWithEmailAndPassword } from 'firebase/auth';
import api from 'redux/api';
import { store } from 'redux/store';
import { Authorization, LoginType } from 'utils/types';
import { trackEvent } from 'utils/analytics';
import { auth } from '../../Firebase';
import { login as loginAction } from './slice';

export const login = async (data: LoginType) => {
  const credential = await signInWithEmailAndPassword(auth, data.email, data.password);
  const user = credential.user;
  if (!user) throw new Error('User not found');

  const token = await user.getIdToken();
  const authorization: Authorization = { token, userID: user.uid };
  localStorage.setItem('auth', JSON.stringify(authorization));

  store.dispatch(loginAction(authorization));

  trackEvent({ event: 'Signed In', userId: user.uid, properties: { email: user.email! } });
};

const AuthApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkShouldResetPassword: build.mutation<{ link: string | null }, string>({
      query: (email) => ({ url: `/checkNeedPasswordReset`, method: 'GET', params: { email } }),
      transformResponse: (response: { data: { link: string | null } }) => response.data || null
    }),
    forgotPassword: build.mutation<any, string>({
      query: (email) => ({ url: `/forgotPassword`, method: 'POST', body: { email } }),
      transformResponse: (response: { data: any }) => response.data || null
    }),
    checkResetPasswordToken: build.mutation<any, string>({
      query: (payload) => ({ url: `/tokenVerifyCheck`, method: 'POST', body: payload }),
      transformResponse: (response: { data: any }) => response.data || null
    }),
    upgradeOrDowngradePlan: build.mutation<any, string>({
      query: (payload) => ({ url: `/payment/upgradeOrDowngrade`, method: 'POST', body: { plan: payload } }),
      transformResponse: (response: { data: any }) => response.data || null,
      invalidatesTags: ['UserPlan']
    }),
    cancelPlan: build.mutation<any, void>({
      query: () => ({ url: `/payment/cancel`, method: 'POST' }),
      transformResponse: (response: { data: any }) => response.data || null,
      invalidatesTags: ['UserPlan']
    }),
    reactivatePlan: build.mutation<any, void>({
      query: () => ({ url: `/payment/reactivate`, method: 'POST' }),
      transformResponse: (response: { data: any }) => response.data || null,
      invalidatesTags: ['UserPlan']
    }),
    validateUpgradePlanCheckoutSession: build.mutation<any, string>({
      query: (payload) => ({ url: `/payment/validateStripeSession`, method: 'POST', body: { sessionId: payload } }),
      transformResponse: (response: { data: any }) => response.data || null,
      invalidatesTags: ['UserPlan']
    })
  }),
  overrideExisting: false
});

export const {
  useCheckResetPasswordTokenMutation,
  useUpgradeOrDowngradePlanMutation,
  useCancelPlanMutation,
  useReactivatePlanMutation,
  useValidateUpgradePlanCheckoutSessionMutation,
  useForgotPasswordMutation,
  useCheckShouldResetPasswordMutation
} = AuthApi;
export default AuthApi;
