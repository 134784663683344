import { FlexCol, FlexRow, Label } from 'features/common/Styles';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createArticle, fetchArticles, fetchTemplates } from 'redux/article';
import { setSelectedTextTemplateId } from 'redux/editor';
import { fetchUser } from 'redux/me';
import styled from 'styled-components';
import { HomeProvider } from 'utils/contexts/HomeContext';
import { Template } from 'utils/types';
import { v4 } from 'uuid';
import { HomeDrawer } from '../drawer/HomeDrawer';
import { HomeFolders } from './HomeFolders';
import { HomeHotTopics } from './HomeHotTopics';
import { HomeQuickActions } from './HomeQuickActions';
import { DeleteArticleModal } from './modals/DeleteArticleModal';
import { DeleteFolderModal } from './modals/DeleteFolderModal';
import { EditArticleModal } from './modals/EditArticleModal';
import { EditFolderModal } from './modals/EditFolderModal';
import { MoveArticleModal } from './modals/MoveArticleModal';
import { NewFolderModal } from './modals/NewFolderModal';
import { LogoutModal } from './modals/LogoutModal';
import { CustomTemplatePreparingModal } from './modals/CustomTemplatePreparingModal';
import { CustomAIModal } from '../customTemplate';

export const HomePage: React.FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { t } = useTranslation(['home', 'article', 'common']);

  // @effects

  useEffect(() => {
    dispatch(fetchArticles());
    dispatch(fetchTemplates());
    dispatch(fetchUser());
  }, []);

  // @handlers

  const handleCreateArticle = useCallback((template?: Template) => {
    const articleId = v4();
    if (template) dispatch(setSelectedTextTemplateId(articleId, template?.id || ''));
    push(`article/${articleId}/true`);
  }, []);

  const handleArticleClick = useCallback((article: { id: string }) => {
    push(`article/${article.id}`);
  }, []);

  // @render

  return (
    <HomeProvider>
      <FlexRow>
        <HomeDrawer onCreateArticle={handleCreateArticle} />

        <Container>
          <Title>{t('home')}</Title>
          <HomeQuickActions onCreateArticle={handleCreateArticle} />
          <HomeHotTopics />
          <HomeFolders onArticleClick={handleArticleClick} />
        </Container>

        <DeleteArticleModal />
        <DeleteFolderModal />

        <EditFolderModal />
        <EditArticleModal />
        <MoveArticleModal />

        <NewFolderModal />
        <LogoutModal />

        <CustomAIModal />
        <CustomTemplatePreparingModal />
      </FlexRow>
    </HomeProvider>
  );
};

const Container = styled(FlexCol)`
  height: 100vh;
  padding: 80px 5%;
  overflow-y: auto;
`;

const Title = styled(Label)`
  align-self: flex-start;
  background-color: ${({ theme }) => theme.colors.gray};
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  padding-bottom: 50px;
`;
