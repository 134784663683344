import i18n from 'config/i18n';
import { ContentKeys, FormField, TEMPLATE_TYPES } from './types';

export function getInitialTemplateRelatedFields(templateType: TEMPLATE_TYPES) {
  const templateFields = i18n.t('article:form', { returnObjects: true }) as {
    [key: string]: { [key: string]: { title: string; description: string; placeholder: string } };
  };

  const newTemplateRelatedFields: { [key: string]: string } = {};
  Object.keys(templateFields[templateType] || {}).forEach((fieldName) => {
    newTemplateRelatedFields[fieldName] = '';
  });
  return newTemplateRelatedFields;
}

export function getFormFieldsForCustomTemplate(templateType: TEMPLATE_TYPES): Array<FormField> {
  const templateFields = i18n.t('article:form', { returnObjects: true }) as {
    [key: string]: { [key: string]: { title: string; description: string; placeholder: string } };
  };

  return Object.entries(templateFields[templateType] || {}).map(([id, field]) => ({
    id: id as keyof ContentKeys,
    ...field,
    required: true,
    multiline: true
  }));
}

const templateNameRepository = {
  emojis: [
    '🚀',
    '🌕',
    '🌍',
    '🌹',
    '🍄',
    '🍀',
    '🍎',
    '🍓',
    '🍔',
    '🍕',
    '🍺',
    '🎈',
    '🎏',
    '🎓',
    '🎸',
    '🏝️',
    '🏔️',
    '🏖️',
    '🏞️',
    '🏜️',
    '📚',
    '📸',
    '🔬',
    '🔭',
    '💡',
    '🔒',
    '🔑',
    '💎',
    '⚙️',
    '🧲',
    '🧪',
    '🧬',
    '🌐',
    '⛏️',
    '🔩',
    '🔧',
    '🧰',
    '⚖️',
    '🧭',
    '🧱',
    '🪁',
    '🪐',
    '☄️',
    '🌠',
    '🎇',
    '🎆',
    '🌈',
    '☀️',
    '⛅',
    '🌧️',
    '🌨️',
    '⛄',
    '🔥',
    '💧',
    '🌊',
    '🍁',
    '🍂',
    '🌴',
    '🌵',
    '🌾'
  ],
  prefix: [
    'Guide to',
    'Introduction to',
    'Advanced Study of',
    'Basics of',
    'Deep Dive into',
    'Primer on',
    'Synopsis of',
    'Analysis of',
    'The Art of',
    'The Science behind',
    'Understanding',
    'Examination of',
    'Insight into',
    'The Philosophy of',
    'The Anatomy of',
    'The Secrets of',
    'Exploring',
    'A Journey through',
    'Interpretation of',
    'Mastering',
    'The Essence of',
    'The World of',
    'Getting Started with',
    'Fundamentals of',
    'The Mechanics of',
    'The Dynamics of',
    'The Principles of',
    'The Realm of',
    'Comprehending',
    'The Intricacies of',
    'The Language of',
    'The Mystery of',
    'Unfolding',
    'Grasping',
    'The Chronicles of',
    'Redefining',
    'Visualizing',
    'The Gateway to',
    'Decoding',
    'Navigating Through',
    'Journey Into',
    'Encyclopedia of',
    'Field Guide to',
    'Treasures of',
    'Pioneering',
    'The Adventure into',
    'Wonders of',
    'Lessons from',
    'The Road to',
    'Delving into'
  ],
  suffix: [
    'Space Monkey',
    'Robot Ninja',
    'Quantum Physics',
    'Peanuts',
    'Blue Whales',
    'Ancient Civilizations',
    'Modern Art',
    'Biological Systems',
    'Astronomical Wonders',
    'Mythical Creatures',
    'Cryptographic Puzzles',
    'Quantum Computing',
    'Geographical Landscapes',
    'Culinary Delights',
    'Musical Harmony',
    'Photographic Techniques',
    'Mathematical Concepts',
    'Historical Events',
    'Revolutionary Technologies',
    'Classical Literature',
    'Cognitive Sciences',
    'Architectural Marvels',
    'Ecological Systems',
    'Videography Tricks',
    'Astrophysics Mysteries',
    'Psychoanalytical Theories',
    'Microbial Worlds',
    'Blockchain Technologies',
    'Artificial Intelligence',
    'Nutritional Sciences',
    'Genetic Engineering',
    'Philosophical Debates',
    'Cosmological Phenomena',
    'Evolutionary Biology',
    'Neuroscientific Discoveries',
    'Theoretical Physics',
    'Economic Theories',
    'Sociological Studies',
    'Political Ideologies',
    'Cultural Diversity',
    'Folklore and Mythology',
    'Animal Behavior',
    'Plant Biodiversity',
    'Environmental Sustainability',
    'Data Science',
    'Cloud Computing',
    'Nanotechnology',
    'Virtual Reality',
    'Augmented Reality',
    'Cybersecurity Threats'
  ]
};

// Let's say possibility of returning same name combination can be negligible
export function generateRandomTemplateName(): string {
  function getRandomElement(array: string[]): string {
    return array[Math.floor(Math.random() * array.length)];
  }

  const emoji = getRandomElement(templateNameRepository.emojis);
  const prefix = getRandomElement(templateNameRepository.prefix);
  const suffix = getRandomElement(templateNameRepository.suffix);

  return `${emoji} ${prefix} ${suffix}`;
}
