import { ReactComponent as BackIcon } from 'assets/img/icons/BackIcon.svg';
import { ReactComponent as SearchLens } from 'assets/img/icons/search_lens.svg';
import PlusIcon from 'features/common/Icons/Plus';
import { Col, FlexCol, Label, Row } from 'features/common/Styles';
import { uniq } from 'lodash';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEntities } from 'redux/entity';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { ArticleDrawerTemplateAccordion } from './ArticleDrawerTemplateAccordion';
import { DrawerRecentTemplateList } from '../DrawerRecentTemplateList';
import { Template } from 'utils/types';
import { ArticleDrawerTemplateListItem } from './ArticleDrawerTemplateListItem';
import { ArticleContext } from 'utils/contexts/ArticleContext';

export const ArticleDrawerTemplates = memo<{
  onBackClick: () => void;
  onTemplateClick: (template: Template) => void;
}>(({ onBackClick, onTemplateClick }) => {
  const { t } = useTranslation(['article', 'common']);
  const { articleId, setModalType } = useContext(ArticleContext);

  const selectedTemplateId = useSelect(({ editor }) => editor.selectedTextTemplateId[articleId] ?? '');
  const templateParentNames = useSelect(({ entity }) => {
    const templates = getEntities(entity, 'template').filter(({ type }) => type === 'text');
    const parentNames = templates.map(({ parent }) => parent);
    return uniq(parentNames).filter((name) => name !== 'Beta' && name !== 'UserTemplate');
  });

  const templates = useSelect(({ entity }) => getEntities(entity, 'template', ({ type }) => type === 'text'));

  const [query, setQuery] = useState<string>('');

  const filteredTemplates = useMemo(() => {
    if (!query) return [];
    return templates.filter((template) => template.name.toLowerCase().includes(query.toLowerCase()));
  }, [query, templates]);

  // @handlers

  const handleSearchQueryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  // @render

  return (
    <FlexCol>
      <Col style={{ paddingTop: 23, alignItems: 'center', minWidth: 230 }}>
        <Header>
          <BackButton onClick={onBackClick}>
            <BackIcon />
          </BackButton>
          <Title>{t('templates')}</Title>
        </Header>
      </Col>

      <FlexCol style={{ paddingLeft: 20, paddingRight: 20 }}>
        <SearchInputContainer>
          <span style={{ marginLeft: 10 }}>
            <SearchLens />
          </span>
          <SearchInput placeholder={t('home:search')} onChange={handleSearchQueryChange} value={query} />
        </SearchInputContainer>

        {!query ? (
          <>
            <CustomTemplateButton onClick={() => setModalType('customTemplate')}>
              <PlusIcon size={14} />

              <Label style={{ fontWeight: 600, fontSize: 14, color: 'white', marginLeft: 10 }}>
                {t('customTemplate')}
              </Label>
            </CustomTemplateButton>

            {templateParentNames.map((templateParentName, index) => (
              <ArticleDrawerTemplateAccordion
                key={templateParentName}
                parentName={templateParentName}
                index={index}
                onTemplateClick={onTemplateClick}
              />
            ))}

            <Col style={{ zIndex: 10, marginBottom: 40 }}>
              <DrawerRecentTemplateList onTemplateClick={onTemplateClick} />
            </Col>
          </>
        ) : (
          <>
            {filteredTemplates.map((template) => (
              <ArticleDrawerTemplateListItem
                key={template.id}
                template={template}
                isSelected={template.id === selectedTemplateId}
                isLastItem={false}
                removePadding
                onTemplateClick={onTemplateClick}
              />
            ))}
          </>
        )}
      </FlexCol>
    </FlexCol>
  );
});

const Title = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  flex: 1;
  color: ${({ theme }) => theme.colors.white};
`;

const Header = styled(Row)`
  align-items: center;
  padding-right: 35px;
  padding-left: 8px;
  justify-content: space-between;
  min-width: 230px;
`;

const BackButton = styled(Row)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 10px !important;
`;

const SearchInput = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.white};
  margin-left: 10px;
  padding: 10px 0px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.3;
  }
`;

const CustomTemplateButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 0px;
  color: ${({ theme }) => theme.colors.white};
  background-color: rgba(255, 255, 255, 0.05);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
