import { ReactComponent as BackIcon } from 'assets/img/icons/BackIcon.svg';
import { Col, FlexCol, Label, Row } from 'features/common/Styles';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const ArticleDrawerBetaTemplates = memo<{
  onBackClick: () => void;
}>(({ onBackClick }) => {
  const { t } = useTranslation(['article', 'common']);

  // @render

  return (
    <FlexCol>
      <Col style={{ paddingTop: 23, alignItems: 'center', minWidth: 230 }}>
        <Header>
          <BackButton onClick={onBackClick}>
            <BackIcon />
          </BackButton>
          <Title>{t('beta')}</Title>
        </Header>
      </Col>
    </FlexCol>
  );
});

const Title = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  flex: 1;
  color: ${({ theme }) => theme.colors.white};
`;

const Header = styled(Row)`
  align-items: center;
  padding-right: 35px;
  padding-left: 8px;
  justify-content: space-between;
  min-width: 230px;
`;

const BackButton = styled(Row)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
`;
