import { ReactComponent as Close2SVG } from 'assets/img/icons/Close.svg';
import AppPopUp from 'features/common/AppPopUp';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTemplates } from 'redux/article';
import styled from 'styled-components';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { CustomTemplateCreator } from './CustomTemplateCreator';
import { CustomTemplateInstructions } from './CustomTemplateInstructions';
import { CustomTemplateOnboarding } from './CustomTemplateOnboarding';
import { CustomTemplateResult } from './CustomTemplateResult';
import { HomeContext } from 'utils/contexts/HomeContext';
import { fetchCustomTemplateFiles, resetCustomTemplateSelectedFiles } from '../../redux/customTemplate';

export const CustomAIModal = memo(() => {
  const dispatch = useDispatch();

  const { modalType: articleModalType, setModalType: setArticleModalType } = useContext(ArticleContext);
  const { modalType: homeModalType, setModalType: setHomeModalType } = useContext(HomeContext);

  const [templateCreationStep, setTemplateCreationStep] = useState(1);

  // @effects

  useEffect(() => {
    const shouldShow = articleModalType === 'customTemplate' || homeModalType === 'customTemplate';
    if (shouldShow) dispatch(fetchCustomTemplateFiles());
    else dispatch(resetCustomTemplateSelectedFiles());
  }, [homeModalType, articleModalType]);

  useEffect(() => {
    if (templateCreationStep === 4) dispatch(fetchTemplates());
  }, [templateCreationStep]);

  // @handlers

  const handlePopupClose = useCallback(() => {
    setArticleModalType(null);
    setHomeModalType(null);
    setTemplateCreationStep(1);
  }, []);

  const handleCustomTemplateProgress = useCallback(() => {
    setTemplateCreationStep((prev) => prev + 1);
  }, []);

  // @render

  const renderContent = useCallback(() => {
    switch (templateCreationStep) {
      case 1:
        return <CustomTemplateOnboarding onClickContinue={handleCustomTemplateProgress} />;
      case 2:
        return <CustomTemplateInstructions onClickContinue={handleCustomTemplateProgress} />;
      case 3:
        return <CustomTemplateCreator onTemplateCreated={handleCustomTemplateProgress} />;
      case 4:
        return <CustomTemplateResult onClickContinue={handlePopupClose} />;
      default:
        return null;
    }
  }, [templateCreationStep, handleCustomTemplateProgress, handlePopupClose]);

  return (
    <AppPopUp open={articleModalType === 'customTemplate' || homeModalType === 'customTemplate'}>
      <Container>
        <CloseIconContainer>
          <Close2Icon style={{ width: 18, height: 18 }} onClick={handlePopupClose} />
        </CloseIconContainer>

        {renderContent()}
      </Container>
    </AppPopUp>
  );
});

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: none;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  padding: 30px 60px;
  padding-bottom: 0px;
  z-index: 20;
`;

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Close2Icon = styled(Close2SVG)`
  cursor: pointer;
`;
