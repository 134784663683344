import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';

// Internal Components - order important, do not change
import { persistor, store } from 'redux/store';
import { App } from 'App';
import theme from 'config/theme';
import 'react-toastify/dist/ReactToastify.css';
import 'config/theme/globalStyles.css';
import reportWebVitals from './reportWebVitals';

import 'config/i18n';
import { Slide, ToastContainer } from 'react-toastify';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <>
            <CssBaseline enableColorScheme />
            <ThemeProvider theme={theme}>
              <App />
              <ToastContainer autoClose={2000} transition={Slide} hideProgressBar />
            </ThemeProvider>
          </>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
