import { ReactComponent as MessySVG } from 'assets/img/icons/Messy.svg';
import AppButton from 'features/common/AppButton';
import { ChangeEvent, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  resetCustomTemplateFileUploadData,
  setCustomTemplateFileUploadData,
  uploadCustomTemplateFile
} from 'redux/customTemplate';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { Col } from '../common/Styles';

const MAX_FILE_ARRAY_LENGTH = 40;

export const CustomTemplateUploadTab = memo<{ onFilesUploaded?: () => void }>(({ onFilesUploaded }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['article', 'common']);

  const files = useSelect(({ customTemplate }) => customTemplate.files);
  const uploadingFilesInProgress = useSelect(({ customTemplate }) => {
    const uploadedCount = customTemplate.fileUploadData.uploadedFileCount;
    const totalCount = customTemplate.fileUploadData.totalFileCount;
    return uploadedCount < totalCount;
  });

  const uploadedFilesSize = useSelect(({ customTemplate }) => customTemplate.fileUploadData.uploadedFilesSize);
  const totalFileSizeToUpload = useSelect(({ customTemplate }) => customTemplate.fileUploadData.totalFilesSize);
  const isDone = useSelect(({ customTemplate }) => customTemplate.fileUploadData.isDone);

  // @effects

  useEffect(() => {
    if (isDone) {
      toast.success(t('customTemplateFilesUploaded'));
      onFilesUploaded?.();
      dispatch(resetCustomTemplateFileUploadData());
    }
  }, [isDone]);

  // @handlers

  const handleFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target?.files?.length === 0) return;
      const filesArray = Array.from(event.target?.files ?? []);
      if ((filesArray?.length ?? 0) + files.length < MAX_FILE_ARRAY_LENGTH) {
        const totalSize = filesArray.reduce((prev, curr) => prev + curr.size, 0);
        dispatch(setCustomTemplateFileUploadData(0, totalSize, 0, filesArray.length));
        filesArray.forEach((file, index) => dispatch(uploadCustomTemplateFile(file, index)));
      } else {
        toast.error(t('customTemplateMaxFileSizeReached'));
      }
    },
    [files, onFilesUploaded]
  );

  return (
    <UploadArea shouldCenterContent={true}>
      {uploadingFilesInProgress ? (
        <ProgressContainer>
          <Progress
            progress={totalFileSizeToUpload !== 0 ? Math.round((uploadedFilesSize / totalFileSizeToUpload) * 100) : 0}
          />
        </ProgressContainer>
      ) : (
        <Col>
          <FileInput type="file" draggable className="file" onChange={handleFileUpload} multiple accept=".txt,.docx" />

          <MessySVG />
          <UploadTextContainer>
            <UploadText>{t('autoMlTextUploadInformation')}</UploadText>
            <UploadText style={{ fontSize: 14, opacity: 0.8, fontWeight: 600 }}>{t('or')}</UploadText>
          </UploadTextContainer>
          <UploadButton text={t('uploadFile')} onClick={() => null} disabled />
        </Col>
      )}
    </UploadArea>
  );
});

const UploadArea = styled.div<{ shouldCenterContent: boolean }>`
  height: 100%;
  padding: 20px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ shouldCenterContent }) => (shouldCenterContent ? 'center' : 'flex-end')};
`;

const FileInput = styled.input`
  position: absolute;
  inset: 0;
  opacity: 0;
  cursor: pointer;
`;

const UploadTextContainer = styled.div`
  margin: 20px 0px;

  & > :first-child {
    margin-bottom: 10px;
  }
`;

const UploadText = styled.p`
  color: ${({ theme }) => theme.colors.primary[30]};
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
`;

const UploadButton = styled(AppButton)`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  color: ${({ theme }) => theme.colors.primary[30]};
  width: 100%;
  margin-top: -6px;
`;

const ProgressContainer = styled.div`
  position: relative;
  width: 50%;
  height: 9px;
  background-color: ${({ theme }) => theme.colors.gray[60]};
  border-radius: 7px;
`;

const Progress = styled.div<{ progress: number }>`
  position: absolute;
  height: 100%;
  width: ${({ progress }: { progress: number }) => `${progress}%`};
  background-color: ${({ theme }) => theme.colors.purple[100]};
  border-radius: 7px;
  transition: width 0.5s;
`;
