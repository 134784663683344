import { ReactComponent as DownloadIcon } from 'assets/img/icons/Download.svg';
import { ReactComponent as EditIcon } from 'assets/img/icons/Edit.svg';
import { ReactComponent as MoveIcon } from 'assets/img/icons/Move.svg';
import { ReactComponent as DotsIcon } from 'assets/img/icons/dots-x3.svg';
import { ReactComponent as TrashIcon } from 'assets/img/icons/trash_small.svg';
import dayjs from 'dayjs';
import { Dropdown } from 'features/common/Dropdown';
import { Col, FlexCol, Label, Row } from 'features/common/Styles';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HomeContext } from 'utils/contexts/HomeContext';
import { Article } from 'utils/types';

export const HomeFolderArticleListItem = memo<{
  article: Article;
  index: number;
  isLastItem: boolean;
  enabled: boolean;
  onDropdownToggle: (isOpen: boolean) => void;
  onArticleClick: (article: Article) => void;
}>(({ article, enabled, index, isLastItem, onDropdownToggle, onArticleClick }) => {
  const { t } = useTranslation(['home']);
  const { setSelectedArticleId, setModalType } = useContext(HomeContext);

  const [zIndex, setZIndex] = useState(index);

  const prompt = useMemo(() => {
    const textPromptKeys = Object.keys(article.text_prompt || {});
    const imagePromptKeys = Object.keys(article.image_prompt || {});

    if (textPromptKeys.length) {
      const textPrompt = article.text_prompt[textPromptKeys[0]];
      if (typeof textPrompt === 'string') return article.text_prompt[textPromptKeys[0]];
      else return '';
    } else if (imagePromptKeys.length) {
      return article.image_prompt[imagePromptKeys[0]];
    } else if (article.paragraph_prompt?.length) {
      return article.paragraph_prompt[0];
    } else {
      return '';
    }
  }, [article]);

  const dropdownOptions = useMemo(() => {
    return [
      { title: t('edit'), icon: EditIcon },
      { title: t('moveToFolder'), icon: MoveIcon },
      { title: t('download'), icon: () => <DownloadIcon stroke="#7D89AB" /> },
      { title: t('delete'), icon: TrashIcon }
    ];
  }, []);

  // @handlers

  const handleDropdownToggle = useCallback(
    (isOpen: boolean) => {
      onDropdownToggle(isOpen);
      if (isOpen) setZIndex(Number.MAX_SAFE_INTEGER);
      else setZIndex(index);
    },
    [onDropdownToggle]
  );

  const handleDropdownOptionClick = useCallback(
    (option: number) => {
      if (!enabled) return;

      setSelectedArticleId(article.id);
      if (option === 0) setModalType('editArticle');
      else if (option === 1) setModalType('moveArticle');
      else if (option === 2) console.log('download');
      else if (option === 3) setModalType('deleteArticle');
    },
    [article.id, enabled]
  );

  const handleArticleClick = useCallback(() => {
    if (enabled) onArticleClick(article);
  }, [article, enabled, onArticleClick]);

  // @render

  return (
    <Container
      style={{ borderBottomWidth: isLastItem ? 0 : 1, zIndex, cursor: enabled ? 'pointer' : 'default' }}
      onClick={handleArticleClick}
    >
      <FlexCol style={{ alignItems: 'flex-start' }}>
        <Row>
          <Title style={{ marginRight: 6 }}>{'📄'}</Title>
          <Title>{article.title || `Draft: ${dayjs(article.created_at).format('MMMM DD, HH:mm')}`}</Title>
        </Row>
        <Subtitle>{prompt || 'No input entered yet.'}</Subtitle>
      </FlexCol>

      <Col style={{ alignItems: 'flex-end' }}>
        <DateInfo>{t('lastUpdatedAt')}</DateInfo>
        <DateText>{dayjs(article.created_at).format('MMMM DD, HH:mm')}</DateText>
      </Col>

      <Dropdown
        alwaysOnTop
        onToggle={handleDropdownToggle}
        style={{ position: 'relative', marginLeft: 3 }}
        position={{ right: 0, top: 24 }}
        options={dropdownOptions}
        handleOptionClick={handleDropdownOptionClick}
      >
        <Row style={{ padding: 12, marginRight: -6, borderRadius: 4 }}>
          <DotsIcon style={{ width: 16 }} />
        </Row>
      </Dropdown>
    </Container>
  );
});

const Container = styled(Row)`
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  padding: 10px 10px;
  margin: 0px 30px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[40]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[10]};
  }
`;

const Title = styled(Label)`
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  padding-bottom: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const Subtitle = styled(Label)`
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.dark[70]};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const DateInfo = styled(Label)`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.colors.gray[50]};
`;

const DateText = styled(Label)`
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme }) => theme.colors.dark[80]};
`;
