import React, { PropsWithChildren, createContext } from 'react';

type HomeModalType =
  | 'newFolder'
  | 'newArticle'
  | 'deleteArticle'
  | 'deleteFolder'
  | 'editArticle'
  | 'editFolder'
  | 'moveArticle'
  | 'customTemplatePreparing'
  | 'customTemplate'
  | 'logout';

export const HomeContext = createContext<{
  modalType: HomeModalType | null;
  selectedArticleId: string | null;
  selectedFolderId: string | null;
  setModalType: (type: HomeModalType | null) => void;
  setSelectedArticleId: (articleId: string | null) => void;
  setSelectedFolderId: (folderId: string | null) => void;
}>({
  modalType: null,
  selectedArticleId: null,
  selectedFolderId: null,
  setModalType: () => {},
  setSelectedArticleId: () => {},
  setSelectedFolderId: () => {}
});

export const HomeProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [modalType, setModalType] = React.useState<HomeModalType | null>(null);
  const [selectedArticleId, setSelectedArticleId] = React.useState<string | null>(null);
  const [selectedFolderId, setSelectedFolderId] = React.useState<string | null>(null);

  return (
    <HomeContext.Provider
      value={{
        modalType,
        selectedArticleId,
        selectedFolderId,
        setModalType,
        setSelectedArticleId,
        setSelectedFolderId
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
