import { Col, FlexCol, Label } from 'features/common/Styles';
import { head } from 'lodash';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setArticleGenerationType } from 'redux/editor';
import { getEntities, getEntity } from 'redux/entity';
import styled from 'styled-components';
import { retrieveTemplateIcon, retrieveTemplateName } from 'utils/helpers/articleHelper';
import { useSelect } from 'utils/hooks/useSelect';
import { Article, DrawerSection, Template } from 'utils/types';
import { DrawerFolderList } from '../DrawerFolderList';
import { DrawerRecentTemplateList } from '../DrawerRecentTemplateList';
import { ArticleDrawerSectionList } from './ArticleDrawerSectionList';

export const ArticleDrawerNavigator = memo<{
  articleId: string;
  onSectionClick: (section: DrawerSection) => void;
  onArticleClick: (article: Article) => void;
  onTemplateClick: (template: Template) => void;
}>(({ articleId, onArticleClick, onSectionClick, onTemplateClick }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['article', 'common']);
  const generationType = useSelect(({ editor }) => editor.generationType[articleId]);

  const selectedTemplate = useSelect(({ editor, entity }) => {
    const templateId = editor.selectedTextTemplateId[articleId];
    const firstTemplate = head(getEntities(entity, 'template'));
    const template = getEntity(entity, 'template', templateId);
    return template || firstTemplate;
  });

  // @effects

  useEffect(() => {
    if (!generationType) dispatch(setArticleGenerationType(articleId, 'text'));
  }, [articleId, generationType]);

  // @handlers

  const handleTemplateClick = useCallback(
    (template: Template) => {
      onTemplateClick(template);
    },
    [onTemplateClick]
  );

  // @render

  return (
    <FlexCol>
      <Col style={{ paddingTop: 28, alignItems: 'center' }}>
        <Title>{t('currentTemplate')}</Title>
        <Label style={{ fontSize: 14, color: 'white', marginTop: 2, minWidth: 190, maxWidth: 190 }}>
          {`${retrieveTemplateIcon(selectedTemplate)} ${retrieveTemplateName(selectedTemplate, t)}`}
        </Label>
      </Col>

      <FlexCol style={{ padding: 20 }}>
        <ArticleDrawerSectionList onSectionClick={onSectionClick} />
        <DrawerRecentTemplateList showLongTitle onTemplateClick={handleTemplateClick} />
        <DrawerFolderList articleId={articleId} onArticleClick={onArticleClick} />
      </FlexCol>
    </FlexCol>
  );
});

const Title = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  min-width: 190px;
  color: ${({ theme }) => theme.colors.white};
`;
