import CheckmarkWithBackgroundIcon from 'features/common/Icons/CheckmarkWithBackground';
import { useCancelPlanMutation, useReactivatePlanMutation } from 'redux/auth/api';
import { useGetUserPlanQuery } from 'redux/user/api';
import { useTheme } from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AppButton from 'features/common/AppButton';
import styled from 'styled-components';

const AccountSubscription = () => {
  const { t } = useTranslation(['account', 'common']);
  const theme = useTheme();

  const { data: userPlan } = useGetUserPlanQuery();
  const [cancelPlanApi, { isLoading: isCancelingInProgress }] = useCancelPlanMutation();
  const [reactivatePlanApi, { isLoading: isReactivatingInProgress }] = useReactivatePlanMutation();

  const onClickCancel = async () => {
    try {
      await cancelPlanApi();
    } catch (error) {
      toast.error(t('common:somethingsWrongError'));
    }
  };

  const onClickReactivate = async () => {
    try {
      await reactivatePlanApi();
    } catch (error) {
      toast.error(t('common:somethingsWrongError'));
    }
  };

  const isThereRequestCount = typeof userPlan?.request_count === 'number';
  const isRequestInfinite = userPlan?.purchased_plan?.request_quota === -1;
  const availableRequestCountText = isRequestInfinite
    ? 'Infinite'
    : `${userPlan?.request_count ?? 0}/${userPlan?.purchased_plan?.request_quota ?? 10}`;

  const isThereArticleCount = typeof userPlan?.article_count === 'number';
  const isArticleInfinite = userPlan?.purchased_plan?.article_quota === -1;
  const availableArticleCountText = isArticleInfinite
    ? 'Infinite'
    : `${userPlan?.article_count ?? 0}/${userPlan?.purchased_plan?.article_quota ?? 10}`;

  const isCanceled = userPlan?.is_canceled;
  const canCancel = userPlan?.purchased_plan.is_cancelable ?? false;
  const isFreePlan = userPlan?.purchased_plan?.price === 0;

  const startedDate = userPlan?.started_at ? dayjs(userPlan?.started_at?._seconds * 1000).format('DD/MM/YYYY') : '-';
  const expireDate = userPlan?.expires_at ? dayjs(userPlan?.expires_at?._seconds * 1000).format('DD/MM/YYYY') : '-';

  return (
    <Container>
      <SubscriptionCard>
        <Section>
          <Title>Plan name</Title>
          <SectionValue>{userPlan?.purchased_plan?.name ?? '-'}</SectionValue>
        </Section>

        <Section>
          <Title>Plan content</Title>
          <HorizontalValueContainer>
            <PlanContentValueContainer>
              <CheckmarkWithBackgroundIcon color={theme.colors.dark[100]} width={16} height={16} />
              <PlanContentValue>{isThereRequestCount ? availableRequestCountText : '-'} Request</PlanContentValue>
            </PlanContentValueContainer>

            <PlanContentValueContainer>
              <CheckmarkWithBackgroundIcon color={theme.colors.dark[100]} width={16} height={16} />
              <PlanContentValue>{isThereArticleCount ? availableArticleCountText : '-'} Article</PlanContentValue>
            </PlanContentValueContainer>
          </HorizontalValueContainer>
        </Section>

        <Section>
          <Title>Start Date</Title>
          <SectionValue>{startedDate}</SectionValue>
        </Section>

        <Section>
          <Title>End Date (Renewal Date)</Title>
          <SectionValue>{expireDate}</SectionValue>
        </Section>

        <Section>
          <Title>Plan price</Title>
          <SectionValue>{isFreePlan ? 'Free' : `$${userPlan?.purchased_plan?.price ?? 0}`}</SectionValue>
        </Section>

        {isCanceled && <CancelText>{t('account:planCanceled', { end_date: expireDate })}</CancelText>}

        {isCanceled ? (
          <ReactivateButton
            text="Reactivate subscription"
            onClick={onClickReactivate}
            variant="link"
            disabled={!isCanceled || isReactivatingInProgress}
          />
        ) : (
          <CancelButton
            text="Cancel subscription"
            onClick={onClickCancel}
            variant="link"
            disabled={!canCancel || isCanceled || isCancelingInProgress}
          />
        )}
      </SubscriptionCard>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.soft[40]};
  flex: 2;
  padding: 40px 70px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow-y: auto;
`;

const SubscriptionCard = styled.div`
  width: 554px;
  background-color: ${({ theme }) => theme.colors.white};

  padding: 30px 40px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.soft[40]};
  padding-bottom: 14px;
  margin-bottom: 14px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.purple[100]};

  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  margin-bottom: 4px;
`;

const SectionValue = styled.p`
  color: ${({ theme }) => theme.colors.dark[100]};

  font-size: 16px;
  line-height: 22px;
`;

const HorizontalValueContainer = styled.div`
  display: flex;
  margin-top: 8px;
`;

const PlanContentValueContainer = styled.div`
  display: flex;

  margin-right: 8px;
`;

const PlanContentValue = styled(SectionValue)`
  font-size: 14px;
  line-height: 19px;

  margin-left: 4px;
`;

const CancelText = styled.p`
  background-color: ${({ theme }) => theme.colors.red[10]};
  padding: 4px 6px;
  border-radius: 4px;

  font-size: 16px;
`;

const CancelButton = styled(AppButton)`
  color: ${({ theme, disabled }) => (disabled ? theme.colors.soft[80] : theme.colors.red[100])} !important;

  margin-top: 46px;
  align-self: flex-end;
`;

const ReactivateButton = styled(AppButton)`
  color: ${({ theme, disabled }) => (disabled ? theme.colors.soft[80] : theme.colors.green[100])} !important;

  margin-top: 46px;
  align-self: flex-end;
`;

export default AccountSubscription;
